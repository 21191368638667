import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, marketActions, reportActions } from '../../_actions'
import Loader from '../../components/Loader/Loader'
import { confirmAlert } from 'react-confirm-alert'
import ReactPaginate from 'react-paginate'

const GameCancel = () => {
    const selector = useSelector(state => state)
    const { gamesList } = useSelector(state => state.games);
    const dispatch = useDispatch()
    let { reports, games } = selector ? selector : {};
    let { getBetsListForAdminPending } = reports ? reports : {};
    let { data } = getBetsListForAdminPending ? getBetsListForAdminPending : {};
    let { list, total } = data ? data : []

    const [date, setDate] = useState('')
    const [gameId, setGameId] = useState('')
    const [gameType, setGameType] = useState('')
    const [userId, setuserId] = useState("")
    const [size, setSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [marketId, setMarketId] = useState('')
    const [error, setError] = useState({})


    const today = new Date()
    const formattedDate = moment(today).format('YYYY-MM-DD')

    const handleValidateFilter = () => {
        let newError = {};
        if (!gameId) newError.gameId = 'Game Name is required.';
        setError(newError);

        return Object.keys(newError).length === 0;
    }

    useEffect(() => {
        fetchData(1)
        setDate(formattedDate)
        dispatch(gamesActions.getAllUser());


        let gameReq2 = {
            "fromDate": '',
            "toDate": '',
            "keyWord": '',
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 500
        }

        let marketData = {
            "fromDate": "",
            "toDate": "",
            "keyWord": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 1000,
            "isJackpot": false,
            "isStarLine": false
        }
        dispatch(gamesActions.getAllGames(gameReq2));
        dispatch(marketActions.getMarketList(marketData))

    }, [])

    const fetchData = (pageNo) => {
        let reqData = {
            fromDate: formattedDate,
            toDate: formattedDate,
            keyWord: "",
            sortBy: "",
            sortOrder: "",
            pageNo: pageNo,
            size: size
        }
        dispatch(reportActions.getBetsListForAdminPending(reqData))
        setCurrentPage(pageNo - 1)
    }

    const handleDateChange = (e) => {
        setDate(e.target.value)
       
    }
    const handleGameId = (e) => {
        setGameId(e.target.value)
        setError((prev) => ({ ...prev, gameId: '' }))
    }
    const handleType = (e) => {
        setGameType(e.target.value)
     
    }
    const handleUserId = (data) => {
        setuserId(data)
       
    }

    const handleMarketId = (e) => {
        setMarketId(e.target.value)
      
    }

    const handleSubmit = () => {
        if (!handleValidateFilter()) {
            return; 
        }

        let reqData = {
            fromDate: date,
            toDate: date,
            keyWord: "",
            gameId: gameId,
            userId: userId ? [userId] : '',
            marketId: marketId,
            sortBy: "",
            isOpen: gameType == 'true' ? true : false,
            sortOrder: "",
            pageNo: pageNo,
            size: 100000
        }
        dispatch(reportActions.getBetsListForAdminPending(reqData))


    }

    const handleClear = () => {
        setDate(formattedDate)
        setGameId('')
        setGameType('')
        setuserId('')
        fetchData(1)
        setMarketId('')
        setError({});


    }

    const handleBetDelete = (data) => {
        let refershData = {
            fromDate: "",
            toDate: "",
            keyWord: "",
            sortBy: "",
            sortOrder: "",
            pageNo: 1,
            size: size
        }
        const betIds = data.bets.map(bet => bet._id)
        let deleteIds = {
            id: betIds
        }
        confirmAlert({
            title: 'Confirm to Delete Bet?',
            message: 'Are you sure you want to delete bet?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dispatch(reportActions.DeleteBet(deleteIds, refershData))
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    const handlePageClick = ({ selected }) => {
        fetchData(selected + 1)
    }

    return (
        <div className='p-5 space-y-2'>
            <Loader loading={selector?.reports?.loading} />
            <div className="p-2 grid grid-cols-1  md:grid-cols-3 gap-4 bg-white rounded-md">
                <div className="flex-1 min-w-full md:min-w-[48%] lg:min-w-[32%]">
                    <label className="block text-gray-700 text-base mb-2">Date :</label>
                    <input
                        type="date"
                        value={date}
                        onChange={handleDateChange}
                        className="w-full px-4 py-2.5 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  

                </div>

                <div className="flex-1 min-w-full md:min-w-[48%] lg:min-w-[32%]">
                    <label className="block text-gray-700 text-base mb-2">Game Name :</label>
                    <select
                        className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize"
                        value={gameId}
                        onChange={handleGameId}
                    >
                        <option value="">Select Game Name</option>
                        {gamesList && gamesList.length > 0
                            ? gamesList.map((element) => (
                                <option key={element._id} value={element._id || ""}>
                                    {element.name || ""}
                                </option>
                            ))
                            : null}
                    </select>
                    {error.gameId && <p className="text-red-500 text-sm">{error.gameId}</p>}

                </div>

                <div className="flex-1 min-w-full md:min-w-[48%] lg:min-w-[32%]">
                    <label className="block text-gray-700 text-base mb-2">Type :</label>
                    <select
                        className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize"
                        onChange={handleType}
                    >
                        <option value="">Select Type</option>
                        <option value={true}>Open</option>
                        <option value={false}>Close</option>
                    </select>
                  
                </div>

                <div className="flex-1 min-w-full md:min-w-[48%] lg:min-w-[32%]">
                    <label className="block text-gray-700 text-base mb-2" htmlFor="name">Market Name :</label>
                    <select
                        className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize"
                        id="userId"
                        name="userId"
                        placeholder="name"
                        value={marketId}
                        onChange={handleMarketId}
                    >
                        <option value="">Select Market Name</option>
                        {selector?.markets?.marketList && selector?.markets?.marketList.length > 0
                            ? selector?.markets?.marketList.filter(item => (item.parentMarketId === true && !item.isJackpot && !item.isJackpot)).map((element, index) => (
                                <option
                                    value={
                                        element && element._id
                                            ? element._id
                                            : ""
                                    }
                                >
                                    {element && element.name
                                        ? element.name
                                        : ""}
                                </option>
                            ))
                            : null}
                    </select>
                   

                </div>

                <div className="flex-1 min-w-full md:min-w-[48%] lg:min-w-[32%]">
                    <label className="mb-2 block text-sm font-medium text-gray-700">Select User</label>
                    <select
                        className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize"
                        value={userId}
                        onChange={(e) => handleUserId(e.target.value)}
                    >
                        <option value="">Select User</option>
                        {selector?.games?.allUserList?.map((element) => (
                            <option key={element._id} value={element._id}>
                                {element.userName || "N/A"}
                            </option>
                        ))}
                    </select>
                    

                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 items-end gap-2 w-full mt-2 md:mt-4">
                    <button
                        className="bg-black px-4 py-2 rounded-md text-white w-full md:w-auto"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                    <button
                        className="bg-blue-400 px-4 py-2 rounded-md text-white w-full md:w-auto"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                </div>
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[70vh] overflow-y-auto">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-6 py-3">User Name</th>
                            <th className="px-6 py-3">Game Name</th>
                            <th className="px-6 py-3">Time</th>
                            <th className="px-6 py-3">Betnumber (Amount)</th>
                            <th className="px-6 py-3">Total</th>
                            <th className="px-6 py-3">Type</th>
                            <th className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && list.length > 0 ? list.map((ele, index) => (
                            <tr className="bg-white border-b" key={index}>
                                <td className="px-6 py-4 font-medium text-gray-900 capitalize">{ele?.userName || '-'}</td>
                                <td className="px-6 py-4 font-medium text-gray-900 capitalize">{ele?.gameName || '-'}</td>
                                <td className="px-6 py-4">{moment(ele?.createdAt).format('YYYY-MM-DD')}</td>
                                <td className="px-6 py-4 max-h-40 overflow-y-auto">{ele?.bets?.map((data, i) => (<span key={i}>{data?.betNumber || '-'} ({data?.amount || '-'}), </span>))}</td>
                                <td className="px-6 py-4">{ele?.totalAmount || '-'}</td>
                                <td className="px-6 py-4">{ele?.bets?.[0]?.isOpen ? 'Open' : 'Close'}</td>
                                <td className="px-6 py-4">
                                    <a href="#" className="text-red-600 font-semibold hover:underline" onClick={() => handleBetDelete(ele)}>Delete</a>
                                </td>
                            </tr>
                        )) : (
                            <tr><td colSpan="7" className="px-6 py-4 text-center bg-white">No Data Found</td></tr>
                        )}
                    </tbody>
                </table>
                {
                    total && total > 10 ? (
                        <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(total / size)}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            forcePage={currentPage}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                        />
                    ) : null
                }

            </div>
        </div>
    )
}

export default GameCancel
