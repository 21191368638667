import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./GameManagement.json";
import { gamesActions, marketActions, userActions } from "../../_actions";
import CreateGameModal from "./components/CreateGameModal/CreateGameModal";
import UpdateGameModal from "./components/UpdateGameModal/UpdateGameModal";
import ViewGameModal from "./components/ViewGameModal/ViewGameModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader/Loader";
import moment, { invalid } from "moment";
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { IoBarChartSharp } from "react-icons/io5";
import { useNavigate } from "react-router";

const IndianJackpots = () => {
  let addref = useRef(null);
  let addref2 = useRef(null);
  const [resetKey, setResetKey] = useState(0);
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [createModal, setCreateModal] = useState(false);
  let [updateModal, setUpdateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [size, setSize] = useState(1000);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);

  const [options, setoptions] = useState([
    { name: "option one", id: "1" },
    { name: "option owo", id: "2" },
  ]);

  const daysOfWeek = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' }
  ];

  const handleSelectChange = (selectedOptions) => {
    setSelectedDays(selectedOptions.map(option => option.value));
    console.log("dayas------>", selectedOptions.map(option => option.value));
  };
  const { allJodi, gamesTotalb, loading } = useSelector((state) => state.games);
  const { allMarket } = useSelector((state) => state.markets);

  useEffect(() => {
    let gameReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(gamesActions.getAllJodiGame(gameReq));
    dispatch(marketActions.getAllMarket());
  }, []);

  const handleCreateOpenModal = () => {
    setMarketSelected([]);
    if (addref?.current?.value) {
      addref.current.value = "";
    }
    setCreateModal(true);
    dispatch(userActions.clearImage());
  };

  const handleCreateHideModal = () => {
    if (addref?.current?.value) {
      addref.current.value = "";
    }
    if (addref2?.current?.value) {
      addref2.current.value = "";
    }
    setCreateModal(false);
    setFieldsGame({});
    setErrorsGame({});
    setMarketSelected([]);
    dispatch(userActions.clearImage());
    setSelectedDays([])
  };

  const [updateSelectedDays, setUpdateSelectedDays] = useState([]);





  const handleUpdateChange = (selectedOptions) => {
    setUpdateSelectedDays(selectedOptions.map(option => option.value))
    console.log("selectedOptionsselectedOptions", selectedOptions)

  }

  const handleUpdateOpenModal = (data) => {
    setUpdateSelectedDays(data?.dayOff || [])
    setFieldsUpdate({
      ...data,
      closingTime: data["closingTime"]
        ? moment.utc(data["closingTime"] * 1000).format("HH:mm")
        : "",
      openTime: data["openTime"]
        ? moment.utc(data["openTime"] * 1000).format("HH:mm")
        : "",
      betClosingTime: data["betClosingTime"]
        ? moment.utc(data["betClosingTime"] * 1000).format("mm:ss")
        : "",
      betOpenTime: data["betOpenTime"]
        ? moment.utc(data["betOpenTime"] * 1000).format("mm:ss")
        : "",
    });
    let datas = data.marketList.map((e) => ({ name: e.name, id: e._id }));
    console.log(datas);
    dispatch(userActions.clearImage());
    setMarketSelected(datas);
    setUpdateModal(true);
  };

  const handleUpdateHideModal = (data) => {
    if (addref2?.current?.value) {
      addref2.current.value = "";
    }
    dispatch(userActions.clearImage());
    setUpdateModal(false);
    setErrorsUpdate({});
    setMarketSelected([]);
  };

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChangeUpdate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateGame()) {
      let selectedmarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map((element) => element.id)
          : null;
      let { users } = selector ? selector : {};
      let { fileData, loading } = users ? users : {};
      let gamePageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const {
        name,
        shortName,
        openTime,
        closingTime,
        isOpen,
        betOpenTime,
        betClosingTime,
        priority,
        min,
        max,
        backgroundColor,
        _id,
      } = fieldsUpdate;

      dispatch(
        gamesActions.updateGames(
          {
            id: _id,
            name: name && name.trim(),
            shortName: shortName && shortName.trim(),
            openTime: openTime,
            closingTime: openTime,
            betOpenTime: "00:00",
            betClosingTime: "00:00",
            min: min,
            max: max,
            dayOff: updateSelectedDays,
            // isOpen:isOpen=="true"?true:false,
            priority: priority,
            backgroundColor: backgroundColor && backgroundColor.trim(),
            marketList: selectedmarketData,
            image: fileData || fieldsUpdate?.image,
          },
          setUpdateModal,
          gamePageRefresh
        )
      );
      if (addref2?.current?.value) {
        addref2.current.value = "";
      }
    }
  };

  let selector = useSelector((state) => state);

  const createGameSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    e.preventDefault();
    if (handleValidationCreateGame()) {
      let selectedmarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map((element) => element.id)
          : null;

      let gamePageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const {
        name,
        shortName,
        openTime,
        priority,
        isOpen,
        betOpenTime,
        betClosingTime,
        max,
        min,
        brandGame,
      } = fieldsGame;
      dispatch(
        gamesActions.createGames(
          {
            name: name && name.trim(),
            shortName: shortName && shortName.trim(),
            betOpenTime: betOpenTime,
            betClosingTime: betClosingTime,
            openTime: openTime,
            // max: max,
            // min: min,
            closingTime: openTime,
            dayOff: selectedDays,
            priority: priority,
            isJodi: true,
            backgroundColor: "",
            marketList: selectedmarketData,
            image: fileData ? fileData : " ",
          },
          gamePageRefresh
        )
      );
      handleCreateHideModal();
      setSelectedDays([])

    }
  };

  const handleValidationUpdateGame = () => {
    let formIsValid = true;
    let errors = {};
    console.log(
      fieldsUpdate.openTime == fieldsUpdate.closingTime,
      fieldsUpdate.openTime,
      fieldsUpdate.closingTime
    );
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map((element) => element._id)
        : null;

    if (!selectedmarketData || selectedmarketData.length == 0) {
      formIsValid = false;
      errors.marketSelected = "Selected market data";
    }
    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }
    // if (!fieldsUpdate.isOpen || fieldsUpdate.isOpen.trim() === "") {
    //   formIsValid = false;
    //   errors.isOpen = "Empty Field";
    // }
    if (!fieldsUpdate.shortName || fieldsUpdate.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdate.openTime || fieldsUpdate.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    }

    // if (!fieldsUpdate.closingTime || fieldsUpdate.closingTime === "") {
    //   formIsValid = false;
    //   errors.closingTime = DashboardJSON.ErrorMsg.closingTime;
    // }
    // if (fieldsUpdate.openTime == fieldsUpdate.closingTime) {
    //   formIsValid = false;
    //   errors.closingTime = "Open Time , Close Time should be different";
    //   errors.openTime = "Open Time , Close Time should be different";
    // }
    // if (!fieldsUpdate.min || fieldsUpdate.min === "" || parseInt(fieldsUpdate.min) <= 0) {
    //   formIsValid = false;
    //   errors.min = "Amount should be greater than 0 and not in decimal ";
    // }
    // if (!fieldsUpdate.max || fieldsUpdate.max === "" || parseInt(fieldsUpdate.min) <= 0) {
    //   formIsValid = false;
    //   errors.max = "Amount should be greater than 0 and not in decimal ";
    // }

    if (!fieldsUpdate.priority || fieldsUpdate.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    // if (!fieldsUpdate.betOpenTime || fieldsUpdate.betOpenTime === "") {
    //   formIsValid = false;
    //   errors.betOpenTime = "Empty Field";
    // }
    // if (!fieldsUpdate.betClosingTime || fieldsUpdate.betClosingTime === "") {
    //   formIsValid = false;
    //   errors.betClosingTime = "Empty Fielde";
    // }

    console.log("errorsUpdate????", errorsUpdate);
    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map((element) => element._id)
        : null;

    if (!selectedmarketData || selectedmarketData.length == 0) {
      formIsValid = false;
      errors.selectedmarketData = "Selected market data";
    }

    if (!fieldsGame.name || fieldsGame.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }
    // if (!fieldsGame.betOpenTime || fieldsGame.betOpenTime === "") {
    //   formIsValid = false;
    //   errors.betOpenTime = "Empty Field";
    // }
    // if (!fieldsGame.betClosingTime || fieldsGame.betClosingTime === "") {
    //   formIsValid = false;
    //   errors.betClosingTime = "Empty Field";
    // }

    // if (!fieldsGame.isOpen || fieldsGame.isOpen.trim() === "") {
    //   formIsValid = false;
    //   errors.isOpen = "Empty Field";
    // }

    // if (!fieldsGame.min || fieldsGame.min === "" || parseInt(fieldsGame.min) <= 0) {
    //   formIsValid = false;
    //   errors.min = "Amount should be greater than 0 and not in decimal ";
    // }
    // if (fieldsGame.openTime == fieldsGame.closingTime) {
    //   formIsValid = false;
    //   errors.closingTime = "Open Time , Close Time should be different";
    //   errors.openTime = "Open Time , Close Time should be different";
    // }
    // if (!fieldsGame.max || fieldsGame.max === "" || parseInt(fieldsGame.min) <= 0) {
    //   formIsValid = false;
    //   errors.max = "Amount should be greater than 0 and not in decimal ";
    // }

    // if (!fileData || fileData === "") {
    //   formIsValid = false;
    //   errors.img = "Image is Required";
    // }

    // if (!fileData || fileData === "") {
    //   formIsValid = false;
    //   errors.img = "Image is Required";
    // }
    // if (!fileData || fileData === "") {
    //   formIsValid = false;
    //   errors.img = "Image is Required";
    // }

    if (!fieldsGame.shortName || fieldsGame.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsGame.openTime || fieldsGame.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    }

    // if (!fieldsGame.closingTime || fieldsGame.closingTime === "") {
    //   formIsValid = false;
    //   errors.closingTime = DashboardJSON.ErrorMsg.closingTime;
    // }

    if (!fieldsGame.priority || fieldsGame.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const disableGame = (data) => {
    console.log(data);
    let gameIdReq = {
      id: data._id,
    };
    let gamePageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    confirmAlert({
      title: data?.isDisable ? `Confirm to Enable ` : "Confirm to Disable",
      message: data?.isDisable
        ? `Are you sure you want to Enable ${data.name}?`
        : `Are you sure you want to Disable ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              gamesActions.updateGamesStatus(gameIdReq, gamePageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteGame = (data) => {
    console.log(data);

    let gameIdReq = {
      id: data._id,
    };

    let gamePageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    confirmAlert({
      title: "Confirm to Delete?",
      message: `Are you sure you want  to delete ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(gamesActions.deleteGames(gameIdReq, gamePageRefresh)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
      setErrorsGame((prevState) => ({ ...prevState, img: "" }));
    } else {
      console.log("No File To Upload!");
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    console.log("selectedListselectedList", selectedList);
    console.log("selectedItemselectedItem", selectedItem);
    let data = selectedList.map((e) => ({ name: e.name, id: e._id || e.id }));

    setMarketSelected(data);
    setErrorsGame((prevState) => ({ ...prevState, selectedmarketData: "" }));
  };

  const onRemove = (selectedList, removedItem) => {
    let filtervalue =
      selectedList && selectedList.filter((item) => item.id !== removedItem.id);
    setMarketSelected(filtervalue);
  };

  const handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
    };
    dispatch(gamesActions.getAllbrandGame(pageReq));
  };
  const navigate = useNavigate();

  const handleOnOf = (elem) => {
    let obj = {
      id: elem?._id,
    };
    let gameReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(gamesActions.onOf(obj, gameReq));
  };

  console.log("allJodiallJodi", allJodi);

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);



  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <section className="flex justify-between my-2 ">
                        <div>
                          <p className="text-2xl font-bold">
                            Indian Jackpots Games
                          </p>
                        </div>
                        {
                          localStoragedata && (localStoragedata.roleId === 0 || localStoragedata.access['JackPot_create']) && (
                            <div>
                              <span className="relative ">
                                <button
                                  className="px-4 py-1 font-normal lg:font-semibold tracking-wider text-white bg-blue-700 border rounded shadow-sm hover:shadow-lg hover:bg-blue-400"
                                  onClick={() => handleCreateOpenModal()}
                                >
                                  Add
                                </button>
                              </span>
                            </div>
                          )
                        }
                      </section>

                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border">
                          <table className="w-full text-sm text-left rtl:text-right text-black ">
                            <thead className="text-sm text-gray-900 font-bold capitalize bg-gray-50  ">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-3 whitespace-nowrap py-3"
                                >
                                  #
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 whitespace-nowrap py-3"
                                >
                                  Games
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 whitespace-nowrap py-3"
                                >
                                  open Time
                                </th>

                                {
                                  localStoragedata && (localStoragedata.roleId === 0 || localStoragedata.access['JackPot_enable']) && (
                                    <th
                                      scope="col"
                                      className="px-3 whitespace-nowrap py-3"
                                    >
                                      Active
                                    </th>
                                  )
                                }

                                {
                                  localStoragedata && (localStoragedata.roleId === 0 || localStoragedata.access['JackPot_edit'] || localStoragedata.access['JackPot_delete']) && (
                                    <th
                                      scope="col"
                                      className="px-3 whitespace-nowrap  text-center py-3 "
                                    >
                                      action
                                    </th>
                                  )
                                }


                              </tr>
                            </thead>
                            <tbody>
                              {allJodi &&
                                allJodi.length > 0 &&
                                allJodi.map((element, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr
                                        key={index}
                                        className={
                                          (index + 1) % 2 === 0
                                            ? "bg-white border-b"
                                            : "bg-gray-100"
                                        }
                                      >
                                        <th
                                          scope="row"
                                          className="px-3 py-2 font-medium whitespace-nowrap"
                                        >
                                          {index + 1}{" "}
                                          {/* Use counter for serial number */}
                                        </th>
                                        <th
                                          scope="row"
                                          className="px-3 py-2 capitalize font-medium text-black whitespace-nowrap"
                                        >
                                          {element && element.name
                                            ? element.name
                                            : "-"}
                                        </th>
                                        <th
                                          scope="row"
                                          className="px-3 py-2 font-medium whitespace-nowrap"
                                        >
                                          {element &&
                                            element.openTime &&
                                            moment()
                                              .hours(
                                                parseInt(
                                                  element.openTime / 3600
                                                )
                                              )
                                              .minutes(
                                                parseInt(
                                                  (element.openTime % 3600) / 60
                                                )
                                              )
                                              .seconds(
                                                parseInt(element.openTime % 60)
                                              )
                                              .milliseconds(0)
                                              .format("h:mm A")}
                                        </th>

                                        {
                                          localStoragedata && (localStoragedata.roleId === 0 || localStoragedata.access['JackPot_enable']) && (
                                            <th
                                              scope="row"
                                              className="px-3 py-2 capitalize font-medium text-black whitespace-nowrap"
                                            >
                                              <button
                                                className={
                                                  element.isDisable ===
                                                    false
                                                    ? "px-2 py-1 border border-green-700 bg-green-700 text-white rounded"
                                                    : "px-2 py-1 border border-red-700 text-white bg-red-700 rounded"
                                                }
                                                onClick={() =>
                                                  disableGame(element)
                                                }
                                              >
                                                {element &&
                                                  element.isDisable === false
                                                  ? "Yes"
                                                  : "No"}
                                              </button>
                                            </th>
                                          )
                                        }



                                        <td className="px-3 py-2 flex justify-center">
                                          {
                                            localStoragedata && (localStoragedata.roleId === 0 || localStoragedata.access['JackPot_edit']) && (
                                              <button onClick={() =>
                                                handleUpdateOpenModal(element)
                                              } className="relative flex justify-start place-items-center gap-1 px-2 py-1 rounded cursor-pointer bg-green-600 text-white">

                                                <FiEdit

                                                  title="Edit"
                                                  className=" cursor-pointer"
                                                /> Edit
                                              </button>
                                            )
                                          }
                                          {
                                            localStoragedata && (localStoragedata.roleId === 0 || localStoragedata.access['JackPot_delete']) && (
                                              <span className="relative">
                                                <MdOutlineDelete
                                                  onClick={() =>
                                                    deleteGame(element)
                                                  }
                                                  title="Delete"
                                                  className="text-2xl text-red-600 cursor-pointer"
                                                />
                                              </span>
                                            )
                                          }



                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              gamesTotal && gamesTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={gamesTotal / size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              gamesTotal && gamesTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={gamesTotal / size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      } */}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateGameModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        selectedMarket={marketSelected}
        inputChangeCreate={inputChangeCreate}
        fieldsGame={fieldsGame}
        setMarketSelected={setMarketSelected}
        errorsGame={errorsGame}
        allMarket={allMarket}
        onSelect={onSelect}
        resetKey={resetKey}
        setResetKey={setResetKey}
        onRemove={onRemove}
        createGameSubmit={createGameSubmit}
        addref={addref}
        daysOfWeek={daysOfWeek}
        handleSelectChange={handleSelectChange}
      />

      <UpdateGameModal
        options={options}
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChangeUpdate={inputChangeUpdate}
        fieldsUpdate={fieldsUpdate}
        addref2={addref2}
        errorsUpdate={errorsUpdate}
        allMarket={allMarket}
        marketSelected={marketSelected}
        onSelect={onSelect}
        onRemove={onRemove}
        updateGameSubmit={updateGameSubmit}
        handleSelectChange={handleSelectChange}
        daysOfWeek={daysOfWeek}
        updateSelectedDays={updateSelectedDays}
        handleUpdateChange={handleUpdateChange}

      />

      <ViewGameModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />
    </>
  );
};

export default IndianJackpots;
