import React, { useEffect, useState } from "react";
import Select from "../../ui/Select/Select";
import { gamesActions, marketActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import Loader from "../../components/Loader/Loader";

const   ProfitAndLoss = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let { games, markets } = selector ? selector : {};
  let { gamesList, allUserList, gameWiseResult, getMarketWiseResultByGameId } =
    games ? games : {};
  let { data } = getMarketWiseResultByGameId ? getMarketWiseResultByGameId : {};
  let { marketList } = markets ? markets : {};

  let todayDate = new Date();
  let formatedDate = moment(todayDate).format("YYYY-MM-DD");
  const [date, setDate] = useState(formatedDate);
  const [gameId, setGameId] = useState("");
  const [gameName, setGameGameId] = useState("");
  const [shoeGameName, setShowGameName] = useState(null);
  const [marketId, setMarketId] = useState("");
  const [useId, setUserId] = useState("");
  const [typeSet, setTpyeSet] = useState("");

  const typeOptions = [
    { name: "Open", value: true },
    { name: "Close", value: false },
  ];

  const modifieUserList =
    allUserList && allUserList.length > 0
      ? allUserList?.map((e) => ({
          name: e.userName,
          _id: e._id,
        }))
      : null;

  useEffect(() => {
    let gameReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 1000,
    };
    let marketData = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 1000,
      isJackpot: false,
      isStarLine: false,
    };

    let gameWiseApiCred = {
      filterDate: formatedDate,
      gameId: "",
      isOpen: "",
      marketId: "",
      user: "",
    };
    dispatch(gamesActions.getAllGames(gameReq));
    dispatch(marketActions.getMarketList(marketData));
    dispatch(gamesActions.getAllUser());
    dispatch(gamesActions.getGameWiseResult(gameWiseApiCred));
  }, []);

  const handleGameSelect = (e) => {
    try {
      const selectedValue = JSON.parse(e.target.value);
      console.log(selectedValue);
      setGameId(selectedValue?.id);
      setGameGameId(selectedValue.name);
    } catch (error) {
      console.log(error);
    }
  };
  const handleMarketSelect = (e) => {
    try {
      const selectedValue = JSON.parse(e.target.value);
      setMarketId(selectedValue?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserId = (e) => {
    try {
      const selectedValue = JSON.parse(e.target.value);
      setUserId(selectedValue?.id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleTypeSet = (e) => {
    try {
      const selectedValue = e.target.value;
      setTpyeSet(selectedValue);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = () => {
    let gameWiseApiCred = {
      filterDate: date,
      gameId: gameId,
      isOpen: Boolean(typeSet),
      marketId: marketId,
      user: useId,
    };

    let secondApiCre = {
      betStatus: 0,
      filterDate: date,
      gameId: gameId,
      isJackpot: false,
      isOpen: "",
      isStarLine: false,
    };
    dispatch(gamesActions.getGameWiseResult(gameWiseApiCred));
    dispatch(gamesActions.getMarketWiseResultbyGameId(secondApiCre));
    setShowGameName(gameName);
  };

  const handleClearFilter = () => {
    let gameWiseApiCred = {
      filterDate: formatedDate,
      gameId: "",
      isOpen: "",
      marketId: "",
      user: "",
    };
    dispatch(gamesActions.getGameWiseResult(gameWiseApiCred));
    setTpyeSet("");
    setGameId("");
    setMarketId("");
    setUserId("");
    setShowGameName(null);
  };
  return (
    <div className="">
      <Loader loading={selector?.games?.loading} />
      <div className="p-3  sm:p-5 space-y-4 h-screen overflow-hidden overflow-y-auto ">
        <div className="grid grid-cols-1 md:grid-cols-7 gap-4 ">
          <Select
            name="Game Name"
            options={gamesList}
            onChange={handleGameSelect}
          />
          <Select
            name="Market Name"
            options={marketList}
            onChange={handleMarketSelect}
          />
          <select
            value={typeSet}
            onChange={handleTypeSet}
            className="block capitalize w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select Type</option>
            {typeOptions && typeOptions.length > 0
              ? typeOptions.map((option, index) => {
                  console.log("optionoption", option);
                  return (
                    <option key={index} value={option.value}>
                      {option.name}
                    </option>
                  );

                  return null;
                })
              : null}
          </select>
          <Select
            name="User"
            options={modifieUserList}
            onChange={handleUserId}
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="block capitalize w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <button
            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-1 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            onClick={handleSearch}
          >
            Search
          </button>
          <button
            className="text-white bg-red-600 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2  mb-1"
            onClick={handleClearFilter}
          >
            Clear
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 h-screen overflow-y-auto">
          <div className="relative overflow-x-auto  shadow h-[70vh] sm:rounded-xl overflow-y-auto bg-white p-3 border">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th className="px-6 py-3">Game</th>
                  <th className="px-6 py-3">Bids</th>
                  <th className="px-6 py-3">Win</th>
                  <th className="px-6 py-3">Profit / Loss</th>
                </tr>
              </thead>
              <tbody>
                {gameWiseResult && gameWiseResult.length > 0 ? (
                  gameWiseResult.map((ele, index) => {
                    const difference =
                      Number(ele.totalAmount || 0) - Number(ele.totalwin || 0);
                    return (
                      <tr
                        className={`bg-white border-b ${
                          difference < 0 ? "bg-red-500" : ""
                        }`}
                        key={index}
                      >
                        <td className="px-6 py-4 font-medium text-gray-900 capitalize">
                          {ele?.name || "-"}
                        </td>
                        <td className="px-6 py-4 font-medium text-gray-900 capitalize">
                          {ele.totalAmount || 0}
                        </td>
                        <td className="px-6 py-4">{ele.totalwin || 0}</td>
                        <td className={`px-6 py-4`}>
                          <span
                            className={` ${
                              difference < 0 ? "text-red-500" : "text-green-600"
                            }`}
                          >
                            {difference}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4" className="px-6 py-4 text-center bg-white">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="bg-white p-3 border h-[70vh] ">
            <div>
              <h2 className="text-center capitalize text-2xl">
                {shoeGameName}
              </h2>
              <div>
                <table className="w-full text-sm text-left text-gray-500 mt-3">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th className="px-6 py-3">Game</th>
                      <th className="px-6 py-3">Total Amount</th>
                      <th className="px-6 py-3">Give Amount</th>
                      {/* <th className="px-6 py-3">View</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data.map((ele, id) => (
                        <tr
                          key={id}
                          className="bg-white border-b hover:bg-gray-100 transition"
                        >
                          <td className="px-6 py-4">{ele.name || "-"}</td>
                          <td className="px-6 py-4">
                            {ele?.result?.totalAmount || 0}
                          </td>
                          <td className="px-6 py-4">
                            {ele?.result?.totalWinAmount || 0}
                          </td>
                          {/* <td className="px-6 py-4">
                                                        <button className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md">
                                                            View
                                                        </button>
                                                    </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="4"
                          className="px-6 py-4 text-center bg-white"
                        >
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitAndLoss;
