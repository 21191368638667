import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Dashboard from "../../pages/dashboard/Dashboard";
import UserManagement from "../../pages/userManagement/UserManagement";
import MarketManagement from "../../pages/marketManagement/MarketManagement";
import GameManagement from "../../pages/gameManagement/GameManagement";
import FundRequest from "../../pages/walletManagement/FundRequest";
import WithdrawRequest from "../../pages/walletManagement/WithdrawRequest";
import SliderManagement from "../../pages/sliderManagement/SliderManagement";
import AppSettingManagement from "../../pages/appsettingManagement/AppSettingManagement";
import layoutJSON from "./layout.json";
import AddFund from "../../pages/walletManagement/AddFund";
import WithdrawFund from "../../pages/walletManagement/WithdrawFund";
import BidRevert from "../../pages/reportManagement/BidRevert";
import AddFundReport from "../../pages/reportManagement/AddFundReport";
import WithdrawReport from "../../pages/reportManagement/WithdrawReport";
import UserBidHistory from "../../pages/reportManagement/UserBidHistory";
import DeclareResult from "../../pages/reportManagement/DeclareResult";
import EditUser from "../../pages/userManagement/EditUser";
import EditGame from "../../pages/gameManagement/components/EditGame/EditGame";
import EditMarket from "../../pages/marketManagement/components/EditMarket/EditMarket";
import UserInfo from "../../pages/userInfo/UserInfo";
import BankDetails from "../../pages/BankDetails/BankDetails";
import AddFundReport2 from "../../pages/walletManagement/AddFundRequest/AddFundReport2";
import SingleDigits from "../../pages/SingleDigits/SingleDigits";
import PointTransferReport from "../../pages/reportManagement/PointTransferReport";
// import WinList from "../../pages/reportManagement/WinList";
import PointBidHistory from "../../pages/reportManagement/PointBidHistory";
import SubAdmin from "../../pages/SubAdmin/SubAdmin";
import Notification from "../../pages/Notification/Notification";
import Charts from "../../pages/Charts/Charts";
import SubAdminUser from "../../pages/SubAdminUser/SubAdminUser";
import Brand from "../../pages/Brand/Brand";
import KolkataGameManagement from "../../pages/gameManagement/KolkataGameManagement";
import BrandgameManagement from "../../pages/BrandgameManagement/BrandgameManagement";
import DeclareResultFatafat from "../../pages/reportManagement/DeclareResultFatafat";
import Ledger from "../../pages/reportManagement/Ledger";
import Ledgers from "../../pages/reportManagement/Ledgers";
import Ledger2 from "../../pages/reportManagement/Ledger2";
import Ledger3 from "../../pages/reportManagement/Ledger3";
import Ledger4 from "../../pages/reportManagement/Ledger4";
import DeclareResultJackPot from "../../pages/reportManagement/DeclareResultJackPot";
import JackPotManagement from "../../pages/JackPotManagement/JackPotManagement";
import StarlineManagement from "../../pages/StarlineManagement/StarlineManagement";
import IndianJackpots from "../../pages/JackPotGameMangement/IndianJackpots";
import StarlineBidPointHistory from "../../pages/reportManagement/StarlineBidPointHistory";
import JackpotBidPointHistorys from "../../pages/reportManagement/JackpotBidPointHistorys";
import JackpotBidHistory from "../../pages/reportManagement/JackpotBidHistory";
import StarlineBidHistory from "../../pages/reportManagement/StarlineBidHistory";
import UserSuperadmin from "../../pages/userSuperadmin/UserSuperadmin";
import BlockUpi from "../../pages/Block Upi/BlockUpi";
import CreateAccount from "../../pages/Create Account/CreateAccount";
import WrongPayment from "../../pages/Wrong Payment/WrongPayment";
import GrandTotalWin from "../../pages/Grand Total Win/GrandTotalWin";
import FundHistory from "../../pages/Fund history/FundHistory";
import CancelBetHistory from "../../pages/Cancel Bet History/CancelBetHistory";
import GameCancel from "../../pages/Game Cancel/GameCancel";
import InactiveUser from "../../pages/Inactive User/InactiveUser";
import ProfitAndLoss from "../../pages/Profit And Loss/ProfitAndLoss";
import ReportGenrate from "../../pages/Report Genrate/ReportGenrate";
import WinningList from "../../pages/gameManagement/Winning List/WinningList";
import JackpotWinnerList from "../../pages/JackPotGameMangement/Winner List/JackpotWinnerList";
import StarlineWinnerList from "../../pages/StarlineManagement/Winner List/StarlineWinnerList";
import Bookie from "../../pages/SubAdmin/components/Bookie";
import Shop from "../../pages/SubAdmin/components/Shop";
import Client from "../../pages/SubAdmin/components/Client";


function Layout() {
  const [navbarOpen, setNavbarOpen] = React.useState(true);
  return (
    <div>
      <div className="overflow-hidden bg-gray-100">
        <div className="min-h-screen flex flex-col justify-center">
          <div className="h-screen flex justify-between  relative ">
            <div
              className={`h-full ${
                navbarOpen
                  ? "w-72 absolute md:relative transition ease-in-out delay-150"
                  : "w-0 "
              }`}
            >
              <Sidebar
                SidebarJSON={layoutJSON.sidebarJSON}
                navbarOpen={navbarOpen}
                setNavbarOpen={setNavbarOpen}
              />
            </div>

            <div
              className={`flex flex-col ${
                navbarOpen ? " flex-1  " : "w-full"
              }   overflow-hidden`}
            >
              <Header HeaderJSON={layoutJSON.headerJSON} />
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/inactive-user" element={<InactiveUser />} />

                <Route path="/user/:userId" element={<UserInfo />} />
                {/* <Route path="/user/:userId"  element={<UserInfo/>} /> */}
                <Route path="/user" element={<UserManagement />} />
                <Route path="/downsideUser" element={<SubAdmin />} />
                <Route path="/bookie" element={<Bookie />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/client" element={<Client />} />




                <Route path="/userofSuperadmin" element={<UserSuperadmin />} />
                <Route path="/betsledger/:id/:name" element={<Ledger4 />} />
                <Route
                  path="/:masterId/:mastername"
                  element={<SubAdminUser />}
                />
                <Route path="/EditUser/:userId?" element={<EditUser />} />
                <Route path="/EditGame" element={<EditGame />} />
                <Route path="/EditMarket/:marketId?" element={<EditMarket />} />
                <Route path="/market" element={<MarketManagement />} />
                <Route path="/marketJackpot" element={<JackPotManagement />} />
                <Route
                  path="/marketStarLine"
                  element={<StarlineManagement />}
                />
                <Route path="/game" element={<GameManagement />} />
                <Route path="/Brandgame" element={<BrandgameManagement />} />
                <Route path="/IndianJackpots" element={<IndianJackpots />} />
                {/* <Route path="/Kolkatagame" element={<KolkataGameManagement />} /> */}
                <Route path="/brand" element={<Brand />} />
                <Route path="/slider" element={<SliderManagement />} />
                <Route path="/fundRequest" element={<FundRequest />} />
                <Route path="/withdrawRequest" element={<WithdrawRequest />} />
                <Route path="/AddFundRequest" element={<AddFundReport2 />} />
                <Route path="/bidRevert" element={<BidRevert />} />
                <Route path="/addFundReport" element={<AddFundReport />} />
                <Route path="/withdrawReport" element={<WithdrawReport />} />
                <Route
                  path="/pointTransfer"
                  element={<PointTransferReport />}
                />

                <Route path="/userbidhistory" element={<UserBidHistory />} />
                <Route
                  path="/jackpotBetHistory"
                  element={<JackpotBidHistory />}
                />
                <Route
                  path="/starlineBidHistory"
                  element={<StarlineBidHistory />}
                />
                <Route path="/grand-total-win" element={<GrandTotalWin />} />

                <Route path="/block-upi" element={<BlockUpi />} />
                <Route path="/create-account" element={<CreateAccount />} />
                <Route path="/wrong-payment" element={<WrongPayment />} />
                <Route path="/fund-history" element={<FundHistory />} />
                <Route
                  path="/cancel-bet-history"
                  element={<CancelBetHistory />}
                />
                <Route path="/game-cancel" element={<GameCancel />} />

                <Route path="/winningList" element={<WinningList />} />
                <Route
                  path="/jackpotWinnerList"
                  element={<JackpotWinnerList />}
                />

                <Route
                  path="/starlineWinnerList"
                  element={<StarlineWinnerList />}
                />

                <Route path="/ledger" element={<Ledger />} />
                <Route path="/ledger/:id" element={<Ledger2 />} />
                <Route path="/ledgers" element={<Ledgers />} />
                <Route path="/addFund" element={<AddFund />} />
                <Route path="/withdrawfund" element={<WithdrawFund />} />
                <Route path="/declareResult" element={<DeclareResult />} />
                <Route
                  path="/declareResultStarline"
                  element={<DeclareResultFatafat />}
                />
                <Route
                  path="/declareResultJackPot"
                  element={<DeclareResultJackPot />}
                />
                {/* <Route path="/winnerList" element={<WinList />} /> */}
                <Route path="/betHistory" element={<PointBidHistory />} />
                <Route
                  path="/starline-bid-point-history"
                  element={<StarlineBidPointHistory />}
                />
                <Route
                  path="/jackpot-bid-point-history"
                  element={<JackpotBidPointHistorys />}
                />
                <Route path="/profit&loss" element={<ProfitAndLoss />} />
                <Route path="/report-generation" element={<ReportGenrate />} />

                <Route path="/appsetting" element={<AppSettingManagement />} />
                <Route path="/bankdetails" element={<BankDetails />} />
                <Route path="/chart/:gameId/:gameName" element={<Charts />} />
                <Route path="/singleDigits" element={<SingleDigits />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/betsledger" element={<Ledger3 />} />
               

                
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
