import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, walletActions } from '../../_actions'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import Loader from '../../components/Loader/Loader'

const WrongPayment = () => {
    const selector = useSelector(state => state)
    let { wallet } = selector ? selector : {};
    let { wrongPyamentList, totalWrongPayment } = wallet ? wallet : {};
    let { data } = wrongPyamentList ? wrongPyamentList : {};
    let { list } = data ? data : {};
    const dispatch = useDispatch()
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const [date, setDate] = useState('')
    const [userId, setUserId] = useState('')
    const [size, setSize] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    useEffect(() => {
        let reqData = {
            "type": "",
            "fromDate": formattedDate,
            "toDate": formattedDate,
            "keyWord": "",
            "sortBy": "",
            "userId": "",
            "sortOrder": "",
            "pageNo": pageNo,
            "status": [2],
            "size": size
        }
        dispatch(walletActions.getPaymentAllPayment(reqData))
        dispatch(gamesActions.getAllUser());

        setDate(formattedDate)


    }, [])

    const handleUserId = (data) => {
        console.log('datatatatatatat', data)
        setUserId(data)
    }
    const handleDateOnChnage = (e) => {
        let newDate = e.target.value
        setDate(newDate);
        console.log('newDataaaa', newDate)
    }

    const handleSearchSubmit = () => {
        let reqData = {
            "type": "",
            "fromDate": date,
            "toDate": date,
            "keyWord": "",
            "sortBy": "",
            "userId": userId,
            "sortOrder": "",
            "pageNo": pageNo,
            "status": [2],
            "size": size
        }
        dispatch(walletActions.getPaymentAllPayment(reqData))
    }


    const handlePageClick = (data) => {
        const selectedPage = data.selected;
        setCurrentPage(selectedPage);
        setPageNo(selectedPage + 1);
    };
    return (
        <div>
            <Loader loading={selector?.wallet?.loading} />
            <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
                <div className="relative">
                    <div className="relative z-20 flex min-h-screen">
                        <div className="flex w-full overflow-hidden ">
                            <div className="flex flex-col flex-1 overflow-hidden">
                                <main className="relative flex-1 ">
                                    <div className="p-3  sm:p-5">
                                        <div className="mx-auto max-w-screen-3xl space-y-5">
                                        
                                            <div className="px-6 py-6 bg-white rounded-lg shadow-md space-y-6">
                                                <div className="flex flex-col md:flex-row justify-between w-full gap-6">
                                                    {/* Date Input Section */}
                                                    <div className="flex-1">
                                                        <label className="mb-2 block text-sm font-medium text-gray-700">Select Date</label>
                                                        <input
                                                            type="date"
                                                            value={date}
                                                            onChange={handleDateOnChnage}
                                                            className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                        />
                                                    </div>

                                                    {/* User Select Section */}
                                                    <div className="flex-1">
                                                        <label className="mb-2 block text-sm font-medium text-gray-700">Select User</label>
                                                        <select
                                                            className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize  "
                                                            id="Users"
                                                            name="Users"
                                                            onChange={(e) => handleUserId(e.target.value)}
                                                        >
                                                            <option value="">Select User</option>
                                                            {selector?.games?.allUserList?.map((element) => (
                                                                <option key={element._id} value={element._id}>
                                                                    {element.userName || "N/A"}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {/* Submit Button Section */}
                                                    <div className="flex items-end justify-center">
                                                        <button
                                                            onClick={handleSearchSubmit}
                                                            className="w-full md:w-auto px-6 py-3 text-sm text-white bg-indigo-600 rounded-md shadow-md hover:bg-indigo-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg border">
                                                    <table className="w-full text-sm text-left rtl:text-right text-black ">
                                                        <thead className="text-gray-900 text-sm capitalize bg-gray-50  ">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3">User Name</th>
                                                                <th scope="col" className="px-6 py-3">Date Time</th>
                                                                <th scope="col" className="px-6 py-3">amount</th>
                                                                <th scope="col" className="px-6 py-3">Total</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {list && list.length > 0 ? list.map((element, index) => {

                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <tr key={index} className={`bg-white border-b border-gray-400`}>

                                                                            <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap capitalize">
                                                                                {element && element.userId ? element?.userId?.userName : "-"}
                                                                            </th>
                                                                            <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                                                                                {element && element.createdAt ? moment(element.createdAt).format('DD-MM-YYYY ,hh:mm A') : "-"}
                                                                            </th>
                                                                            <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                                                                                {element && element.amount ? element.amount : "-"}
                                                                            </th>
                                                                            <th scope="row" className="px-6 py-4 font-medium  whitespace-nowrap ">
                                                                                {element && element.amount ? element.amount : "-"}
                                                                            </th>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )

                                                            })
                                                                : <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className='text-black pt-2'>No Data Found</td>
                                                                    <td></td>
                                                                    <td></td>

                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>

                                                    {
                                                        totalWrongPayment && totalWrongPayment > size ?
                                                            <ReactPaginate
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={Math.ceil(totalWrongPayment / size)}
                                                                marginPagesDisplayed={3}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={'pagination'}
                                                                pageClassName={'page-cls'}
                                                                activeClassName={'active'}
                                                                forcePage={currentPage}
                                                            />
                                                            : null}
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WrongPayment