import React from 'react'

const Select = ({ name, value, onChange, options }) => {
    console.log(options)
    return (
        <div>
            <div className="relative">
                <select
                    value={value}
                    onChange={onChange}
                    className="block capitalize w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                    <option value="">
                        Select {name}
                    </option>
                    {options && options.length > 0 ? options.map((option, index) => {
                        if (option?._id && option?.name) {
                            return (
                                <option
                                    key={index}
                                    value={JSON.stringify({ id: option._id, name: option.name })}
                                >
                                    {option.name}
                                </option>
                            );
                        }
                        return null;
                    }) : null}
                </select>

                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg
                        className="w-5 h-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Select