import { reportConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
export const reportActions = {
    getBetsListForAdmin,
    getWinningList,
    getNumberWiseMarketSum,
    getAllEntries,
    betwiseLedger,
    getDownLineUsers,
    getLedger,
    getLedgerforUser,
    getAllEntriesbyId,
    getCancleHistory,
    getBetsListForAdminPending,
    DeleteBet,
    getBetsListForAdmin2


};


function betwiseLedger(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/betwiseLedger'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                // console.log("--->report",report)
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GETBET_WISE_LEDGER_DATA_REQUEST, report } }
    function success(report) { return { type: reportConstants.GETBET_WISE_LEDGER_DATA_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GETBET_WISE_LEDGER_DATA_FAILURE, error } }
}
function getAllEntriesbyId(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllEntriesbyId'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                // console.log("--->report",report)
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_LEDFERS_ID_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_LEDFERS_ID_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_LEDFERS_ID_FAILURE, error } }
}
function getLedger(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getLedger'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                // console.log("--->report",report)
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_LEDFERS_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_LEDFERS_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_LEDFERS_FAILURE, error } }
}



function getLedgerforUser(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getLedgerforUser'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                // console.log("--->report",report)
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_LEDFERS_REQUEST22, report } }
    function success(report) { return { type: reportConstants.GET_LEDFERS_SUCCESS22, report } }
    function failure(error) { return { type: reportConstants.GET_LEDFERS_FAILURE22, error } }
}



function getDownLineUsers(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getDownLineUsers'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_DOWN_LINE_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_DOWN_LINE_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_DOWN_LINE_FAILURE, error } }
}
function getAllEntries(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllEntries'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_ALL_ENTRIES_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_ALL_ENTRIES_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_ALL_ENTRIES_FAILURE, error } }
}
function getNumberWiseMarketSum(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberWiseMarketSum'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_FAILURE, error } }
}
function getWinningList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getWinningList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_WIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_WIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_WIN_FAILURE, error } }
}
function getBetsListForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getBetsListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_FAILURE, error } }
}



function getCancleHistory(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getCancleHistory'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_CANCEL_HISTORY_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_CANCEL_HISTORY_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_CANCEL_HISTORY_FAILURE, error } }
}






function getBetsListForAdminPending(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getBetsListForAdminPending'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_BETS_LIST_FOR_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_BETS_LIST_FOR_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_BETS_LIST_FOR_ADMIN_FAILURE, error } }
}



function DeleteBet(data, refershData) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/DeleteBet'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                    dispatch(getBetsListForAdminPending(refershData))
                    dispatch(alertActions.success('Bet deleted'))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.DELETE_BETS_REQUEST, report } }
    function success(report) { return { type: reportConstants.DELETE_BETS_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.DELETE_BETS_FAILURE, error } }
}




function getBetsListForAdmin2(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getBetsListForAdmin2'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_BET_LIST_FOR_ADMIN2_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_BET_LIST_FOR_ADMIN2_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_BET_LIST_FOR_ADMIN2_FAILURE, error } }
}