import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { walletActions } from '../../_actions'
import moment from 'moment'
import Loader from '../../components/Loader/Loader'
import ReactPaginate from 'react-paginate'

const FundHistory = () => {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()

    let { wallet } = selector ? selector : {};
    let { wrongPyamentList, totalWrongPayment } = wallet ? wallet : {};
    let { data } = wrongPyamentList ? wrongPyamentList : {};
    let { list, total } = data ? data : {}
    console.log('listlist', list, totalWrongPayment)
    let today = new Date()
    const formattedDate = moment(today).format('YYYY-MM-DD');
    const [date, setDate] = useState('')
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [size, setSize] = useState(10)
    const [pageNo, setPageNo] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPage3, setCurrentPage3] = useState(0);

    const [error, setError] = useState({})

    const handleValidate = () => {
        const errors = {};

        if (!date) errors.date = "Date is required";
        if (!type) errors.type = "Transaction type is required";
        if (!status) errors.status = "Status is required";

        setError(errors);
        return Object.keys(errors).length === 0;
    }

    useEffect(() => {
        let reqData = {
            "fromDate": formattedDate,
            "toDate": formattedDate,
            "keyWord": "",
            "userId": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "status": [],
            "size": size
        }
        dispatch(walletActions.getPaymentAllPayment(reqData))
        setDate(formattedDate)
    }, [])
    const handleDateChange = (e) => {
        setDate(e.target.value)
        setError((prev) => ({ ...prev, date: "" }))
    }
    const handleType = (e) => {
        setType(e.target.value)
        setError((prev) => ({ ...prev, type: "" }))

    }
    const handleStatus = (e) => {
        setStatus(e.target.value)
        setError((prev) => ({ ...prev, status: "" }))

    }
    const handleSubmit = () => {

        if (!handleValidate()) return
        let reqData = {

            "fromDate": date,
            "toDate": date,
            "keyWord": "",
            "userId": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "status": [status],
            type: type,
            "size": 100
        }
        dispatch(walletActions.getPaymentAllPayment(reqData))
    }
    const handleClear = () => {
        let reqData = {
            "fromDate": formattedDate,
            "toDate": formattedDate,
            "keyWord": "",
            "userId": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "status": [],
            "size": 100
        }
        dispatch(walletActions.getPaymentAllPayment(reqData))
        setStatus('')
        setType('')
        setError({})
    }

    const handlePageClick = (data) => {

        const newPageNo = data.selected + 1;
      
        let offset1 = Math.ceil(data.selected * size);
        // setOffset1(offset1);
        let params = {
            "keyWord": '',
            "pageNo": String(data.selected + 1),
            "size": String(size),
            "keyWord": "",
            "isExpert": true,
            "fromDate": "",
            "toDate": "",
            "status": []

        }



        dispatch(walletActions.getPaymentAllPayment(params))

    }
    return (
        <div className='p-5 space-y-2'>
            <Loader loading={selector?.wallet?.loading} />
            <div className="bg-white p-2 rounded-lg shadow-md flex flex-wrap justify-between items-end gap-4">
                <div className="flex-1 min-w-[150px]">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Date</label>
                    <input
                        type="date"
                        value={date}
                        onChange={handleDateChange}
                        className="w-full p-1.5 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />

                    {error.date && <p className="text-red-500 text-sm">{error.data}</p>}
                </div>

                <div className="flex-1 min-w-[150px]">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Transaction Type</label>
                    <select className="w-full p-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" onChange={handleType} value={type}>
                        <option value="">Select Type</option>
                        <option value="DEPOSIT">Add</option>
                        <option value="WITHDRAW">Withdraw</option>
                    </select>
                    {error.type && <p className="text-red-500 text-sm">{error.type}</p>}

                </div>

                <div className="flex-1 min-w-[150px]">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                    <select className="w-full p-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" onChange={handleStatus} value={status}>
                        <option value="">Select Status</option>
                        <option value="1">Accepted</option>
                        <option value="2">Reject</option>
                        <option value="0">Pending</option>
                    </select>
                    {error.status && <p className="text-red-500 text-sm">{error.status}</p>}

                </div>

                <div className="flex gap-2">
                    <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors" onClick={handleSubmit}>Submit</button>
                    <button className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition-colors" onClick={handleClear}>Clear</button>
                </div>
            </div>

            <div className="overflow-x-auto overflow-y-auto h-screen">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden text-left">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Accept Date</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Via</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Request Point</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Request</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && list.length > 0 ? (
                            list.map((ele, index) => (
                                <tr key={index} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                    <td className="py-4 px-6 text-sm text-gray-700 capitalize">{ele?.userId?.userName}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700">{moment(ele?.createdAt).format('YYYY-MM-DD')}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700">{moment(ele?.updatedAt).format('YYYY-MM-DD')}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700">{ele?.mode}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700">{ele?.amount}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700">{ele?.type}</td>
                                    <td className={`py-4 px-6 text-sm text-gray-700 ${ele?.status === 1 ? 'text-green-500' : 'text-red-500'}`}>{ele?.status === 1 ? 'Accepted' : 'Rejected'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="py-4 px-6 text-center text-sm text-gray-500">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="py-3">

                    <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={totalWrongPayment / size}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        pageClassName={'page-cls'}
                        activeClassName={'active'}
                        total={totalWrongPayment}
                        size={size}
                    />

                </div>
            </div>







        </div>
    )
}

export default FundHistory