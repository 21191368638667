import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { FaHome, FaUserAltSlash, FaUserAstronaut, FaUserCog } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { LuGamepad2 } from "react-icons/lu";
import { TbCoinOff, TbReportAnalytics, TbReportMedical } from "react-icons/tb";
// import { IoMdMenu } from "react-icons/io";
import { GiAngelWings, GiExpense } from "react-icons/gi";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdDoNotDisturbOnTotalSilence, MdKeyboardArrowRight, MdNotificationsNone, MdOutlineVideogameAssetOff } from "react-icons/md";
import profitTowin from "./profitTowin.png";
import { MdHistoryEdu } from "react-icons/md";

import { TfiWallet } from "react-icons/tfi";
import { TbSettingsUp } from "react-icons/tb";
import { RiExchangeFundsLine, RiSecurePaymentLine, RiStarLine } from "react-icons/ri";
import { TbPokerChip } from "react-icons/tb";

import { SiAdblock } from "react-icons/si";




function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);




  const menuItems = [
    { route: "/app/downsideUser", label: "ADMIN", roles: [0] },
    { route: "/app/bookie", label: "BOOKIE", roles: [0, 2] },
    { route: "/app/shop", label: "SHOP", roles: [0, 3] },
    { route: "/app/client", label: "CLIENT", roles: [0, 100] },
  ];

  // Filter items based on the user's role
  const userRole = localStoragedata?.roleId;

  const filteredMenu = menuItems.filter((item) =>
    item.roles.includes(userRole)
  );




  return (
    <>
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          } absolute top-0 left-0 px-10 pt-8   items-center justify-center  text-white focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 `}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          ></path>
        </svg>
      </button>
      {/* lg:flex lg:static  */}
      <div
        className={`${navbarOpen === false ? "hidden " : "flex "
          } fixed left-0 inset-y-0   w-72 z-50 flex-col`}
      >
        <div className="flex flex-col w-72 relative flex-1 h-full max-h-full ">

          <div className="cursor-pointer relative flex justify-between place-items-center  flex-shrink-0 bg-[#024CAA]   w-full z-50">
            <img
              src={`/matkabookielogo.webp`}
              alt=""
              className=" px-2 items-center drop-shadow-lg shadow-[#DB2777] py-[9px] w-[9.2rem] h-[4.5rem]"
            />

            <div className="">
              <GiHamburgerMenu
                onClick={handleNavbar}
                className=" text-white text-xl  block mx-4"
              />
            </div>
          </div>
          <nav className="overflow-y-auto bg-[#024CAA] scroll-hidden shadow    flex flex-col flex-wrap items-center justify-between relative  w-72 z-10   h-full overflow-auto zilla-slab-medium">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-2"}>
                <div className="">
                  <span
                    onClick={() => onClickMenu("/app/dashboard")}
                    className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/dashboard"
                      ? "text-[#024CAA] bg-white "
                      : "text-white   hover:text-[#DB2777]"
                      }`}
                  >
                    <span className="group-hover:text-[#DB2777]">
                      <FaHome size={18} />
                    </span>
                    <span className="text-base  whitespace-nowrap">Dashboard</span>
                  </span>
                  {/* {localStoragedata&&localStoragedata.roleId !==0 &&  <span onClick={() => onClickMenu('/app/user')} className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/user" ||
                                        location.pathname.includes("/app/user/") ||
                                        location.pathname.includes("/app/EditUser/")
                                        ? "text-white bg-[#992941] rounded-md"
                                        : "text-black"
                                        }`}>
                                        <span className="group-hover:text-[#DB2777]"><FaUserGear size={18} /></span>
                                        <span className="text-sm  whitespace-nowrap">User Management</span>
                                    </span>} */}
                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResult')} className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult" ? "text-white bg-[#992941] rounded-md" : "text-white   hover:text-[#DB2777]"}`}>
                                            <span className="group-hover:text-[#DB2777]"><FaRegNewspaper size={18} /></span>
                                            <span className="text-sm  whitespace-nowrap">Declare Result</span>
                                        </span>} */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResultStarline')} className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultStarline" ? "text-white bg-[#992941] rounded-md" : "text-white   hover:text-[#DB2777]"}`}>
                                            <span className="group-hover:text-[#DB2777]"><FaRegNewspaper size={18} /></span>
                                            <span className="text-sm  whitespace-nowrap">Declare FataFat Result</span>
                                        </span>} */}




                  {filteredMenu.map((menuItem, index) => (
                    <span
                      key={index}
                      onClick={() => navigate(menuItem.route)}
                      className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 ${location.pathname === menuItem.route
                        ? "text-white bg-[#DB2777]"
                        : "text-white hover:text-[#024CAA] hover:bg-white"
                        }`}
                    >
                      <span className="group-hover:text-[#DB2777]">
                        <ImUsers size={18} />
                      </span>
                      <span className="text-base whitespace-nowrap">{menuItem.label}</span>
                    </span>
                  ))}


                  {localStoragedata && localStoragedata.roleId == 0 && <span onClick={() => onClickMenu('/app/userofSuperadmin')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/userofSuperadmin" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><FaUserCog size={18} /></span>
                    <span className="text-base  whitespace-nowrap">User Management</span>
                  </span>}


                  <span onClick={() => onClickMenu('/app/notification')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/notification" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><MdNotificationsNone size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Notification Managemnt</span>
                  </span>

                  <span onClick={() => onClickMenu('/app/profit&loss')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/profit&loss" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><GiExpense size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Profit & Loss</span>
                  </span>

                  <span onClick={() => onClickMenu('/app/report-generation')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/report-generation" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><TbReportMedical size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Report Generation</span>
                  </span>


                  <span onClick={() => onClickMenu('/app/block-upi')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/block-upi" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><SiAdblock size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Block UPI</span>
                  </span>

                  <span onClick={() => onClickMenu('/app/create-account')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/create-account" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><FaUserAstronaut size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Create New Account </span>
                  </span>


                  <span onClick={() => onClickMenu('/app/wrong-payment')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/wrong-payment" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><RiSecurePaymentLine size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Wrong Payment </span>
                  </span>

                  <span onClick={() => onClickMenu('/app/grand-total-win')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/grand-total-win" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><MdDoNotDisturbOnTotalSilence size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Grand Total Win </span>
                  </span>


                  <span onClick={() => onClickMenu('/app/fund-history')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/fund-history" ? "text-white bg-[#DB2777] " : "text-white  hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><RiExchangeFundsLine size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Fund History </span>
                  </span>


                  <span onClick={() => onClickMenu('/app/cancel-bet-history')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/cancel-bet-history" ? "text-white bg-[#DB2777] " : "text-white   hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><TbCoinOff size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Cancel Bet History </span>
                  </span>

                  <span onClick={() => onClickMenu('/app/game-cancel')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/game-cancel" ? "text-white bg-[#DB2777] " : "text-white   hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><MdOutlineVideogameAssetOff size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Game Cancel </span>
                  </span>

                  <span onClick={() => onClickMenu('/app/inactive-user')} className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/inactive-user" ? "text-white bg-[#DB2777] " : "text-white   hover:text-[#024CAA] hover:bg-white"}`}>
                    <span className="group-hover:text-[#DB2777]"><FaUserAltSlash size={18} /></span>
                    <span className="text-base  whitespace-nowrap">Inactive Users </span>
                  </span>

                  {localStoragedata && localStoragedata?.roleId == 0 && (
                    <div className="cursor-pointer group">
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex justify-between items-between px-4 py-1 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-8"
                        >
                          <div className="flex justify-start place-content-center gap-4">

                            <span className="group-hover:text-[#DB2777]">
                              <TbSettingsUp size={18} />
                            </span>
                            <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                              Setting Management
                            </span>
                          </div>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#DB2777]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#DB2777]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex justify-between items-between px-4 py-1 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-8"
                        >
                          <div className="flex justify-start place-content-center gap-4">

                            <span className="group-hover:text-[#DB2777]">
                              <TbSettingsUp size={18} />{" "}
                            </span>
                            <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                              Setting Management
                            </span>
                          </div>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#DB2777]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#DB2777]" />
                          )}
                        </span>
                      )}
                      {showSection === "settingManagement" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() => onClickMenu("/app/appsetting")}
                            className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting"
                              ? "text-white bg-[#992941] rounded-md"
                              : "text-white   hover:text-[#024CAA] hover:bg-white"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  whitespace-nowrap">
                              App Setting
                            </span>
                          </span>

                        </div>
                      ) : null}
                    </div>
                  )}

                </div>





                {localStoragedata && (localStoragedata?.roleId == 0 || localStoragedata?.access && localStoragedata?.access["game_read"]) && (
                  <div className="cursor-pointer group">
                    {showSection === "game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                      >
                        <div className="flex justify-start place-content-center gap-4">

                          <span className="group-hover:text-[#DB2777]">
                            <LuGamepad2 size={18} />
                          </span>
                          <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                            Game Management
                          </span>
                        </div>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#DB2777]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#DB2777]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("game")}
                        className="flex justify-between items-center px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                      >
                        <div className="flex justify-start place-content-center gap-4">

                          <span className="group-hover:text-[#DB2777]">
                            <LuGamepad2 size={18} />{" "}
                          </span>
                          <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                            Game Management
                          </span>
                        </div>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#DB2777]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#DB2777]" />
                        )}
                      </span>





                    )}
                    {showSection === "game" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() => onClickMenu("/app/game")}
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/game"
                            ? "text-white bg-[#992941] rounded-md"
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >
                          {/* <span className="group-hover:text-[#DB2777]"><LuGamepad2 size={18} /></span> */}
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">Game </span>
                        </span>



                        {
                          localStoragedata && (localStoragedata?.roleId == 0 || localStoragedata?.access && localStoragedata?.access["market_read"]) && (
                            <span
                              onClick={() => onClickMenu("/app/market")}
                              className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/market"
                                ? "text-white bg-[#992941] rounded-md"
                                : "text-white   hover:text-[#DB2777]"
                                }`}
                            >
                              {/* <span className="group-hover:text-[#DB2777]"><FaShoppingBasket size={18} /></span> */}
                              <MdKeyboardArrowRight />{" "}
                              <span className="text-sm  whitespace-nowrap">
                                Market Management
                              </span>
                            </span>

                          )
                        }






                        <span
                          onClick={() => onClickMenu("/app/declareResult")}
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult"
                            ? "bg-[#992941] text-white  rounded-lg "
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">
                            Game Result
                          </span>
                        </span>




                        <span
                          onClick={() => onClickMenu("/app/betHistory")}
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/betHistory"
                            ? "bg-[#992941] text-white  rounded-lg "
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">
                            Bit Point History
                          </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/userbidhistory")
                          }
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/userbidhistory"
                            ? "text-white bg-[#992941] rounded-md"
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >

                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">User  Bet history </span>
                        </span>


                        {/* <span
                          onClick={() => onClickMenu("/app/winningList")}
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/winningList"
                            ? "bg-[#992941] text-white  rounded-lg "
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">
                            Winning List
                          </span>
                        </span> */}


                      </div>
                    ) : null}
                  </div>
                )}



                {localStoragedata &&
                  (localStoragedata?.roleId === 0 || localStoragedata?.access?.['StarLine_read']) && (
                    <div className="cursor-pointer group">
                      {showSection === "starLineManagment" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex items-center justify-between px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                        >
                          <div className="flex justify-start items-center gap-4">
                            <span className="group-hover:text-[#DB2777]">
                              <RiStarLine size={18} />
                            </span>
                            <span className="text-base group-hover:text-[#DB2777] whitespace-nowrap">
                              StarLine Management
                            </span>
                          </div>
                          <HiChevronUp className="group-hover:text-[#DB2777]" />
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("starLineManagment")}
                          className="flex items-center justify-between px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                        >
                          <div className="flex justify-start items-center gap-4">
                            <span className="group-hover:text-[#DB2777]">
                              <RiStarLine size={18} />
                            </span>
                            <span className="text-base group-hover:text-[#DB2777] whitespace-nowrap">
                              StarLine Management
                            </span>
                          </div>
                          <HiChevronDown className="group-hover:text-[#DB2777]" />
                        </span>
                      )}

                      {showSection === "starLineManagment" && (
                        <div className="mb-5 flex flex-col pl-10">
                          {[
                            { path: "/app/Brandgame", label: "Indian Starline Games" },
                            {
                              path: "/app/marketStarLine",
                              label: "StarLine Market",
                              condition: localStoragedata?.access?.["market_read"] // Add specific condition here
                            },
                            { path: "/app/declareResultStarline", label: "StartLine Result" },
                            { path: "/app/starline-bid-point-history", label: "StarLine Bit Point History" },
                            { path: "/app/starlineBidHistory", label: "Starline Bet History" },
                          ]
                            .filter(item => item.condition === undefined || item.condition) // Filter by condition
                            .map((item, index) => (
                              <span
                                key={index}
                                onClick={() => onClickMenu(item.path)}
                                className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150 ${location.pathname === item.path
                                  ? "text-white bg-[#992941] rounded-md"
                                  : "text-white hover:text-[#DB2777]"
                                  }`}
                              >
                                <MdKeyboardArrowRight />
                                <span className="text-sm whitespace-nowrap">{item.label}</span>
                              </span>
                            ))}
                        </div>
                      )}
                    </div>
                  )
                }

                {/* {localStoragedata && (localStoragedata?.roleId === 0 || localStoragedata?.access?.['JackPot_read']) && (
                  <div className="cursor-pointer group">
                    {showSection === "JackPotManagement" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex justify-between items-center px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                      >
                        <div className="flex justify-start place-content-center gap-4">

                          <span className="group-hover:text-[#DB2777]">
                           
                            <TbPokerChip size={18} />
                          </span>
                          <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                            JackPot Management
                          </span>
                        </div>
                        {showSection === "JackPotManagement" ? (
                          <HiChevronUp className="group-hover:text-[#DB2777]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#DB2777]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("JackPotManagement")}
                        className="flex justify-between items-center px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                      >
                        <div className="flex justify-start place-content-center gap-4">

                          <span className="group-hover:text-[#DB2777]">
                        
                            <TbPokerChip size={18} />{" "}
                          </span>
                          <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                            JackPot Management
                          </span>
                        </div>
                        {showSection === "JackPotManagement" ? (
                          <HiChevronUp className="group-hover:text-[#DB2777]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#DB2777]" />
                        )}
                      </span>
                    )}
                    {showSection === "JackPotManagement" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() => onClickMenu("/app/IndianJackpots")}
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/IndianJackpots"
                            ? "text-white bg-[#992941] rounded-md"
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">
                            Jackpot Games{" "}
                          </span>
                        </span>

                        {
                          localStoragedata && (localStoragedata?.roleId == 0 || localStoragedata?.access && localStoragedata?.access["market_read"]) && (
                            <span
                              onClick={() => onClickMenu("/app/marketJackpot")}
                              className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/marketJackpot"
                                ? "text-white bg-[#992941] rounded-md"
                                : "text-white   hover:text-[#DB2777]"
                                }`}
                            >
                             
                              <MdKeyboardArrowRight />{" "}
                              <span className="text-sm  whitespace-nowrap">
                                JackPot Market
                              </span>
                            </span>
                          )
                        }




                        <span
                          onClick={() =>
                            onClickMenu(
                              "/app/declareResultJackPot",
                              handleNavbar()
                            )
                          }
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultJackPot"
                            ? "bg-[#992941] text-white  rounded-lg "
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">
                            JackPot Result
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu("/app/jackpot-bid-point-history")}
                          className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/jackpot-bid-point-history"
                            ? "bg-[#992941] text-white  rounded-lg "
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">
                            JackPot Bit Point History
                          </span>
                        </span>


                        <span
                          onClick={() =>
                            onClickMenu("/app/jackpotBetHistory")
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/jackpotBetHistory"
                            ? "text-white bg-[#992941] rounded-md"
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >

                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">Jackpot  Bet history </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu("/app/jackpotWinnerList")
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/jackpotWinnerList"
                            ? "text-white bg-[#992941] rounded-md"
                            : "text-white   hover:text-[#DB2777]"
                            }`}
                        >

                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  whitespace-nowrap">Jackpot Winner List </span>
                        </span>


                      </div>
                    ) : null}
                  </div>
                )} */}
                <div className="cursor-pointer group">
                  {showSection === "walletMangment" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex justify-between  items-center px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                    >
                      <div className="flex justify-start place-content-center gap-4">

                        <span className="group-hover:text-[#DB2777]">
                          <TfiWallet size={18} />
                        </span>
                        <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                          Wallet Management
                        </span>
                      </div>
                      {showSection === "walletMangment" ? (
                        <HiChevronUp className="group-hover:text-[#DB2777]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#DB2777]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("walletMangment")}
                      className="flex justify-between items-center px-4 py-1 space-x-4 text-base text-white transition duration-150 ease-in-out cursor-pointer h-8"
                    >
                      <div className="flex justify-start place-content-center gap-4">

                        <span className="group-hover:text-[#DB2777]">
                          <TfiWallet size={18} />{" "}
                        </span>
                        <span className="text-base group-hover:text-[#DB2777]  whitespace-nowrap">
                          Wallet Management
                        </span>
                      </div>
                      {showSection === "walletMangment" ? (
                        <HiChevronUp className="group-hover:text-[#DB2777]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#DB2777]" />
                      )}
                    </span>
                  )}
                  {showSection === "walletMangment" ? (
                    <div className=" mb-5  flex flex-col pl-10">

                      <span
                        onClick={() => onClickMenu("/app/AddFundRequest")}
                        className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/game"
                          ? "text-white bg-[#992941] rounded-md"
                          : "text-white   hover:text-[#DB2777]"
                          }`}
                      >
                        {/* <span className="group-hover:text-[#DB2777]"><LuGamepad2 size={18} /></span> */}
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  whitespace-nowrap">Add Fund Report </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawRequest")
                        }
                        className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/addFundReport"
                          ? "text-white bg-[#992941] rounded-md"
                          : "text-white   hover:text-[#DB2777]"
                          }`}
                      >

                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  whitespace-nowrap">Withdraw Fund Report</span>
                      </span>


                      <span
                        onClick={() =>
                          onClickMenu("/app/addFund")
                        }
                        className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/addFund"
                          ? "text-white bg-[#992941] rounded-md"
                          : "text-white   hover:text-[#DB2777]"
                          }`}
                      >

                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  whitespace-nowrap">Add Fund </span>
                      </span>

                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawfund")
                        }
                        className={`cursor-pointer h-8 items-center flex space-x-4 px-1 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/withdrawfund"
                          ? "text-white bg-[#992941] rounded-md"
                          : "text-white   hover:text-[#DB2777]"
                          }`}
                      >

                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  whitespace-nowrap">Withdraw Fund </span>
                      </span>


                    </div>
                  ) : null}
                </div>

                <span
                  onClick={() =>
                    onClickMenu("/app/ledger")
                  }
                  className={`cursor-pointer h-8 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/ledger"
                    ? "text-white bg-[#DB2777] "
                    : "text-white   hover:text-[#DB2777]"
                    }`}
                >

                  <MdHistoryEdu size={18} />{" "}
                  <span className="text-base  whitespace-nowrap">My Statements </span>
                </span>







              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
