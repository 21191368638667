import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './WalletManagement.json'
import { gamesActions, marketActions, walletActions } from '../../_actions';
// import { TfiEye } from "react-icons/tfi";
import ViewGameModal from "./components/ViewGameModal/ViewGameModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom';


// import Table from '../../components/Atoms/Table/Table';


const PointTransferReport = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [createModal, setCreateModal] = useState(false);
  let [updateModal, setUpdateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [selectedMarket, setSelectedMarket] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const[fromDate,setfromDate] =useState("");
  const[toDate,settoDate] =useState("");

  const [options, setoptions] = useState([{ "name": 'option one', "id": "1" }, { "name": 'option owo', "id": "2" }]);
  // console.log('options.....', options);
  const { paymentList, totalPayment,loading,getPointsList,getPointstotal } = useSelector(state => state.wallet);
 
  

  useEffect(() => {
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
    
      "pageNo": pageNo,
      "size": size
    }
    dispatch(walletActions.getTransferPointListForAdmin(gameReq));


  }, []);


  const handleViewHideModal = (data) => {
    setViewModal(false);
    setRowData({});
  }

 const  createGameSubmit2 =()=>{
  let gameReq = {
    "fromDate": fromDate,
    "toDate": toDate,
    "keyWord": "",
    "sortBy": "",
    "sortOrder": "",
    "pageNo": pageNo,
    "size": size
  }
  if(fromDate !== ""&& toDate!==""||fromDate==""&&toDate==""){
    dispatch(walletActions.getTransferPointListForAdmin(gameReq));
  }
 }


  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {

      let selectedmarketData = selectedMarket && selectedMarket.length > 0 ?
        selectedMarket.map((element) => (element._id)) : null

      let gamePageRefresh = {
        "fromDate": fromDate,
        "toDate": toDate,
        "keyWord": "",
        "sortBy": "",
        "sortOrder": "",
      
        "pageNo": pageNo,
        "size": size
      }

      // console.log('selectedmarketData???', selectedmarketData);

      const { name, shortName, openTime, closingTime, priority, backgroundColor } = fieldsGame;
      dispatch(gamesActions.createGames({
        name: name,
        shortName: shortName,
        openTime: openTime,
        closingTime: closingTime,
        priority: priority,
        backgroundColor: backgroundColor,
        marketList: selectedmarketData,
        image: "https://res.cloudinary.com/dnd2isyjo/image/upload/v1712911734/your_folder_name/s4f9b6byp7u6weyrjfyg.jpg"
      }, setCreateModal, gamePageRefresh));
    }
  };

  const handleValidationUpdateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.shortName || fieldsUpdate.shortName === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdate.openTime || fieldsUpdate.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    }

    if (!fieldsUpdate.closingTime || fieldsUpdate.closingTime === "") {
      formIsValid = false;
      errors.closingTime = DashboardJSON.ErrorMsg.closingTime;
    }

    if (!fieldsUpdate.priority || fieldsUpdate.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    if (!fieldsUpdate.backgroundColor || fieldsUpdate.backgroundColor === "") {
      formIsValid = false;
      errors.backgroundColor = DashboardJSON.ErrorMsg.backgroundColor;
    }
    console.log('errorsUpdate????', errorsUpdate);
    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.name || fieldsGame.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsGame.shortName || fieldsGame.shortName === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsGame.openTime || fieldsGame.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    }

    if (!fieldsGame.closingTime || fieldsGame.closingTime === "") {
      formIsValid = false;
      errors.closingTime = DashboardJSON.ErrorMsg.closingTime;
    }

    if (!fieldsGame.priority || fieldsGame.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    if (!fieldsGame.backgroundColor || fieldsGame.backgroundColor === "") {
      formIsValid = false;
      errors.backgroundColor = DashboardJSON.ErrorMsg.backgroundColor;
    }

    setErrorsGame(errors);
    return formIsValid;
  };


  const disableGame = (data) => {
    let gameIdReq = {
      "id": data._id,
    }
    let gamePageRefresh = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
    
      "pageNo": pageNo,
      "size": size
    }
    confirmAlert({

      title: 'Confirm to disable Game?',
      message: `Are you sure to disable ${data.gameName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(gamesActions.updateGamesStatus(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const deleteGame = (data,elem) => {
    let gameIdReq = {
      "paymentId": data._id,
      "status":elem
    }

    let gamePageRefresh = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
    
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({


      title: 'Confirm to Change Status?',
      message: `Are you sure to change Status of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const handleFile = (event) => {
    console.log("handleFileevent", event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(gamesActions.uploadImage(event.target.files[event.target.files.length - 1]));
      // this.setState({ selectedFile: null });
      setSelectedFile(null)

    }
    else {
      console.log("No File To Upload!")
    }

  }


  const onSelect = (selectedList, selectedItem) => {
    setSelectedMarket(selectedList);
    // this.setState({ selectedMarket: selectedList });
  }

  const onRemove = (selectedList, removedItem) => {
    // console.log('selectedList', selectedList);
    // console.log('removedItem', removedItem);
    setSelectedMarket(selectedList);
    // this.setState({ selectedMarket: selectedList });
  }


  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected)
    setOffset(offset);
     setPageNo(data.selected+1);
    let pageReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
    
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(walletActions.getTransferPointListForAdmin(pageReq));
  }

  console.log(getPointsList)
  console.log(getPointstotal)

  return (
    <>
 <Loader loading={loading}/>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <sectiion className="flex justify-between my-4 ">
                        <div className='text-xl font-semibold'>Dashboard{" "}/{" "}<span className='text-[#7D1970]'>Point Transfer Report</span></div>
                      </sectiion>

                      <div className="px-6 py-4 text-left bg-white space-y-2 rounded-lg">
                        <div className='flex justify-between items-center px-3 border-b'>
                          <span className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white  ">
                          Point Transfer Report
                          </span>
                        </div>

                        <div className='flex  justify-end w-full space-x-5'>
                          <div className='flex flex-wrap md:flex-nowrap justify-between gap-3'>

                          
                          <div className="w-full mt-1 shadow-sm">
                            <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">From:</label>
                            <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="date" name="date" placeholder="Enter date" value={fromDate} type="date" onChange={(e)=>setfromDate(e.target.value)} />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-yellow-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>
                          <div className="w-full mt-1 shadow-sm">
                            <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">To:</label>
                            <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="date" name="date" placeholder="Enter date" value={toDate} type="date" onChange={(e)=>settoDate(e.target.value)} />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-yellow-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>
                          <div className="w-full flex justify-start items-end">
                            <button className="flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-green-500 border border-green-500 rounded-xl focus:outline-none" type="button" onClick={createGameSubmit2}>Submit</button>
                          </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                          <table className="w-full text-sm text-left rtl:text-right text-black ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th scope="col" className="px-6 py-3">#</th>
                                <th scope="col" className="px-6 py-3">Sender Name</th>
                                <th scope="col" className="px-6 py-3">Reciver Name</th>
                                <th scope="col" className="px-6 py-3">Amount</th>
                                <th scope="col" className="px-6 py-3">Type</th>
                                <th scope="col" className="px-6 py-3">Date</th>
                                {/* <th scope="col" className="px-6 py-3 text-right">action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {getPointsList && getPointsList.length > 0 ? getPointsList.map((element, index) =>{
                               
                                return(
                                  <React.Fragment key={index}>
                                    <tr key={index} className={`bg-white border-b border-gray-400`}>
                                      <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap ">
                                        {offset + index + 1}
                                      </th>
                                      <th scope="row" className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap ">
                                      <Link to={`/app/user/${element.senderUser?._id}`}>{element && element.senderUser ? element?.senderUser?.userName : "-"}</Link>  
                                      </th>
                                      <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap ">
                                        {element && element.recipientUser ? element.recipientUser : "-"}
                                      </th>
                                      <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap ">
                                        {element && element.amount ? element.amount : "--"}
                                      </th>
                                 
                                      <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap ">
                                        {element && element.type ? element.type : "--"}
                                      </th>
                                 
                                 
                                      <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap ">
                                        {element && element.createdAt ?new Date(element.createdAt).toLocaleString() : "-"}
                                      </th>
                                 
                            
                                    </tr>
                                  </React.Fragment>
                                ) 
                              })
                               : null
                              }
                            </tbody>
                          </table>
                         
                        </div>
                        {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          getPointstotal && getPointstotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={getPointstotal / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          getPointstotal && getPointstotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={getPointstotal / size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                            : null}
                      </nav>
                  }
                      </div>
                    </div>
                  </div>

                  <ViewGameModal
               rowData={rowData}
               viewModal={viewModal}
               handleViewHideModal={handleViewHideModal}
                  />


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointTransferReport;