import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, gamesActions, marketActions, reportActions } from '../../_actions'
import TableGenrateReport from './TableGenrateReport'
import Loader from '../../components/Loader/Loader'

const ReportGenrate = () => {
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    const [marketId, setMarketId] = useState('')
    const [userId, setUserId] = useState('')
    const [date, setDate] = useState(formattedDate)
    const [type, setType] = useState('')
    const [gameId, setGameId] = useState('')


    let { reports, games } = selector ? selector : {};
    let { gamesList } = games ? games : {}
    let { getBetListForAdmin2 } = reports ? reports : {};
    let { resData } = getBetListForAdmin2 ? getBetListForAdmin2 : {}


    useEffect(() => {

        let cred = {

            "gameId": "",
            "marketId": "",
            "userId": "",
            "isOpen": "",
            "fromDate": "2024-12-03",
            "toDate": "2024-12-03",
            "keyWord": "",
            "pageNo": 1,
            "size": 100,
            "sortBy": "",
            "sortOrder": ""

        }
        let marketData = {
            "fromDate": "",
            "toDate": "",
            "keyWord": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 1000,
            "isJackpot": false,
            "isStarLine": false
        }
        dispatch(reportActions.getBetsListForAdmin2(cred))
        dispatch(marketActions.getMarketList(marketData))
        dispatch(gamesActions.getAllUser());


    }, [])



    const singleDigitData = resData && resData.length > 0 ? resData.filter((item) => item.docs[0].parentMarketName === "Single Digit") : [];
    const jodiData = resData && resData.length > 0 ? resData.filter((item) => item.docs[0].parentMarketName === "Jodi") : [];
    const singlePanaData = resData && resData.length > 0 ? resData.filter((item) => item.docs[0].parentMarketName === "Single Pana") : [];
    const doublePanaData = resData && resData.length > 0 ? resData.filter((item) => item.docs[0].parentMarketName === "Double Pana") : [];
    const triplaPanaData = resData && resData.length > 0 ? resData.filter((item) => item.docs[0].parentMarketName === "Triple Pana") : [];
    const fullSangamData = resData && resData.length > 0 ? resData.filter((item) => item.docs[0].parentMarketName === "Full Sangam") : [];
    const halfSangamData = resData && resData.length > 0 ? resData.filter((item) => item.docs[0].parentMarketName === "Half Sangam") : [];

    const handleMarketId = (e) => {
        setMarketId(e.target.value)
    }
    const handleGameId = (e) => {
        setGameId(e.target.value)
    }

    const handleDateOnChnage = (e) => {
        let newDate = e.target.value
        setDate(newDate)
    }
    const handleType = (e) => {
        setType(e.target.value)
    }
    const handleUserId = (e) => {
        setUserId(e.target.value)
    }
    const handleClear = () => {
        let cred = {
            "gameId": "",
            "marketId": "",
            "userId": "",
            "isOpen": "",
            "fromDate": "2024-12-03",
            "toDate": "2024-12-03",
            "keyWord": "",
            "pageNo": 1,
            "size": 100,
            "sortBy": "",
            "sortOrder": ""
        }
        dispatch(reportActions.getBetsListForAdmin2(cred))
        setGameId('');
        setUserId('');
        setDate(formattedDate);
        setMarketId('');
        setType('')

    }
    const handleSubmit = () => {
        let submitData = {
            "gameId": gameId,
            "marketId": marketId,
            "userId": userId,
            "isOpen": type,
            "fromDate": date,
            "toDate": date,
            "keyWord": "",
            "pageNo": 1,
            "size": 100,
            "sortBy": "",
            "sortOrder": ""

        }
        dispatch(reportActions.getBetsListForAdmin2(submitData))

        console.log('submitDatasubmitData', submitData)
    }



    const handleAllCopy = () => {
        if (resData && resData.length > 0) {
            // Initialize the header
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";

            // Loop through resData to construct table rows
            resData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }

    const handleSingleDataCopy = () => {
        if (singleDigitData && singleDigitData.length > 0) {
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";
            singleDigitData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }
    const handleCopyJodi = () => {
        if (jodiData && jodiData.length > 0) {
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";
            jodiData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }

    const handleSinglePanaDataCopy = () => {
        if (singlePanaData && singlePanaData.length > 0) {
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";
            singlePanaData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }
    const handleDoublePanaCopy = () => {
        if (doublePanaData && doublePanaData.length > 0) {
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";
            doublePanaData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }
    const handleTriplePanaDataCopy = () => {
        if (triplaPanaData && triplaPanaData.length > 0) {
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";
            triplaPanaData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }
    const handleFullSangamDataCopy = () => {
        if (fullSangamData && fullSangamData.length > 0) {
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";
            fullSangamData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }
    const handleHalfSangamDataCopy = () => {
        if (halfSangamData && halfSangamData.length > 0) {
            let tableData = "Game\tType\tOpen/Close\tNo\tBet\n";
            halfSangamData.forEach((ele) => {
                ele.docs.forEach((e) => {
                    tableData += `${e?.gameName || "-"}\t${e?.marketName || "-"}\t${e?.isOpen === true ? "open" : "close"
                        }\t${e?.betNumber || ""}\t${e?.amount || "-"}\n`;
                });
            });
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    dispatch(alertActions.success('table data copied'))
                })
                .catch((err) => {
                    console.error("Could not copy data: ", err);
                });
        } else {
            alert("No data to copy!");
        }
    }





    return (
        <div className='overflow-hidden overflow-y-auto p-3'>
            <Loader loading={selector?.reports?.loading} />
            <div className='grid grid-cols-1 md:grid-cols-7 items-end px-5  gap-1 '>
                <div className='w-full'>
                    <div className="flex-1">
                        <label class="block text-gray-700 text-base mb-2" for="name">Market Name :</label>
                        <select className="w-full px-4 py-2.5 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize"
                            id="userId" name="userId" placeholder="name"
                            value={marketId}
                            onChange={handleMarketId}
                            type="text"  >
                            <option value="">Select Game Name</option>
                            {selector?.markets?.marketList && selector?.markets?.marketList.length > 0
                                ? selector?.markets?.marketList.filter(item => (item.parentMarketId === true && !item.isJackpot && !item.isJackpot)).map((element, index) => (
                                    <option
                                        value={
                                            element && element._id
                                                ? element._id
                                                : ""
                                        }
                                    >
                                        {element && element.name
                                            ? element.name
                                            : ""}
                                    </option>
                                ))
                                : null}

                        </select>

                    </div>

                </div>
                <div className='w-full'>
                    <div className="flex-1">
                        <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                        <select className="w-full px-4 py-2.5 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize"
                            id="userId" name="userId" placeholder="name"
                            value={userId}
                            type="text" onChange={handleGameId} >
                            <option value="">Select Game Name</option>
                            {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                            )) : null}

                        </select>

                    </div>
                </div>
                <div className='w-full'>
                    <div className="flex-1">
                        <label className="block text-gray-700 text-base mb-2">Select Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={handleDateOnChnage}
                            className="w-full px-4 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                </div>
                <div className='w-full'>
                    <div className='flex-1'>
                        <label class="block text-gray-700 text-base mb-2" for="name">Game Type</label>
                        <select onChange={handleType} value={type} className="w-full px-4 py-2.5 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize">
                            <option value=''>Slect Type</option>
                            <option value={`true`}>Open</option>
                            <option value={`false`}>close</option>

                        </select>
                    </div>
                </div>
                <div className='w-full'>
                    <div className="flex-1">
                        <label className="block text-gray-700 text-base mb-2">Select User</label>
                        <select
                            className="w-full px-4 py-2.5 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize  "
                            id="Users"
                            value={userId}
                            name="Users"
                            onChange={handleUserId}
                        >
                            <option value="">Select User</option>
                            {selector?.games?.allUserList?.map((element) => (
                                <option key={element._id} value={element._id}>
                                    {element.userName || "N/A"}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='w-full '>
                    <button className='bg-black w-full py-2 rounded-md text-white' onClick={handleSubmit}>Submit</button>
                </div>
                <div className='w-full'>
                    <button className='bg-blue-500 w-full py-2 rounded-md text-white' onClick={handleClear}>Clear</button>
                </div>
            </div>

            <div className="  sm:p-5 space-y-4 ">
                <div className="relative overflow-x-auto rounded-md  bg-white p-3 border">
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2' onClick={handleAllCopy}>Copy All</button>
                    <TableGenrateReport resData={resData} />
                    <div className='flex justify-end place-items-center gap-2'>
                        <div>
                            {resData && resData.length > 0 && (
                                <div>
                                    <span className='pe-2'>Total Bet:</span>
                                    {resData.reduce((acc, curr) => acc + curr.docs.length, 0)}
                                </div>
                            )}
                        </div>,
                        <div className=''>
                            <span className='pe-2'>Total Amount:</span>{resData && resData.length > 0 ? resData?.reduce(
                                (acc, curr) => acc + curr.totalAmount,
                                0
                            ) : null}
                        </div>


                    </div>
                </div>

                <div className="relative overflow-x-auto  rounded-md  bg-white p-3 border" onClick={handleSingleDataCopy}>
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2'>Copy Single Digit</button>
                    <TableGenrateReport resData={singleDigitData} />
                    {/* <div className='flex justify-end place-items-center gap-2'>
                        <div>
                            {resData && resData.length > 0 && (
                                <div>
                                    <span className='pe-2'>Total Bet:</span>
                                    {resData.reduce((acc, curr) => acc + curr.docs.length, 0)}
                                </div>
                            )}
                        </div>,
                        <div className=''>
                            <span className='pe-2'>Total Amount:</span>{resData && resData.length > 0 ? resData?.reduce(
                                (acc, curr) => acc + curr.totalAmount,
                                0
                            ) : null}
                        </div>
                    </div> */}
                </div>

                <div className="relative overflow-x-auto rounded-md shadow  bg-white p-3 border" onClick={handleCopyJodi}>
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2'>Copy Jodi</button>
                    <TableGenrateReport resData={jodiData} />

                </div>

                <div className="relative overflow-x-auto rounded-md shadow  bg-white p-3 border" onClick={handleSinglePanaDataCopy}>
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2'>Copy Single Pana</button>
                    <TableGenrateReport resData={singlePanaData} />

                </div>

                <div className="relative overflow-x-auto rounded-md shadow  bg-white p-3 border" onClick={handleDoublePanaCopy}>
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2'>Copy Double Pana</button>
                    <TableGenrateReport resData={doublePanaData} />

                </div>

                <div className="relative overflow-x-auto rounded-md shadow  bg-white p-3 border" onClick={handleTriplePanaDataCopy}>
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2'>Copy Triple Pana</button>
                    <TableGenrateReport resData={triplaPanaData} />
                </div>

                <div className="relative overflow-x-auto  shadow rounded-md bg-white p-3 border" onClick={handleFullSangamDataCopy}>
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2'>Copy Full Sangam</button>
                    <TableGenrateReport resData={fullSangamData} />
                </div>

                <div className="relative overflow-x-auto  shadow rounded-md bg-white p-3 border" onClick={handleHalfSangamDataCopy}>
                    <button className='bg-white px-4 py-2 border rounded-md shadow mb-2'>Copy Half Sangam</button>
                    <TableGenrateReport resData={halfSangamData} />
                </div>
            </div>
        </div>
    )
}

export default ReportGenrate