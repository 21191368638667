import React from 'react'

const TableGenrateReport = ({ resData }) => {
    return (
        <div>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th className="px-6 py-3">Game</th>
                        <th className="px-6 py-3">Type</th>
                        <th className="px-6 py-3">open close</th>
                        <th className="px-6 py-3">No</th>
                        <th className="px-6 py-3">Bet</th>

                    </tr>
                </thead>
                <tbody>
                    {resData && resData.length > 0 ? (
                        resData.map((ele, index) => {

                            return (
                                <>
                                    {
                                        ele?.docs?.map((e) => (
                                            <tr className='border-b'>
                                                <td className="px-6 py-4 font-medium text-gray-900 capitalize">
                                                    {e?.gameName || "-"}
                                                </td>
                                                <td className="px-6 py-4 font-medium text-gray-900 capitalize">
                                                    {e?.marketName || "-"}
                                                </td>
                                                <td className="px-6 py-4 font-medium text-gray-900 capitalize">
                                                    {e?.isOpen === true ? 'Opne' : "Close"}
                                                </td>
                                                <td className="px-6 py-4 font-medium text-gray-900 capitalize">
                                                    {e?.betNumber || "-"}
                                                </td>
                                                <td className="px-6 py-4 font-medium text-gray-900 capitalize">
                                                    {e?.amount || "-"}
                                                </td>


                                            </tr>
                                        ))
                                    }
                                </>




                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="4" className="px-6 py-4 text-center bg-white">
                                No Data Found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TableGenrateReport