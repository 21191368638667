import React from "react";

const CardsDashboard = ({ title, value, progress = 100, icon: Icon, progressBarColor = "bg-teal-500" }) => {
    return (
        <div>
            <div className="w-auto bg-white shadow-md rounded-lg p-6 flex flex-col items-start space-y-4">
                {/* Title and Icon */}
                <div className="flex justify-between items-center w-full">
                    <div>
                        <div className="text-sm font-semibold text-black">{title}</div>
                        <div className="text-2xl font-bold pt-1" style={{ color: progressBarColor }}>{value}</div>
                    </div>
                    <div className={`p-2 rounded-full ${progressBarColor}`} >
                        {Icon && <Icon className="w-5 h-5 text-white" />}
                    </div>
                </div>


                <div className="w-full h-1 bg-gray-200 rounded-full">
                    <div
                        className={`h-2 rounded-full ${progressBarColor}`}
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default CardsDashboard;
