import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import DashboardJSON from "./WalletManagement.json";
import { gamesActions, reportActions } from "../../_actions";
import ReactPaginate from "react-paginate";
// import { isMobile } from "react-device-detect";
import moment from "moment";
// import Select from "react-select";
// import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

// import Table from '../../components/Atoms/Table/Table';

const Ledger = () => {
  let selector = useSelector((state) => state);
  const dispatch = useDispatch();

  let { games, reports } = selector ? selector : {};
  let { getLedgerList, getLedgerTotal,loading } = reports ? reports : {};
  let { allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});

  // console.log('options.....', options);
  // const { reportsList, reportsTotal,loading } = useSelector(state => state.reports);
  // const { userList, userTotal } = useSelector((state) => state.reports);
  // const { gamesList, gamesTotal } = useSelector((state) => state.games);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyWord, setkeyWord] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [Options, setOptions] = useState([]);
  const [toDate, settoDate] = useState("");
  const [selecteddata, setselecteddata] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      userId: selecteddata,
    };

    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    // dispatch(gamesActions.getAllGames(gameReq2))
    // dispatch(reportActions.getBetsListForAdmin(gameReq));
    dispatch(reportActions.getAllEntries(gameReq));
    // dispatch(gamesActions.getAllMarket())
    // dispatch(gamesActions.getAllUser())
  }, []);

  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id,
      }));
      console.log(options);
      setOptions(options);
    }
  }, [allUserList]);

  console.log(Options);
  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate2 = (e) => {
    console.log(e);
    setselecteddata(e);
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    // if (handleValidationCreateGame()) {
    let gameReq2 = {
      fromDate: fieldsGame?.date || "",
      toDate: fieldsGame?.todate || "",
      keyWord: fieldsGame?.keyWord || "",
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
      userId:
        selecteddata && selecteddata.length > 0
          ? selecteddata.map((e) => e.value)
          : [],
    };
    dispatch(reportActions.getAllEntries(gameReq2));
    // }
  };

  // const handleValidationCreateGame = () => {
  //   let formIsValid = true;
  //   let errors = {};

  //   if (!fieldsGame.userId || fieldsGame.userId === "") {
  //     formIsValid = false;
  //     errors.userId = "Select Game Name";
  //   }

  //   if (!fieldsGame.userId2 || fieldsGame.userId2 === "") {
  //     formIsValid = false;
  //     errors.userId2 = "Select Game Type";
  //   }

  //   if (!fieldsGame.todate || fieldsGame.todate === "") {
  //     formIsValid = false;
  //     errors.todate = "Select Date";
  //   }

  //   if (!fieldsGame.date || fieldsGame.date === "") {
  //     formIsValid = false;
  //     errors.date = "Select Date";
  //   }

  //   setErrorsGame(errors);
  //   return formIsValid;
  // };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setCurrentPage(data.selected);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      userId: selecteddata,
    };
    dispatch(reportActions.getAllEntries(pageReq));
  };

  const handleInputSearch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    let pageReq = {
      type: "WITHDRAW",
      keyWord: value,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: 100,
    };
    // dispatch(walletActions.getPaymentListForAdmin(pageReq));
  };

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    setSize(e.target.value);
    let pageReq = {
      // "type": "WITHDRAW",
      keyWord: keyWord,
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: value,
      userId: selecteddata,
    };
    dispatch(reportActions.getAllEntries(pageReq));
  };
  // const [selectedValues, setSelectedValues] = useState([]);

  // const handleMultiSelectChange = (selectedValues) => {
  //   setSelectedValues(selectedValues);
  // };
  let sessionData = JSON.parse(window.sessionStorage.getItem("adminuser"));

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-2">
                      {/* <sectiion className="flex justify-between my-4 ">
                        <div className='text-xl font-semibold'>Dashboard{" "}/{" "}<span className='text-[#7D1970]'>Bid History (Main Market)</span></div>
                      </sectiion> */}
                      <div className="px-6  text-left  space-y-2 rounded-lg">

                        <div className="flex justify-between md:flex-nowrap  gap-3 flex-wrap items-end  w-full ">
                          <div className="mt-1 shadow-sm w-full">
                            <label
                              class=" mb-1 block  text-sm font-medium text-gray-900"
                              for="gamename"
                            >
                              From Date :
                            </label>
                            <input
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded focus:outline-none"
                              id="date"
                              name="date"
                              placeholder="Enter date"
                              value={
                                fieldsGame && fieldsGame["date"]
                                  ? fieldsGame["date"]
                                  : ""
                              }
                              max={today}
                              type="date"
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame["date"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                            ) : null}
                          </div>

                          <div className="mt-1 shadow-sm w-full">
                            <label
                              class=" mb-1 block  text-sm font-medium text-gray-900"
                              for="gamename"
                            >
                              To Date :
                            </label>
                            <input
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded focus:outline-none"
                              id="date"
                              name="todate"
                              placeholder="Enter date"
                              value={
                                fieldsGame && fieldsGame["todate"]
                                  ? fieldsGame["todate"]
                                  : ""
                              }
                              max={today}
                              type="date"
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame["todate"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["todate"]}
                              </div>
                            ) : null}
                          </div>

                          {/* <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                            <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId" name="userId" placeholder="name"
                              value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                              <option value="">Select Game Name</option>
                              {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                              )) : null}

                            </select>
                            {errorsGame && errorsGame["userId"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId"]}
                              </div>
                              : null}
                          </div> */}

                          {/* <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name">Game Type :</label>
                            <select className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId2" name="userId2" placeholder="name"
                              value={fieldsGame && fieldsGame["userId2"] ? fieldsGame["userId2"] : ""} type="text" onChange={inputChangeCreate} >
                              <option value="">Select Game Type</option>
                              {allMarket && allMarket.length > 0 ? allMarket.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                              )) : null}
                            </select>
                            {errorsGame && errorsGame["userId2"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["userId2"]}
                              </div>
                              : null}
                          </div> */}

                          {/* <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name"> User :</label>
                        
                            <Select
                              isMulti
                              name="colors"
                              value={selecteddata}
                              options={Options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={inputChangeCreate2}
                            />

                          </div> */}

                          <div className="w-full flex mt-2 md:mt-0 justify-start items-end">
                            <button
                              className="flex w-full lg:w-auto  justify-center px-4 py-1.5 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded focus:outline-none"
                              type="button"
                              onClick={createGameSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="px-0 lg:px-4 py-4 text-left bg-white space-y-2 rounded-lg">
                        <div className="flex  justify-between items-center px-3 py-2 border-b">
                          <span className="font-semibold text-left rtl:text-right  text-gray-900 bg-white">
                            My Point Statement List
                          </span>
                          <div className="flex flex-col lg:flex-row justify-start items-center gap-1">
                            <label
                              className="block text-sm lg:text-base text-gray-700 mb-1"
                              htmlFor="size"
                            >
                              Data
                            </label>
                            <select
                              className="w-full  px-2 py-1.5 text-sm  bg-white border border-gray-400 rounded-md focus:outline-none lg:max-w-none lg:px-3 lg:py-2"
                              id="size"
                              name="size"
                              value={fieldsGame && fieldsGame["size"] ? fieldsGame["size"] : ""}
                              onChange={handleInputSize}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </div>

                        <div className="relative overflow-x-auto p-1 lg:p-4">

                          <table className="w-full text-sm text-left rtl:text-right text-black ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">category</th>
                                <th className="px-6 py-3">description</th>
                                <th className="px-6 py-3">credit</th>
                                <th className="px-6 py-3">Debit</th>
                                <th className="px-6 py-3">wallet</th>
                                <th className="px-6 py-3">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getLedgerList && getLedgerList.length > 0 ? (
                                getLedgerList.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr
                                      key={index}
                                      className="bg-white border-b border-gray-400"
                                    >
                                      <th
                                        scope="row"
                                        className="px-6 py-2 font-medium text-black whitespace-nowrap "
                                      >
                                        {offset + index + 1}
                                      </th>

                                      <th
                                        scope="row"
                                        className="px-6 py-2  text-black font-semibold whitespace-nowrap "
                                      >
                                        {element &&
                                          element.category &&
                                          element.category
                                          ? element.category
                                          : "-"}
                                      </th>

                                      <th
                                        scope="row"
                                        className="px-6 py-2 font-medium text-black whitespace-nowrap "
                                      >
                                        {element &&
                                          element.description &&
                                          element.description
                                          ? element.description
                                          : "-"}
                                      </th>

                                      <th
                                        scope="row"
                                        className="px-6 py-2 font-medium text-black whitespace-nowrap "
                                      >
                                        {element &&
                                          element.type &&
                                          element.type == "credit"
                                          ? element?.amount
                                          : "-"}
                                      </th>
                                      <th
                                        scope="row"
                                        className="px-6 py-2 font-medium text-black whitespace-nowrap "
                                      >
                                        {element &&
                                          element.type &&
                                          element.type == "debit"
                                          ? element?.amount
                                          : "-"}
                                      </th>
                                      {sessionData &&
                                        sessionData.roleId !== 0 ? (
                                        <th
                                          scope="row"
                                          className="px-6 py-2 font-medium text-black whitespace-nowrap "
                                        >
                                          {element &&
                                            element.walletAmount &&
                                            element.walletAmount
                                            ? element.walletAmount
                                            : "-"}
                                        </th>
                                      ) : (
                                        <th className="px-6 py-2 font-medium text-black whitespace-nowrap ">
                                          -
                                        </th>
                                      )}
                                      {/* <th scope="row" className="px-6 py-2 font-medium text-black whitespace-nowrap ">
                                      {element && element.marketId && element.marketId.name ? element.marketId.name : "-"}
                                    </th> */}

                                      <th
                                        scope="row"
                                        className="px-6 py-2 font-medium text-black whitespace-nowrap "
                                      >
                                        {element && element.createdAt
                                          ? moment(
                                            new Date(
                                              parseInt(element.createdAt)
                                            )
                                          )
                                            .utcOffset("+05:30")
                                            .format("YYYY-MM-DD hh:mm:ss a")
                                          : "-"}
                                      </th>
                                      {/* {element.betStatus == 0 && <th scope="row" className="px-6 text-yellow-700 py-2 font-medium  whitespace-nowrap ">
                                      Pending
                                    </th>}
                                    {element.betStatus == 1 && <th scope="row" className="px-6 py-2 font-medium text-green-500 whitespace-nowrap ">
                                      Win
                                    </th>}
                                    {element.betStatus == 2 && <th scope="row" className="px-6 py-2 font-medium text-red-500 whitespace-nowrap ">
                                      Loss
                                    </th>}

                                    <td className="px-6 py-2 text-right">
                                      {element?.betStatus == 1 && <div className=' text-green-500'>{element?.winAmount || "--"}</div>}
                                      {element?.betStatus == 2 && <div className=' text-red-500'>{element?.lossAmount || "--"}</div>}
                                      {element?.betStatus == 0 && <div className=' text-yellow-700'>--</div>}

                                    </td> */}
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <td>No Data Found</td>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {getLedgerTotal && getLedgerTotal > size ? ( // Assuming `size` is the number of items per page
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={Math.ceil(getLedgerTotal / size)} // Calculate total pages
                              marginPagesDisplayed={2} // Show 1 page before and after current page
                              pageRangeDisplayed={1} // Show up to 5 pages in pagination
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-cls"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </nav>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ledger;
