import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { gamesActions, reportActions } from "../../../_actions";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import moment from "moment";

const WinningList = () => {
  let selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const today = new Date().toISOString().split("T")[0];
  let { games } = selector ? selector : {};
  let { allMarket, allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  // const today = new Date().toISOString().split('T')[0];

  const { winList, winTotal, loading } = useSelector((state) => state.reports);
  const { gamesList, gamesTotal } = useSelector((state) => state.games);
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    dispatch(gamesActions.getAllGames(gameReq2));
    dispatch(reportActions.getWinningList(gameReq));
    dispatch(gamesActions.getAllMarket());
    dispatch(gamesActions.getAllUser());
  }, []);

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit2 = () => {
    setFieldsGame({});
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(reportActions.getWinningList(gameReq));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    // if (handleValidationCreateGame()) {
    // }
    console.log(fieldsGame);
    let gameReq2 = {
      fromDate: fieldsGame?.date || "",
      // "toDate": fieldsGame?.todate || "",
      keyWord: fieldsGame?.keyWord || "",
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
    };
    dispatch(reportActions.getWinningList(gameReq2));
  };
  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
    };

    dispatch(reportActions.getWinningList(pageReq));
  };
  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
    setSize(e.target.value);
    let pageReq = {
      // "type": "WITHDRAW",
      keyWord: "",
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: fromDate,
      toDate: toDate,
      size: value,
    };
    dispatch(reportActions.getWinningList(pageReq));
  };
  return (
    <>
      <Loader loading={loading} />

      <div className="p-3 sm:p-5">
        <div className="mx-auto max-w-screen-3xl space-y-3">
          <sectiion className="flex justify-between my-4 ">
            <div className="text-xl font-semibold">
              Pages / <span className="text-[#DB2777]">Winner List</span>
            </div>
          </sectiion>
          <div className=" text-left  rounded-lg bg-white p-3 space-y-4">
            <div className="flex justify-between md:flex-nowrap gap-1 flex-wrap items-end  w-full ">
              <div className="mt-1 shadow-sm w-full">
                <label
                  class="block mb-2 text-sm font-medium text-gray-900 "
                  for="gamename"
                >
                  Date :
                </label>
                <input
                  className="w-full px-3 py-1.5 text-sm bg-white border uppercase border-gray-400 rounded focus:outline-none"
                  id="date"
                  name="date"
                  placeholder="Enter date"
                  value={
                    fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""
                  }
                  max={today}
                  type="date"
                  onChange={inputChangeCreate}
                />
                {errorsGame && errorsGame["date"] ? (
                  <div className="text-red-600 invalid-feedback">
                    {errorsGame["date"]}
                  </div>
                ) : null}
              </div>

              <div className="mt-1 shadow-sm w-full">
                <label
                  class="block mb-2 text-sm font-medium text-gray-900 "
                  for="name"
                >
                  Game Name :
                </label>
                <select
                  className="w-full px-3 py-2 capitalize text-sm bg-white border border-gray-400 rounded focus:outline-none"
                  id="userId"
                  name="userId"
                  placeholder="name"
                  value={
                    fieldsGame && fieldsGame["userId"]
                      ? fieldsGame["userId"]
                      : ""
                  }
                  type="text"
                  onChange={inputChangeCreate}
                >
                  <option value="">Select Game Name</option>
                  {gamesList && gamesList.length > 0
                    ? gamesList.map((element, index) => (
                        <option
                          value={element && element._id ? element._id : ""}
                        >
                          {element && element.name ? element.name : ""}
                        </option>
                      ))
                    : null}
                </select>
                {errorsGame && errorsGame["userId"] ? (
                  <div className="text-red-600 invalid-feedback">
                    {errorsGame["userId"]}
                  </div>
                ) : null}
              </div>

              <div className="w-full flex flex-col lg:flex-row mt-2 md:mt-0 gap-3 justify-start items-end">
                <button
                  className="flex-1 w-auto justify-center px-4 py-1.5 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-black border  rounded focus:outline-none"
                  type="button"
                  onClick={createGameSubmit}
                >
                  Submit
                </button>
                <button
                  className="flex-1 w-auto justify-center px-4 py-1.5 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#DB2777]  rounded focus:outline-none"
                  type="button"
                  onClick={createGameSubmit2}
                >
                  Reset
                </button>
              </div>
            </div>

            <div className="relative overflow-x-auto p-1 lg:p-3 border rounded-xl">
              <div className="flex justify-end place-items-center gap-3">
                <select
                  className={`w-20 px-3 py-2 text-sm bg-white border border-gray-400 rounded focus:outline-none`}
                  id="size"
                  name="size"
                  placeholder="name"
                  value={
                    fieldsGame && fieldsGame["size"] ? fieldsGame["size"] : ""
                  }
                  type="text"
                  onChange={handleInputSize}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
              <div className="flex justify-between items-center  py-2">
                <table className="w-full text-sm text-left rtl:text-right text-black ">
                  <thead className="text-gray-900 capitalize bg-gray-50  ">
                    <tr>
                      <th className="px-6 py-3">#</th>
                      <th className="px-6 py-3">user name</th>
                      <th className="px-6 py-3">amount</th>
                      <th className="px-6 py-3">Bet Number</th>
                      <th className="px-6 py-3">Game Name</th>
                      <th className="px-6 py-3">Market Name</th>
                      <th className="px-6 py-3">Market Type</th>
                      <th className="px-6 py-3">Date</th>
                      <th className="px-6 py-3">Bet Status</th>
                      <th className="px-6 py-3 ">Profit/Loss</th>
                    </tr>
                  </thead>
                  <tbody>
                    {winList && winList.length > 0 ? (
                      winList.map((element, index) => (
                        <React.Fragment key={index}>
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-80 capitalize"
                          >
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-black whitespace-nowrap "
                            >
                              {offset + index + 1}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-3 text-[#DB2777] font-semibold whitespace-nowrap "
                            >
                              <Link to={`/app/user/${element.userId?._id}`}>
                                {element &&
                                element.userId &&
                                element.userId.userName
                                  ? element.userId.userName
                                  : "-"}
                              </Link>
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-black whitespace-nowrap "
                            >
                              {element && element.amount ? element.amount : "-"}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-black whitespace-nowrap "
                            >
                              {element && element.betNumber
                                ? element.betNumber
                                : "-"}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-black whitespace-nowrap "
                            >
                              {element && element.gameId && element.gameId.name
                                ? element.gameId.name
                                : "-"}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-black whitespace-nowrap "
                            >
                              {element &&
                              element.marketId &&
                              element.marketId.name
                                ? element.marketId.name
                                : "-"}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-black whitespace-nowrap "
                            >
                              {element && element.isOpen && element.isOpen
                                ? "Open"
                                : "Close"}
                            </th>
                            <th
                              scope="row"
                              className="px-6 py-3 font-medium text-black whitespace-nowrap  uppercase"
                            >
                              {element && element.createdAt
                                ? moment(new Date(parseInt(element.createdAt)))
                                    .utcOffset("+05:30")
                                    .format("DD-MM-YYYY hh:mm a")
                                : "-"}
                            </th>
                            {element.betStatus == 0 && (
                              <th
                                scope="row"
                                className="px-6 text-yellow-700 py-3 font-medium  whitespace-nowrap "
                              >
                                Pending
                              </th>
                            )}
                            {element.betStatus == 1 && (
                              <th
                                scope="row"
                                className="px-6 py-3 font-medium text-green-500 whitespace-nowrap "
                              >
                                Win
                              </th>
                            )}
                            {element.betStatus == 2 && (
                              <th
                                scope="row"
                                className="px-6 py-3 font-medium text-red-500 whitespace-nowrap "
                              >
                                Loss
                              </th>
                            )}

                            <td className="px-6 py-3 ">
                              {element?.betStatus == 1 && (
                                <div className=" text-green-500">
                                  {element?.winAmount || "--"}
                                </div>
                              )}
                              {element?.betStatus == 2 && (
                                <div className=" text-red-500'">
                                  {element?.lossAmount || "--"}
                                </div>
                              )}
                              {element?.betStatus == 0 && (
                                <div className=" text-yellow-700">--</div>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <nav className="relative z-0 flex justify-end mt-5 w-76">
                {winTotal && winTotal > 10 ? (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={winTotal / size}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-cls"}
                    activeClassName={"active"}
                  />
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinningList;
