import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bankActions } from '../../_actions'
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../components/Loader/Loader';

function BlockUpi() {
    const selector = useSelector(state => state)
    let { bank } = selector ? selector : {};
    let { blockUpiList } = bank ? bank : {};
    let { data } = blockUpiList ? blockUpiList : {}
    const [upiId, setUpiId] = useState('');
    const [error, setErrors] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(bankActions.getAllUPiBlock())
    }, [])
    const handleDeleteUpi = (upi) => {
        let reqData = {
            id: upi._id
        }

        confirmAlert({

            title: 'Confirm to delte?',
            message: `Are you sure to delete upi ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dispatch(bankActions.deleteUPiBlock(reqData))
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const handleSubmitUpi = () => {
        if (!upiId) {
            setErrors('UPI ID is required.');
            return;
        }
        let reqData = {
            UPIID: upiId
        }
        dispatch(bankActions.createUPiBlock(reqData))
        setUpiId('')
        setErrors('');
    }
    return (
        <div className="flex justify-center items-center bg-gray-100 h-screen relative">
            <Loader loading={selector?.bank?.loading} />
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-lg ">
                <div className="flex items-center space-x-2 mb-4">
                    <input
                        type="text"
                        placeholder="Enter UPI..."
                        className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:border-blue-500"
                        value={upiId}
                        onChange={(e) => {
                            setUpiId(e.target.value);
                            if (error) setErrors('');
                        }}
                    />
                    {error && (
                        <div className="text-red-500 text-sm mt-1">
                            {error}
                        </div>
                    )}
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" onClick={handleSubmitUpi}>
                        Save
                    </button>
                </div>
                <div className='h-[50vh] overflow-y-auto'>

                    {data && data.length > 0 ? (
                        <table className="w-full border border-gray-300 rounded-md mt-4 ">
                            <thead>
                                <tr className="bg-gray-200 text-gray-700">
                                    <th className="p-2 border-b">UPI ID</th>
                                    <th className="p-2 border-b">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((ele, index) => (
                                    <tr key={index} className="text-gray-600 text-center">
                                        <td className="p-2 border-b capitalize">{ele?.UPIID || ''}</td>
                                        <td className="p-2 border-b">
                                            <button className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600" onClick={() => handleDeleteUpi(ele)}>
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-gray-500 text-center mt-4">No Data Found</div>
                    )}
                </div>
            </div>

            {/* Scroll to Top Button */}

        </div>

    )
}

export default BlockUpi