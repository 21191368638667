import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, marketActions } from '../../_actions'
import Loader from '../../components/Loader/Loader'


const GrandTotalWin = () => {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    let { games } = selector ? selector : {};
    let { getMarketWiseResultByGameId } = games ? games : {};
    let { data } = getMarketWiseResultByGameId ? getMarketWiseResultByGameId : {}
    const { gamesList } = useSelector(state => state.games);
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const [date, setDate] = useState(formattedDate)
    const [gameId, setGameId] = useState('')
    const [type, setType] = useState('')
    const [marketId, setMarketId] = useState('');
    const [error, setError] = useState({})



    useEffect(() => {
        let reqData = {
            // "betStatus": 0,
            "filterDate": date,
            "isJackpot": false,
            "isStarLine": false,
            "gameId": "",
            "isOpen": null,
            parentMarketIds: ""
        }
        let gameReq2 = {
            "fromDate": '',
            "toDate": '',
            "keyWord": '',
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 500
        }
        let marketData = {
            "fromDate": "",
            "toDate": "",
            "keyWord": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 1000,
            "isJackpot": false,
            "isStarLine": false
        }
        dispatch(gamesActions.getAllGames(gameReq2));
        dispatch(gamesActions.getMarketWiseResultbyGameId(reqData));
        dispatch(marketActions.getMarketList(marketData))

        setDate(formattedDate)
    }, [])

    const validateFilter = () => {
        let newError = {};
        if (!type) newError.type = 'Type is required';
        setError(newError);
        // Return true if no errors exist, else false
        return Object.keys(newError).length === 0;
    };

    const handleGameId = (e) => {
        let data = e.target.value
        setGameId(data)
    }

    const handleType = (e) => {
        setType(e.target.value)
        if (error.type) {
            setError((prev) => ({ ...prev, type: '' }));
        }
    }

    const handleDateOnChnage = (e) => {
        let newDate = e.target.value
        setDate(newDate)
    }
    const handleMarketId = (e) => {
        setMarketId(e.target.value)
    }
    const handleSubmit = () => {

        if (!validateFilter()) {
            return
        }
        let filterData = {
            "filterDate": date,
            "isJackpot": false,
            "isStarLine": false,
            "gameId": gameId,
            "isOpen": type === 'true' ? true : false,
            "parentMarketIds": marketId


        }
        console.log('filterdata---->>>', filterData)
        dispatch(gamesActions.getMarketWiseResultbyGameId(filterData));

    }
    const handleClear = () => {
        let reqData = {
            "betStatus": 0,
            "filterDate": date,
            "gameId": '',
            "isJackpot": false,
            "isOpen": null,
            "isStarLine": false
        }
        dispatch(gamesActions.getMarketWiseResultbyGameId(reqData));
        setGameId('')
        setMarketId('')
        setType('')
        setDate(formattedDate)
        setError({})
    }
    return (
        <div className='p-3 space-y-2  h-screen overflow-hidden overflow-y-auto '>
            <Loader loading={selector?.games?.loading} />
            <div className='grid grid-cols-1 md:grid-cols-6 items-end  gap-2 bg-white p-2'>
                <div className="flex-1">
                    <label className="mb-2 block text-sm font-medium text-gray-700">Select Date</label>
                    <input
                        type="date"
                        value={date}
                        onChange={handleDateOnChnage}
                        className="w-full px-4 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>
                <div className="flex-1">
                    <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                    <select className="w-full px-3 py-2 capitalize text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                        id="userId" name="userId" placeholder="name"
                        value={gameId}
                        type="text" onChange={handleGameId} >
                        <option value="">Select Game Name</option>
                        {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (
                            <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                        )) : null}

                    </select>

                </div>
                <div className="flex-1">
                    <label class="block text-gray-700 text-base mb-2" for="name">Market Name :</label>
                    <select className="w-full px-3 py-2 capitalize text-sm bg-white border border-gray-400 rounded-lg focus:outline-none"
                        id="userId" name="userId" placeholder="name"
                        value={marketId}
                        onChange={handleMarketId}
                        type="text"  >
                        <option value="">Select Game Name</option>
                        {selector?.markets?.marketList && selector?.markets?.marketList.length > 0
                            ? selector?.markets?.marketList.filter(item => (item.parentMarketId === true && !item.isJackpot && !item.isJackpot)).map((element, index) => (
                                <option
                                    value={
                                        element && element._id
                                            ? element._id
                                            : ""
                                    }
                                >
                                    {element && element.name
                                        ? element.name
                                        : ""}
                                </option>
                            ))
                            : null}

                    </select>

                </div>
                <div className='flex-1'>
                    <label class="block text-gray-700 text-base mb-2" for="name">Game Type</label>
                    <select onChange={handleType} value={type} className="w-full px-3 py-2 capitalize text-sm bg-white border border-gray-400 rounded-lg focus:outline-none">
                        <option value=''>Slect Type</option>
                        <option value={`true`}>Open</option>
                        <option value={`false`}>close</option>

                    </select>
                    {error.type && <p className="text-red-500 text-sm">{error.type}</p>}
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <button className='bg-black text-white p-2 rounded-md' onClick={handleSubmit}>Submit</button>
                    <button className='bg-green-500 text-white p-2 rounded-md' onClick={handleClear}>Clear</button>

                </div>

            </div>
            <div class="relative overflow-x-auto  sm:rounded-lg bg-white p-2 h-auto overscroll-y-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-md text-gray-900 whitespace-nowrap">
                                Game Type
                            </th>
                            <th scope="col" className="px-6 py-3 text-md text-gray-900 whitespace-nowrap">
                                Bids
                            </th>
                            <th scope="col" className="px-6 py-3 text-md text-gray-900 whitespace-nowrap">
                                Win
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 ? (
                            data?.map((ele, index) => (
                                <tr class="text-black border-b " key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap">{ele?.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {ele?.result?.totalAmount || 0}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {ele?.result?.totalWinAmount || 0}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-6 py-4 text-center text-gray-500"
                                >
                                    No Data Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>

        </div>
    )
}

export default GrandTotalWin