import { ticketConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPublicAPI, logoutFunction, headerForPrivateAPI } from '../_helpers';
export const ticketActions = {
    createTicket,
    getTicketList,
    getAllTicket,
    updateTicket,
    updateTicketStatus,
    deleteTicket,
    getAllPermission
};

function createTicket(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createTicket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                ticket => {
                    dispatch(success(ticket));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(ticket) { return { type: ticketConstants.CREATE_TICKET_REQUEST, ticket } }
    function success(ticket) { return { type: ticketConstants.CREATE_TICKET_SUCCESS, ticket } }
    function failure(error) { return { type: ticketConstants.CREATE_TICKET_FAILURE, error } }
}
function getTicketList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getTicketList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                ticket => {
                    dispatch(success(ticket));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(ticket) { return { type: ticketConstants.GET_TICKET_LIST_REQUEST, ticket } }
    function success(ticket) { return { type: ticketConstants.GET_TICKET_LIST_SUCCESS, ticket } }
    function failure(error) { return { type: ticketConstants.GET_TICKET_LIST_FAILURE, error } }
}

function updateTicket(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateTicket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                ticket => {
                    dispatch(success(ticket));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(ticket) { return { type: ticketConstants.UPDATE_TICKET_REQUEST, ticket } }
    function success(ticket) { return { type: ticketConstants.UPDATE_TICKET_SUCCESS, ticket } }
    function failure(error) { return { type: ticketConstants.UPDATE_TICKET_FAILURE, error } }
}

function updateTicketStatus(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updaeUserStatus'
    }    
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                ticket => {
                    dispatch(success(ticket));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(ticket) { return { type: ticketConstants.UPDATE_TICKET_STATUS_REQUEST, ticket } }
    function success(ticket) { return { type: ticketConstants.UPDATE_TICKET_STATUS_SUCCESS, ticket } }
    function failure(error) { return { type: ticketConstants.UPDATE_TICKET_STATUS_FAILURE, error } }
}

function deleteTicket(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteTicket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                ticket => {
                    dispatch(success(ticket));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(ticket) { return { type: ticketConstants.DELETE_TICKET_REQUEST, ticket } }
    function success(ticket) { return { type: ticketConstants.DELETE_TICKET_SUCCESS, ticket } }
    function failure(error) { return { type: ticketConstants.DELETE_TICKET_FAILURE, error } }
}

function getAllTicket(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllTicket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                ticket => {
                    dispatch(success(ticket));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(ticket) { return { type: ticketConstants.GET_ALL_TICKET_REQUEST, ticket } }
    function success(ticket) { return { type: ticketConstants.GET_ALL_TICKET_SUCCESS, ticket } }
    function failure(error) { return { type: ticketConstants.GET_ALL_TICKET_FAILURE, error } }
}


function getAllPermission(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/Premisions'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                ticket => {
                    dispatch(success(ticket));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(ticket) { return { type: ticketConstants.GET_PERMISSION_LIST_REQUEST, ticket } }
    function success(ticket) { return { type: ticketConstants.GET_PERMISSION_LIST_SUCCESS, ticket } }
    function failure(error) { return { type: ticketConstants.GET_PERMISSION_LIST_FAILURE, error } }
}



