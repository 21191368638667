import {
  walletConstants
} from '../_constants';

export default function WALLET(state = {}, action) {
  switch (action.type) {








    case walletConstants.GET_BET_TOTAL_COUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.GET_BET_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        totalCountBet: action?.wallet,
        // getPointstotal:action?.wallet?.data?.total,
        loading: false,
      };
    case walletConstants.GET_BET_TOTAL_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case walletConstants.GET_TRANSFER_POINTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.GET_TRANSFER_POINTS_SUCCESS:
      return {
        ...state,
        getPointsList: action?.wallet?.data?.list,
        getPointstotal: action?.wallet?.data?.total,
        loading: false,
      };
    case walletConstants.GET_TRANSFER_POINTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case walletConstants.UPDATE_USER_BANK_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.UPDATE_USER_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
      };
    case walletConstants.UPDATE_USER_BANK_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case walletConstants.DEPOSITE_BALANCE_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.DEPOSITE_BALANCE_BY_ADMIN_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
      };
    case walletConstants.DEPOSITE_BALANCE_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case walletConstants.WITHDRAW_BALANCE_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.WITHDRAW_BALANCE_BY_ADMIN_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
      };
    case walletConstants.WITHDRAW_BALANCE_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case walletConstants.CREATE_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.CREATE_WALLET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case walletConstants.CREATE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case walletConstants.GET_APP_REJ__REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.GET_APP_REJ__SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        paymentList: action?.wallet?.data?.list,
        totalPayment: action?.wallet?.data?.total,
        loading: false,
      };
    case walletConstants.GET_APP_REJ__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case walletConstants.GET_PAYMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.GET_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        paymentList: action?.wallet?.data?.list,
        totalPayment: action?.wallet?.data?.total,
        loading: false,
      };
    case walletConstants.GET_PAYMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case walletConstants.GET_ALL_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.GET_ALL_WALLET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        WALLETList: action?.WALLET?.data?.list,
        WALLETTotal: action?.WALLET?.data?.total,
        loading: false,
      };
    case walletConstants.GET_ALL_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case walletConstants.UPDATE_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.UPDATE_WALLET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case walletConstants.UPDATE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case walletConstants.UPDATE_WALLET_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.UPDATE_WALLET_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case walletConstants.UPDATE_WALLET_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case walletConstants.DELETE_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.DELETE_WALLET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case walletConstants.DELETE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case walletConstants.GET_ALL_PAYEMENT_ALL_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case walletConstants.GET_ALL_PAYEMENT_ALL_PAYMENT_SUCCESS:
      return {
        ...state,
        wrongPyamentList: action?.wallet,
        totalWrongPayment: action?.wallet?.data?.total,
        loading: false,
      };
    case walletConstants.GET_ALL_PAYEMENT_ALL_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}