import React from "react";
import Modal from 'react-modal';

export default function CreateAppSettingModal(props) {

  let { createModal, handleCreateHideModal, inputChangeCreate, fieldsAppSetting, errorsAppSetting, createAppSettingSubmit, handleFile } = props;

  return (

    // <Modal
    //   isOpen={createModal}
    // >

      <div className={createModal?`fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster`:'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border  rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between bg-[#992941] mb-3 rounded-lg px-2 py-3 text-white">
              <p className="text-2xl font-bold">Add Bank Details </p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleCreateHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <form autoComplete="off">
              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="name">Account Holder's Name:</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAppSetting && !errorsAppSetting["name"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="name" name="name" placeholder="Account Holder's Name"
                    value={fieldsAppSetting && fieldsAppSetting["name"] ? fieldsAppSetting["name"] : ""}

                    type="text" onChange={inputChangeCreate} />
                  {errorsAppSetting && errorsAppSetting["name"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsAppSetting["name"]}
                    </div>
                    : null}
                </div>
              </div>


              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="appsettingname">Bank Name :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAppSetting && !errorsAppSetting["key"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="key" name="key" placeholder="Bank Name" value={fieldsAppSetting && fieldsAppSetting["key"] ? fieldsAppSetting["key"] : ""} type="text" onChange={inputChangeCreate} />
                  {errorsAppSetting && errorsAppSetting["key"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsAppSetting["key"]}
                    </div>
                    : null}
                </div>
              </div>

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="appsettingname">Account No. :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAppSetting && !errorsAppSetting["acno"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="acno" name="acno" placeholder="Account No" value={fieldsAppSetting && fieldsAppSetting["acno"] ? fieldsAppSetting["acno"] : ""} type="number" onChange={inputChangeCreate} />
                  {errorsAppSetting && errorsAppSetting["acno"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsAppSetting["acno"]}
                    </div>
                    : null}
                </div>
              </div>

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="appsettingname">IFSC :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAppSetting && !errorsAppSetting["value"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="value" name="value" placeholder="IFSC" value={fieldsAppSetting && fieldsAppSetting["value"] ? fieldsAppSetting["value"] : ""} type="text" onChange={inputChangeCreate} />
                  {errorsAppSetting && errorsAppSetting["value"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsAppSetting["value"]}
                    </div>
                    : null}
                </div>
              </div>

             

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="appsettingname">UPI ID :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAppSetting && !errorsAppSetting["upi"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="upi" name="upi" placeholder="UPI ID" value={fieldsAppSetting && fieldsAppSetting["upi"] ? fieldsAppSetting["upi"] : ""} type="text" onChange={inputChangeCreate} />
                  {errorsAppSetting && errorsAppSetting["upi"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsAppSetting["upi"]}
                    </div>
                    : null}
                </div>
              </div>



              <div className="w-64 mx-auto mt-4 sm:w-72">
                <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#992941] border border-[#992941] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#992941] focus:shadow-outline-yellow active:bg-[#992941]" type="button" onClick={createAppSettingSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>


    // </Modal>



  );
}
