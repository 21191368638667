import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './AppSettingManagement.json'
import { bank, bankActions, bankActionsActions } from '../../_actions';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { useParams } from 'react-router';


const Charts = () => {
  const dispatch = useDispatch();
  let selector = useSelector(state => state)
  const [currentPage3, setCurrentPage3] = useState(0);
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  let parmas = useParams()

  console.log(parmas?.gameName)

  useEffect(() => {
    let appsettingReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(bankActions.getAdminBankDetailList(appsettingReq));
    dispatch(bankActions.calander({ gameId: parmas?.gameId }));

  }, []);


  const handleCreateOpenModal = () => {
    setCreateModal(true);
  }

  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  }

  const handleUpdateOpenModal = (data) => {
    setFieldsUpdate(data);
    setUpdateModal(true);
  }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {

    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }
      let Object = {
        "id": fieldsUpdate?._id,
        "accountNo": fieldsUpdate?.accountNo && parseInt(fieldsUpdate?.accountNo),
        "name": fieldsUpdate?.name.trim(),
        "ifsc": fieldsUpdate?.ifsc.trim(),
        "bankName": fieldsUpdate?.bankName.trim(),
        "upiId": fieldsUpdate?.upiId.trim(),
      }
      dispatch(bankActions.updateAdminBankDetail(Object, appsettingPageRefresh))
      handleUpdateHideModal()



    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === "") {
      formIsValid = false;
      errors.name = "Please enter account holder's name";
    }

    if (!fieldsUpdate.bankName || fieldsUpdate.bankName.trim() === "") {
      formIsValid = false;
      errors.bankName = "Please Enter Bank name";
    }

    if (!fieldsUpdate.ifsc || fieldsUpdate.ifsc.trim() === "") {
      formIsValid = false;
      errors.ifsc = "Please Enter IFSC Code";
    }
    if (!fieldsUpdate.accountNo || fieldsUpdate.accountNo === "") {
      formIsValid = false;
      errors.accountNo = "Please Enter Account Number";
    }
    if (!fieldsUpdate.upiId || fieldsUpdate.upiId.trim() === "") {
      formIsValid = false;
      errors.upiId = "Please Enter UPI Id";
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };


  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    let appsettingReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }



    if (handleValidationCreateAppSetting()) {

      let appsettingPageRefresh = {
        "accountNo": fieldsAppSetting && fieldsAppSetting.acno && parseInt(fieldsAppSetting.acno) || 0,
        "name": fieldsAppSetting && fieldsAppSetting.name.trim() || "",
        "ifsc": fieldsAppSetting && fieldsAppSetting.value.trim() || "",
        "bankName": fieldsAppSetting && fieldsAppSetting.key.trim() || "",
        "upiId": fieldsAppSetting && fieldsAppSetting.upi.trim() || "",
      }
      dispatch(bankActions.addAdminBankDetail(appsettingPageRefresh, appsettingReq))
      setFieldsAppSetting({})
      setErrorsAppSetting({})
      handleCreateHideModal()
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key.trim() === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value.trim() === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }
    if (!fieldsAppSetting.acno || fieldsAppSetting.acno === "") {
      formIsValid = false;
      errors.acno = "Please enter Account No.";
    }
    if (!fieldsAppSetting.upi || fieldsAppSetting.upi.trim() === "") {
      formIsValid = false;
      errors.upi = "Please enter UPI Id.";
    }


    setErrorsAppSetting(errors);
    return formIsValid;
  };


  const disableAppSetting = (data) => {
    let appsettingIdReq = {
      "id": data._id,
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    confirmAlert({

      title: 'Confirm to disable Bank?',
      message: `Are you sure you want to disable ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(bankActions.bankstatusUpdate(appsettingIdReq, appsettingPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const deleteAppSetting = (data) => {
    let appsettingIdReq = {
      "id": data._id,
    }

    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure you want  to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(bankActions.deleteAdminBankDetail(appsettingIdReq, appsettingPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const handleFile = (event) => {
    console.log("handleFileevent", event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(bankActions.uploadImage(event.target.files[event.target.files.length - 1]));
      // this.setState({ selectedFile: null });
      setSelectedFile(null)

    }
    else {
      console.log("No File To Upload!")
    }

  }

  const handlePageClick = (data) => {

    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setCurrentPage3(data.selected)
    setPageNo(data.selected + 1);
    let pageReq = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(bankActions.getAdminBankDetailList(pageReq));
  }

  let { bank } = selector ? selector : {};
  let { loading, adminBankDetail, chartdata } = bank ? bank : {};
  let { total, list } = adminBankDetail ? adminBankDetail : {};


  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex gap-3 my-4 ">
                        <div>
                          <p className='text-2xl font-bold capitalize'>{parmas?.gameName}</p>
                        </div>

                      </sectiion>

                      <div>
                        <a className=' flex bg-[#992941] justify-center items-center w-40 mx-auto mb-3 py-2 px-1 text-white font-bold rounded-md' href='#top' id='bottom'> GO TO BOTTOM  </a>
                        <div className='overflow-hidden overflow-x-auto'>
                          <table class="min-w-full text-xs md:text-base  divide-y divide-gray-200 ">
                            <thead>
                              <tr class="bg-gray-50">
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">Date</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">MON</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">TUE</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">WED</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">THU</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">FRI</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">SAT</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">SUN</th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              {chartdata && chartdata.length > 0 && chartdata ? chartdata?.map((week, index) => {
                                const startDate = new Date(week.rWeek);
                                // Calculate end date by adding 6 days to start date
                                const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                // Format dates as YYYY-MM-DD strings
                                const formattedStartDate = startDate.toISOString().split('T')[0];
                                const formattedEndDate = endDate.toISOString().split('T')[0];

                                return (
                                  <tr key={index} class="bg-white border">

                                    <td class="px-1 whitespace-nowrap border">
                                      <div className=' flex flex-col text-xs  md:text-base justify-center items-center'>
                                        <div>
                                          {formattedStartDate}
                                        </div>
                                        to
                                        <div>
                                          {formattedEndDate}
                                        </div>
                                      </div>

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.MON?.openNumber&&week?.MON?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.MON?.openNumber&&week?.MON?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.MON?.openNumber&&week?.MON?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.MON?.jodiNumber&&week?.MON?.jodiNumber.toString()[0] || "*"} {week?.MON?.jodiNumber&&week?.MON?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.MON?.closeNumber&&week?.MON?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.MON?.closeNumber&&week?.MON?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.MON?.closeNumber&&week?.MON?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.TUE?.openNumber&&week?.TUE?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.TUE?.openNumber&&week?.TUE?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.TUE?.openNumber&&week?.TUE?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.TUE?.jodiNumber&&week?.TUE?.jodiNumber.toString()[0] || "*"} {week?.TUE?.jodiNumber&&week?.TUE?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.TUE?.closeNumber&&week?.TUE?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.TUE?.closeNumber&&week?.TUE?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.TUE?.closeNumber&&week?.TUE?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.WED?.openNumber&&week?.WED?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.WED?.openNumber&&week?.WED?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.WED?.openNumber&&week?.WED?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.WED?.jodiNumber&&week?.WED?.jodiNumber.toString()[0] || "*"} {week?.WED?.jodiNumber&&week?.WED?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.WED?.closeNumber&&week?.WED?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.WED?.closeNumber&&week?.WED?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.WED?.closeNumber&&week?.WED?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.THU?.openNumber&&week?.THU?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.THU?.openNumber&&week?.THU?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.THU?.openNumber&&week?.THU?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.THU?.jodiNumber&&week?.THU?.jodiNumber.toString()[0] || "*"} {week?.THU?.jodiNumber&&week?.THU?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.THU?.closeNumber&&week?.THU?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.THU?.closeNumber&&week?.THU?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.THU?.closeNumber&&week?.THU?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.FRI?.openNumber&&week?.FRI?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.FRI?.openNumber&&week?.FRI?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.FRI?.openNumber&&week?.FRI?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.FRI?.jodiNumber&&week?.FRI?.jodiNumber.toString()[0] || "*"} {week?.FRI?.jodiNumber&&week?.FRI?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.FRI?.closeNumber&&week?.FRI?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.FRI?.closeNumber&&week?.FRI?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.FRI?.closeNumber&&week?.FRI?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.SAT?.openNumber&&week?.SAT?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SAT?.openNumber&&week?.SAT?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SAT?.openNumber&&week?.SAT?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.SAT?.jodiNumber&&week?.SAT?.jodiNumber.toString()[0] || "*"} {week?.SAT?.jodiNumber&&week?.SAT?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.SAT?.closeNumber&&week?.SAT?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SAT?.closeNumber&&week?.SAT?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SAT?.closeNumber&&week?.SAT?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.SUN?.openNumber&&week?.SUN?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SUN?.openNumber&&week?.SUN?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SUN?.openNumber&&week?.SUN?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.SUN?.jodiNumber&&week?.SUN?.jodiNumber.toString()[0] || "*"} {week?.SUN?.jodiNumber&&week?.SUN?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.SUN?.closeNumber&&week?.SUN?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SUN?.closeNumber&&week?.SUN?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SUN?.closeNumber&&week?.SUN?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>


                                  </tr>
                                )

                              }):<td> No Data Found</td>}
                            </tbody>
                          </table>
                        </div>
                  
                        <a  className=' flex bg-[#992941] justify-center items-center w-40 mx-auto mt-3 py-2 px-1 text-white font-bold rounded-md' href='#bottom' id='top'> 
                        GO TO Top
                         </a>
                      </div>



                    </div>
                  </div>

                  {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage3}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage3}
                            />
                            : null}
                      </nav>
                  }


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>





      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        // inputChange={inputChange}
        rowData={rowData}
      // errorsUpdate={errorsUpdate}
      // updateAppSettingSubmit={updateAppSettingSubmit}
      />


    </>
  );
};

export default Charts;
