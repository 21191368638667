import { walletConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPublicAPI, logoutFunction, headerForPrivateAPI } from '../_helpers';
import { userActions } from './user.actions';
import { reportActions } from './report.actions';
export const walletActions = {
    createGames,
    getPaymentListForAdmin,
    updateUserBankDetailByAdmin,
    getAllGames,
    updateGames,
    depositeBalanceByAdmin,
    withdrawBalanceByAdmin,
    updateGamesStatus,
    deleteGames,
    approvePaymentByAdmin,
    getApproveRejectPaymentListForAdmin,
    getTransferPointListForAdmin,

    getBetsTotalCount,
    creditReferenceAdd,
    getPaymentAllPayment,
    creditReferenceAdd2,
    depositeBalanceByAdmin2,
    withdrawBalanceByAdmin2
};


function creditReferenceAdd(data,myBetReq) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/creditReferenceAdd'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(alertActions.success("Updated"));
                    dispatch(reportActions.getLedger());
                    dispatch(userActions.getAdminProfile());
                    dispatch(userActions.getUserList(myBetReq));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.CREDIT_REFEFENCE_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.CREDIT_REFEFENCE_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.CREDIT_REFEFENCE_FAILURE, error } }
}


function creditReferenceAdd2(data,myBetReq) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/creditReferenceAdd'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
           
                    dispatch(userActions.getUserList(myBetReq));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.CREDIT_REFEFENCE_REQUEST2, wallet } }
    function success(wallet) { return { type: walletConstants.CREDIT_REFEFENCE_SUCCESS2, wallet } }
    function failure(error) { return { type: walletConstants.CREDIT_REFEFENCE_FAILURE2, error } }
}


function getBetsTotalCount(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getBetsTotalCount'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.GET_BET_TOTAL_COUNT_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.GET_BET_TOTAL_COUNT_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.GET_BET_TOTAL_COUNT_FAILURE, error } }
}




function getTransferPointListForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getTransferPointListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.GET_TRANSFER_POINTS_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.GET_TRANSFER_POINTS_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.GET_TRANSFER_POINTS_FAILURE, error } }
}
function createGames(data, setCreateModal, gamePageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createGames'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(getPaymentListForAdmin(gamePageRefresh));
                    setCreateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.CREATE_GAMES_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.CREATE_GAMES_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.CREATE_GAMES_FAILURE, error } }
}


function getApproveRejectPaymentListForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getApproveRejectPaymentListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.GET_APP_REJ__REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.GET_APP_REJ__SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.GET_APP_REJ__FAILURE, error } }
}
function getPaymentListForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getPaymentListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.GET_PAYMENT_LIST_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.GET_PAYMENT_LIST_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.GET_PAYMENT_LIST_FAILURE, error } }
}
function updateUserBankDetailByAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/saveClientBankDetail'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(alertActions.success("Updated"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.UPDATE_USER_BANK_DETAILS_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.UPDATE_USER_BANK_DETAILS_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.UPDATE_USER_BANK_DETAILS_FAILURE, error } }
}

function updateGames(data, setUpdateModal, gamePageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateGames'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(getAllGames(gamePageRefresh));
                    setUpdateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.UPDATE_GAMES_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.UPDATE_GAMES_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.UPDATE_GAMES_FAILURE, error } }
}

function depositeBalanceByAdmin(data, userId) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/depositeBalanceByAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(alertActions.success("Fund Added"));
                    dispatch(userActions.getAdminProfile(userId));
                    dispatch(reportActions.getLedger());
                    dispatch(userActions.getProfileforAdmin(userId))
      
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.DEPOSITE_BALANCE_BY_ADMIN_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.DEPOSITE_BALANCE_BY_ADMIN_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.DEPOSITE_BALANCE_BY_ADMIN_FAILURE, error } }
}


function depositeBalanceByAdmin2(data, userId) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/depositeBalanceByAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(userActions.getUserList(userId))
      
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.DEPOSITE_BALANCE_BY_ADMIN_REQUEST2, wallet } }
    function success(wallet) { return { type: walletConstants.DEPOSITE_BALANCE_BY_ADMIN_SUCCESS2, wallet } }
    function failure(error) { return { type: walletConstants.DEPOSITE_BALANCE_BY_ADMIN_FAILURE2, error } }
}


function withdrawBalanceByAdmin(data, userId) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/withdrawBalanceByAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(alertActions.success("Withdrawal successful"));
                    dispatch(userActions.getProfileforAdmin({ "userId": userId }));
                    dispatch(reportActions.getLedger());
                    dispatch(userActions.getAdminProfile());
                    dispatch(userActions.getUserList())
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.WITHDRAW_BALANCE_BY_ADMIN_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.WITHDRAW_BALANCE_BY_ADMIN_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.WITHDRAW_BALANCE_BY_ADMIN_FAILURE, error } }
}


function withdrawBalanceByAdmin2(data, userId) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/withdrawBalanceByAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(userActions.getUserList(userId))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.WITHDRAW_BALANCE_BY_ADMIN_REQUEST2, wallet } }
    function success(wallet) { return { type: walletConstants.WITHDRAW_BALANCE_BY_ADMIN_SUCCESS2, wallet } }
    function failure(error) { return { type: walletConstants.WITHDRAW_BALANCE_BY_ADMIN_FAILURE2, error } }
}

function updateGamesStatus(data, gamePageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateGamesStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(getAllGames(gamePageRefresh));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.UPDATE_GAMES_STATUS_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.UPDATE_GAMES_STATUS_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.UPDATE_GAMES_STATUS_FAILURE, error } }
}

function approvePaymentByAdmin(data, gamePageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/approvePaymentByAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(getPaymentListForAdmin(gamePageRefresh));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.DELETE_GAMES_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.DELETE_GAMES_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.DELETE_GAMES_FAILURE, error } }
}
function deleteGames(data, gamePageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteGames'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                    dispatch(getAllGames(gamePageRefresh));
                    // dispatch(getPaymentListForAdmin(gamePageRefresh));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.DELETE_GAMES_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.DELETE_GAMES_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.DELETE_GAMES_FAILURE, error } }
}

function getAllGames(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllGames'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.GET_ALL_GAMES_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.GET_ALL_GAMES_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.GET_ALL_GAMES_FAILURE, error } }
}



function getPaymentAllPayment(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getPaymentAllPayment'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                wallet => {
                    dispatch(success(wallet));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(wallet) { return { type: walletConstants.GET_ALL_PAYEMENT_ALL_PAYMENT_REQUEST, wallet } }
    function success(wallet) { return { type: walletConstants.GET_ALL_PAYEMENT_ALL_PAYMENT_SUCCESS, wallet } }
    function failure(error) { return { type: walletConstants.GET_ALL_PAYEMENT_ALL_PAYMENT_FAILURE, error } }
}


