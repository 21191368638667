

import React from "react";
export default function Loader(props) {
  let { loading } = props;

  return (
    <>  
    {loading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900/70 backdrop-blur-sm z-50">
         
          <div className="relative w-[800px] h-[25px] flex justify-center items-center">
            {[..."eikooB"].map((char, index) => (
              <div
                key={index}
                className={`absolute text-white font-mono text-[25px] capitalize opacity-0 animate-letter`}
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {char}
              </div>
            ))}
          </div>

          <style jsx>{`
     
            @keyframes moveLetters {
              0% {
                left: 0%;
                transform: rotateZ(180deg);
                opacity: 0;
              }
              25% {
                left: 40%;
                transform: rotateZ(0deg);
                opacity: 1;
              }
              75% {
                left: 60%;
                transform: rotateZ(0deg);
                opacity: 1;
              }
              100% {
                left: 100%;
                transform: rotateZ(-180deg);
                opacity: 0;
              }
            }

            .animate-letter {
              animation: moveLetters 2s linear infinite;
            }
          `}</style>
        </div>
      ) : null}
    </>
  );
}
