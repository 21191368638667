import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
// import { FaTag } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { PiHandDeposit, PiHandWithdraw } from "react-icons/pi";

import { gamesActions, marketActions, walletActions, userActions, appSettingActions, } from "../../_actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "./ViewGameModal";
// import profileimg from "./profile.png";
import ChangePasswordModel from "./ChangePasswordModel";
import moment from "moment";
import Card from "./Card/Card";
import { IoWalletOutline } from "react-icons/io5";
import CardsDashboard from "./Card/CardsDashboard";
import { HiUsers } from "react-icons/hi";

const Dashboard = () => {
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  // const { gamesList, gamesTotal } = useSelector((state) => state.games);
  // const today = new Date().toISOString().split("T")[0];
  // const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const { adminProfileDetails } = useSelector((state) => state.users);
  let [rowData, setRowData] = useState(null);
  let [viewModal, setViewModal] = useState(false);
  // const [fieldsGame, setFieldsGame] = useState({});
  // const [errorsGame, setErrorsGame] = useState({});
  const [size, setSize] = useState(10);
  // const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  // const [currentPage, setCurrentPage] = useState(0);
  const [gameIds, setgameIds] = useState("");
  const [marketIds, setmarketIds] = useState("");
  const [openmodel, setopenmodel] = useState(false);
  let sessiondata = JSON.parse(window.sessionStorage.getItem("adminuser"));
  let date = new Date();
  let formatedDate = moment(date).format("YYYY-MM-DD");
  const [fromDates, setfromDates] = useState(formatedDate);

  useEffect(() => {
    const fetchData = async () => {
      let gameReq = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: 1000,
      };

      let dateData = {
        filterDate: formatedDate,
      };

      dispatch(gamesActions.getAllGames(gameReq));
      dispatch(userActions.getAdminProfile());

      dispatch(userActions.getPlayCount(dateData));
      dispatch(userActions.getWinsGameWise(dateData));
      dispatch(userActions.getLossAmountGameWise(dateData));
      dispatch(userActions.getFundCounts(dateData));

      let paymentListReq = {
        type: "DEPOSIT",
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      let marketData = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: 1,
        size: 1000,
        isJackpot: false,
        isStarLine: false,
      };

      dispatch(walletActions.getPaymentListForAdmin(paymentListReq));
      dispatch(userActions.getTotalCount());
      dispatch(gamesActions.getAllMarket());
      dispatch(marketActions.getMarketList(marketData));
      let obj = {
        gameId: gameIds,
        marketId: marketIds,
        fromDate: fromDates,
      };

      dispatch(walletActions.getBetsTotalCount(obj));

      let appsettingReq = {
        keyWord: "",
        pageNo: pageNo,
        size: size,
      };
      dispatch(appSettingActions.getAppSettingList(appsettingReq));
    };

    // if(sessiondata){

    fetchData();
    // }
  }, []);

  // const createGameSubmit = (e) => {
  //   e.preventDefault();

  //   let obj = {
  //     gameId: gameIds || "",
  //     marketId: marketIds || "",
  //     fromDate: fromDates || "",
  //   };
  //   dispatch(walletActions.getBetsTotalCount(obj));
  // };

  const deleteGame2 = (data, elem) => {
    console.log(data, elem);
    let gameIdReq = {
      paymentId: data._id,
      status: elem,
    };

    let gamePageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      type: "DEPOSIT",
      pageNo: pageNo,
      size: size,
    };

    confirmAlert({
      title: "Confirm to Change Status?",
      message: `Are you sure you want to change Status of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
    setViewModal(false);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
    setRowData({});
  };

  let { users } = selector ? selector : {};
  let { getPlayCount, getWinGameWise, getLossAmountGameWise, getFundCount } =
    users ? users : {};
  let { counts } = getPlayCount ? getPlayCount : {};
  // let { games, markets } = selector ? selector : {};
  // let { appSettingList } = appSettings ? appSettings : {};
  // let { allMarket, allUserList } = games ? games : {};

  // let { totalCountBet } = wallet ? wallet : {};
  let { totalCounts } = users ? users : {};
  let { loading } = users ? users : {};

  // const totalPlayCount =
  //   counts && counts.length > 0
  //     ? counts?.reduce((sum, obj) => sum + obj.totalAmount, 0)
  //     : "0";

  // let totalWinData =
  //   getWinGameWise && getWinGameWise.length > 0
  //     ? getWinGameWise.map((e) => e.totalAmount)
  //     : null;
  // let profitLossData =
  //   getLossAmountGameWise && getLossAmountGameWise.length > 0
  //     ? getLossAmountGameWise.map((e) => e.totalLossAmount)
  //     : null;
  // console.log(
  //   "getLossAmountGameWisegetLossAmountGameWise",
  //   getLossAmountGameWise
  // );
  return (
    <>
      <Loader loading={loading} />

      <div className="z-0 overflow-hidden h-screen overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className=" sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-3   p-5 ">
                      <div className="w-full grid grid-cols-1 lg:grid-cols-1 gap-3">
                        <div className="w-full lg:col-span-2  space-y-3">
                          <div className="grid lg:grid-cols-4 gap-3">
                            <CardsDashboard title={`Total Users`} value={totalCounts?.data?.totalUserCount || 0} icon={FaUser} progressBarColor="bg-red-800" />
                            <CardsDashboard title={`Wallet Amount`} value={totalCounts?.data?.alluserWallet || 0} progressBarColor="bg-green-500" icon={FaUser} />
                            <CardsDashboard title={`Add Request`} value={totalCounts?.data?.totalAddFundCount || 0} progressBarColor="bg-orange-500" icon={FaUser} />
                            <CardsDashboard title={`TWithdraw Request`} value={totalCounts?.data?.totalWithdrawlFundCount || 0} progressBarColor="bg-blue-500" icon={FaUser} />

                            {/* <Link className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5 cursor-default">
                              <div className="w-full flex flex-col justify-center items-start">
                                <span></span>
                                <span className="font-semibold text-gray-600">
                                  {totalCounts?.data?.totalUserCount || 0}
                                </span>
                              </div>
                              <div className="bg-[#DB2777] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <FaUser />
                              </div>
                            </Link> */}

                            {/* <Link className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5">
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>Wallet Amount</span>
                                <span className="font-semibold text-gray-600">
                                  {totalCounts?.data?.alluserWallet || 0}
                                </span>
                              </div>
                              <div className="bg-[#DB2777] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <IoWalletOutline />
                              </div>
                            </Link> */}

                            {/* <Link className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5">
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>Add Request</span>
                                <span className="font-semibold text-gray-600">
                                  {totalCounts?.data?.totalAddFundCount || 0}
                                </span>
                              </div>
                              <div className="bg-[#DB2777] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <PiHandDeposit />
                              </div>
                            </Link> */}

                            {/* <Link className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5">
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>Withdraw Request</span>
                                <span className="font-semibold text-gray-600">
                                  {totalCounts?.data?.totalWithdrawlFundCount ||
                                    0}
                                </span>
                              </div>
                              <div className="bg-[#DB2777] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <PiHandWithdraw />
                              </div>
                            </Link> */}

                            {sessiondata && sessiondata.roleId !== 0 && (
                              <div
                                to="/app/userbidhistory"
                                className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5"
                              >
                                <div className="w-full flex flex-col justify-center items-start">
                                  <span>
                                    Balance :{" "}
                                    {adminProfileDetails &&
                                      adminProfileDetails?.adminData &&
                                      adminProfileDetails?.adminData &&
                                      adminProfileDetails?.balance?.toString()}
                                  </span>
                                </div>
                                <div className="bg-[#992941] w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                  <FaRupeeSign />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-white rounded-xl">
                        {/* <div className="p-5 space-y-2">
                          <div className="flex justify-end place-items-end gap-4">
                            <div className="mt-1 shadow-sm w-auto">
                              <label class="block text-gray-700 " for="name">
                                Date
                              </label>
                              <input
                                className="w-full px-3 py-1.5 text-sm bg-white border border-zinc-500 rounded focus:outline-none"
                                id="fromDate"
                                name="fromDate"
                                value={fromDates}
                                max={today}
                                type="date"
                                onChange={(e) => setfromDates(e.target.value)}
                              />
                            </div>

                            <div className="mt-1 shadow-sm w-auto">
                              <button
                                className="px-8 py-1.5 w-full text-base font-semibold text-white capitalize  bg-black border border-white rounded"
                                type="button"
                                onClick={createGameSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 place-items-center">
                        <Card
                          title="Play"
                          firstName={`Matka Play`}
                          firstValue={counts?.find((e) => e._id === "Matka")?.totalAmount || 0}
                          secondName={`starline play`}
                          secondValue={counts?.find((e) => e._id === "Starline")?.totalAmount || 0}
                          thirdName={`jackpot Play`}
                          thirdValue={counts?.find((e) => e._id === "Jackpot")?.totalAmount || 0}
                          totalName={`Total Play`}
                          containerClassName="bg-[#FF8E36] "
                        />

                        <Card
                          title="win"
                          firstName={`Matka Play`}
                          firstValue={getWinGameWise?.find((e) => e._id === "Matka")?.totalAmount || 0}
                          secondName={`starline play`}
                          secondValue={getWinGameWise?.find((e) => e._id === "Starline")?.totalAmount || 0}
                          thirdName={`jackpot Play`}
                          thirdValue={getWinGameWise?.find((e) => e._id === "Jackpot")?.totalAmount || 0}
                          totalName={`Total Play`}
                          containerClassName="bg-[#42D6E2] "
                        />

                        <Card
                          title="Profit-Loss"
                          firstName={`Matka Play`}
                          firstValue={getLossAmountGameWise?.find((e) => e._id === "Matka")?.totalLossAmount || 0}
                          secondName={`starline play`}
                          secondValue={getLossAmountGameWise?.find((e) => e._id === "Starline")?.totalLossAmount || 0}
                          thirdName={`jackpot Play`}
                          thirdValue={getLossAmountGameWise?.find((e) => e._id === "Jackpot")?.totalLossAmount || 0}
                          totalName={`Total Play`}
                          containerClassName="bg-[#4E54E0] "
                        />

                        <Card
                          title="Total Add Money"
                          firstName={`Request`}
                          firstValue={getFundCount?.sumofAddMoneyRequest}
                          secondName={`Manually`}
                          secondValue={getFundCount?.sumAddmoneyrequestMannual}
                          thirdName={null}
                          thirdValue={null}
                          totalName={`Total`}
                          containerClassName="bg-[#DB2777] "
                        />

                        <Card
                          title="Total Withdraw Money"
                          firstName={`Request`}
                          firstValue={getFundCount?.sumrequestWithdrawlCount}
                          secondName={`Manually`}
                          secondValue={getFundCount?.sumMannualWithdrawlCount}
                          thirdName={null}
                          thirdValue={null}
                          totalName={`Total`}
                          containerClassName="bg-[#FF5669] "
                        />

                        <Card
                          title="Reject Money Request"
                          firstName={`Request`}
                          firstValue={
                            getFundCount?.sumwithdrawlmoneyRequestReject
                          }
                          secondName={`Manually`}
                          secondValue={
                            getFundCount?.sumAddmoneyRequestRejectCount
                          }
                          thirdName={null}
                          thirdValue={null}
                          totalName={`Total`}
                          containerClassName="bg-[#016C7A] "
                        />
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangePasswordModel openmodel={openmodel} setopenmodel={setopenmodel} />
      <ViewGameModal
        rowData={rowData}
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        deleteGame2={deleteGame2}
      />
    </>
  );
};

export default Dashboard;
