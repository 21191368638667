import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoChevronBackCircle } from "react-icons/io5";
import PermissionJson from '../../Permissions/Permissons.json'
import Select from "react-select";
import {
  bankActions,
  ticketActions,
  // gamesActions,
  // marketActions,
  userActions,
  walletActions,
} from "../../_actions";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loader from "../../components/Loader/Loader";
import ViewUserModal from "./components/ViewUserModal/ViewUserModal";
import { CiSaveUp1 } from "react-icons/ci";

const Dashboard = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  let selector = useSelector((state) => state);
  // const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const { userDetailsById } = useSelector((state) => state.users);
  const [rowData, setrowData] = useState({});
  // const [sortBy, setsortBy] = useState("asc");
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [viewModal, setviewModal] = useState(false);
  // const [currentPage2, setCurrentPage2] = useState(0);
  // const [fieldsBank, setFieldsBank] = useState({});
  const [errorsBank, setErrorsBank] = useState({});
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUser, setFieldsUser] = useState({});
  const [errorsUser, setErrorsUser] = useState({});
  const [bankDetailsOfUser, setbankDetailsOfUser] = useState({});
  const [fromDate2, setfromDate2] = useState("");
  const [keyWord2, setkeyWord2] = useState("");
  const [sortBy2, setsortBy2] = useState("asc");
  const [size, setSize] = useState(10);
  const [size2, setSize2] = useState(10);
  // const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [toDate2, settoDate2] = useState("");
  const [offset2, setOffset2] = useState(0);
  const [pageNo2, setPageNo2] = useState(1);
  // const [adminBlockedNumber, setadminBlockedNumber] = useState([]);
  // const [isChecked, setIsChecked] = useState(false);
  let { bank, users } = selector ? selector : {};
  let { bankDetailsByAdmin } = bank ? bank : {};
  let [sessionData, setsessionData] = useState({});
  // let { markets } = selector ? selector : {};
  // let { allMarket } = markets ? markets : {};


  const [selectedOptions, setSelectedOptions] = useState([]);

  const { tickets: { permissionList = {} } } = selector ? selector : {}


  const [selectedOption2, setSelectedOptions2] = useState({});


  console.log('selectedOption2selectedOption2selectedOption2', fieldsUser?.access)

  const handleCheckboxChange = (permission) => {
    setSelectedOptions2((prev) => {
      const updatedOptions = {
        ...prev,
        [permission]: !prev[permission], // Toggle checkbox state
      };

      // Update fieldsUser.access simultaneously
      setFieldsUser((fields) => ({
        ...fields,
        access: updatedOptions,
      }));

      return updatedOptions;
    });
  };


  console.log('selectedOptionsselectedOptionsselectedOptions', selector)


  // Handle change for multi-select
  const handlePermissionOnChange = (selected) => {

    setSelectedOptions(selected);
  };

  // Function to retrieve session data
  const getSessionData = async () => {
    const sessiondata = await JSON.parse(sessionStorage.getItem("adminuser"));
    setsessionData(sessiondata);
    return { sessiondata };
  };

  // console.log(sessionData)

  useEffect(() => {
    getSessionData();

    // let gameReq = {
    //   fromDate: "",
    //   toDate: "",
    //   keyWord: "",
    //   sortBy: "",
    //   sortOrder: "",
    //   pageNo: pageNo,
    //   size: size,
    // };
    // dispatch(gamesActions.getAllGames(gameReq));
    // dispatch(marketActions.getAllMarket());
    dispatch(userActions.getProfileforAdmin({ userId: userId }));
    dispatch(userActions.getAdminProfile());

    dispatch(ticketActions.getAllPermission())

    let paymentListReq = {
      type: "DEPOSIT",
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    let myBetReq2 = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy2,
      pageNo: pageNo2,
      size: size2,
      userId: userId,
    };

    dispatch(walletActions.getPaymentListForAdmin(paymentListReq));

    dispatch(bankActions.getClientBankDetail({ userId: userId }));

    dispatch(userActions.transactionHistory(myBetReq2));
  }, [userId, size2]);

  const { gamesList } = useSelector((state) => state.games);
  useEffect(() => {
    console.log("userDetailsByIduserDetailsById", userDetailsById);

    if (userDetailsById?.access) {
      setFieldsUser(userDetailsById);
      setSelectedOptions2(userDetailsById?.access);
    }

    if (userDetailsById) {
      setFieldsUser(userDetailsById);
    }
    if (bankDetailsByAdmin) {
      setbankDetailsOfUser(bankDetailsByAdmin);
    }

    const blockedGames = selector?.users?.userDetailsById?.blockedGames || [];
    const selectedData = gamesList?.filter((game) =>
      blockedGames.includes(game._id)
    );
    let allMarketData = gamesList;

    // Uncomment and use these if needed:
    // setOptions(allMarketData);
    if (sessionData.roleId !== 0) {
      setoptions(selectedData);
    } else {
      setoptions(allMarketData);
    }
    setMarketSelected(selectedData);
  }, [userDetailsById, bankDetailsByAdmin, gamesList]);

  const inputChangeUser = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUser((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUser((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateUserSubmit = (e) => {
    if (handleValidationUserUpdate()) {
      const { userName, mobNo, email, _id, share, comm } = fieldsUser;
      dispatch(
        userActions.updateUser({
          userName: userName && userName.trim(),
          mobNo: mobNo && mobNo.trim(),
          // email: email && email.trim(),
          share: share,
          comm: comm,
          userId: _id,
        })
      );
    }
  };
  const updateUserSubmitPassword = (e) => {
    // if (handleValidationUserUpdatess()) {
    const { userName, mobNo, password, email, _id, share, comm } = fieldsUser;
    dispatch(
      userActions.changePasswordforAdmin({
        // userName: userName && userName.trim(), mobNo: mobNo && mobNo.trim(),
        password: password && password.trim(),
        // share:share,
        // comm:comm,
        userId: _id,
      })
    );
    // }
  };

  const handleValidationUserUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
      formIsValid = false;
      errors.userName = "Enter userName";
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
      formIsValid = false;
      errors.mobNo = "Enter mobile Number";
    }

    setErrorsUser(errors);
    console.log("errorsBank!!!!!!!!!!!!!", errorsBank);
    return formIsValid;
  };





  const handleSubmitPermisson = () => {
    let apiPaload = {
      "userId": userId,
      // "roleId": "",
      "access": selectedOption2
    }

    dispatch(userActions.givePermissions(apiPaload))
  }









  // const inputChangeBank = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   setbankDetailsOfUser((prevState) => ({ ...prevState, [name]: value }));
  //   setErrorsBank((prevState) => ({ ...prevState, [name]: "" }));
  // };

  // const updateBankDetailsSubmit = (e) => {
  //   if (handleValidationBankUpdate()) {
  //     const { accountNo, name, ifsc, bankName, upiId } = bankDetailsOfUser;

  //     dispatch(
  //       walletActions.updateUserBankDetailByAdmin({
  //         userId: userId,
  //         accountNo: accountNo && accountNo,
  //         name: name && name.trim(),
  //         ifsc: ifsc && ifsc.trim(),
  //         bankName: bankName && bankName.trim(),
  //         upiId: upiId && upiId.trim(),
  //       })
  //     );
  //   }
  // };

  const handleValidationBankUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!bankDetailsOfUser.accountNo || bankDetailsOfUser.accountNo === "") {
      formIsValid = false;
      errors.accountNo = "Enter accountNo";
    }

    if (!bankDetailsOfUser.name || bankDetailsOfUser.name.trim() === "") {
      formIsValid = false;
      errors.name = "Enter name";
    }

    if (!bankDetailsOfUser.ifsc || bankDetailsOfUser.ifsc.trim() === "") {
      formIsValid = false;
      errors.ifsc = "Enter ifsc";
    }

    if (
      !bankDetailsOfUser.bankName ||
      bankDetailsOfUser.bankName.trim() === ""
    ) {
      formIsValid = false;
      errors.bankName = "Enter bankName";
    }

    if (!bankDetailsOfUser.upiId || bankDetailsOfUser.upiId.trim() === "") {
      formIsValid = false;
      errors.upiId = "Enter upiId";
    }

    setErrorsBank(errors);
    console.log("errorsBank!!!!!!!!!!!!!", errorsBank);
    return formIsValid;
  };

  // const inputChangeCreate = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
  //   setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  // };

  const createGameSubmit2 = (e, data) => {
    console.log(e);
    setrowData({ ...e, key: data });
    setviewModal(true);
  };
  const createGameSubmit3 = (e, data) => {
    console.log(e);
    setrowData({ ...e, key: data });
    setviewModal(true);
  };
  const createGameSubmit4 = (e, data) => {
    console.log(e);
    setrowData({ ...e, key: data });
    setviewModal(true);
  };

  const handleChange = (e) => {
    setrowData({ ...rowData, [e.target.name]: e.target.value });
    setErrorsGame({ ...rowData, [e.target.name]: "" });
  };

  const handleFund = (e) => {
    e.preventDefault();

    if (handleValidationCreateGame()) {
      let obj = {
        userId: rowData._id,
        amount: rowData?.amount || 0,
      };

      let idforuserId = {
        userId: userId,
      };

      console.log(rowData?.key === "Withdraw Fund");
      if (rowData?.key === "Credit Reference") {
        dispatch(walletActions.creditReferenceAdd(obj, userId));
      } else if (rowData?.key === "Withdraw Fund") {
        dispatch(walletActions.withdrawBalanceByAdmin(obj, userId));
      } else {
        dispatch(walletActions.depositeBalanceByAdmin(obj, idforuserId));
      }

      setrowData({});
      setviewModal(false);
    }
  };

  const handleViewHideModal = () => {
    setrowData({});
    setviewModal(false);
    setFieldsGame({});
    setErrorsGame({});
    setFieldsUser({});
    setErrorsUser({});
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rowData.amount || rowData.amount === "") {
      formIsValid = false;
      errors.amount = "Enter Amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };
  // const handlePageClick2 = (data) => {
  //   let offset = Math.ceil(data.selected * size);
  //   setCurrentPage2(data.selected);
  //   setOffset2(offset);
  //   setPageNo2(data.selected + 1);
  //   let myBetReq = {
  //     fromDate: fromDate2,
  //     toDate: toDate2,
  //     keyWord: keyWord2,
  //     sortBy: "",
  //     sortOrder: sortBy,
  //     pageNo: data.selected + 1,
  //     size: size,
  //     userId: userId,
  //   };
  //   dispatch(userActions.transactionHistory(myBetReq));
  // };

  useEffect(() => {
    let myBetReq2 = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy2,
      pageNo: pageNo2,
      size: size2,
      userId: userId,
    };
    dispatch(userActions.transactionHistory(myBetReq2));
  }, [keyWord2, size2]);

  // const handleInputSize2 = (e) => {
  //   const newSize = e.target.value;
  //   setPageNo2(1);
  //   setOffset2(0);
  //   setCurrentPage2(0);
  //   setSize2(newSize);
  //   setSize(newSize);
  // };
  // let handleFilter2 = () => {
  //   let myBetReq2 = {
  //     fromDate: fromDate2,
  //     toDate: toDate2,
  //     keyWord: keyWord2,
  //     sortBy: "",
  //     sortOrder: sortBy2,
  //     pageNo: pageNo2,
  //     size: size2,
  //     userId: userId,
  //   };
  //   dispatch(userActions.transactionHistory(myBetReq2));
  // };
  //----------------Multi select--------
  let [options, setoptions] = useState([]);
  // const { gamesList, gamesTotal } = useSelector(state => state.games);
  useEffect(() => {
    // Destructure with optional chaining to avoid undefined errors
    const blockgamIds =
      users?.adminProfileDetails?.adminData?.blockedGames?.flatMap(
        (game) => game?.games
      ) || [];

    const gamesLists =
      gamesList?.filter((e) => !blockgamIds.includes(e._id)) || [];
    const allMarketdata = gamesList || [];

    if (sessionData?.roleId === 0) {
      setoptions(allMarketdata);
    } else {
      setoptions(gamesLists);
    }
  }, [users, gamesList, sessionData]);

  // const onSelect = (selectedList, selectedItem) => {
  //   let data = selectedList.map((e) => ({ name: e.name, id: e._id || e.id }));
  //   setMarketSelected(data);
  //   // setErrorsGame(prevState => ({ ...prevState, selectedmarketData: "" }));
  // };

  // const onRemove = (selectedList, removedItem) => {
  //   let filtervalue =
  //     selectedList && selectedList.filter((item) => item.id !== removedItem.id);
  //   setMarketSelected(filtervalue);
  // };
  // let BlockGame = () => {
  //   let arrayData =
  //     marketSelected &&
  //     marketSelected.length > 0 &&
  //     marketSelected.map((e) => e.id);
  //   dispatch(
  //     userActions.blockedGames({ gameId: arrayData || [], clientId: userId })
  //   );
  // };

  // const [selectMarket, setselectMarket] = useState({});
  // const [selectMarketOption, setselectMarketOption] = useState([]);
  // const [numberSelected, setnumberSelected] = useState([]);

  // let handleSlectMarket = (event) => {
  //   setnumberSelected({});
  //   setselectMarket(event.target.value);
  //   let Options =
  //     allMarket &&
  //     allMarket.length > 0 &&
  //     allMarket.filter((e) => e._id == event.target.value);

  //   let arryDatas = Options && Options[0] && Options[0]?.numList.flat(Infinity);
  //   let Optionss =
  //     arryDatas &&
  //     arryDatas.length > 0 &&
  //     arryDatas.map((e) => ({ name: e, value: e }));
  //   console.log("OptionssOptionss", Optionss);

  //   const output = users?.adminProfileDetails && users?.adminProfileDetails?.adminData && users?.adminProfileDetails?.adminData?.blockedNumbers
  //     .filter((e) => e?.marketId === event.target.value) // Filter based on marketId
  //     .map((e) => ({
  //       numbers: e.numbers.flatMap((parent) => parent.numbers), // Flatten all numbers from parents
  //     }));

  //   // console.log("output",output)

  //   if (output && output.length > 0) {
  //     let data =
  //       Optionss &&
  //       Optionss.length > 0 &&
  //       Optionss.filter((e) => !output[0]?.numbers?.includes(e.value?.toString()));
  //     setselectMarketOption(data);
  //     console.log(data);
  //   } else {
  //     setselectMarketOption(Optionss);
  //   }
  // };
  // const onSelect2 = (selectedList, selectedItem) => {
  //   setnumberSelected(selectedList);
  //   // setErrorsGame(prevState => ({ ...prevState, selectedmarketData: "" }));
  // };
  // const BlockNumber = () => {
  //   // marketId, userId, numbers
  //   console.log("numberSelected", numberSelected);
  //   if (selectMarket && selectMarket.length == 0) {
  //     return;
  //   }
  //   // console.log(selectMarket);z
  //   let Obj = {
  //     marketId: selectMarket,
  //     numbers:
  //       (numberSelected &&
  //         numberSelected.length > 0 &&
  //         numberSelected.map((e) => e.value)) ||
  //       [],
  //     userId: userId,
  //   };
  //   dispatch(userActions.blockeNumbercreate(Obj));
  // };

  //----------------Multi select--------       onSelect={onSelectMarket}
  // onRemove={onRemoveMarket}
  // let {markets} = selector?selector:{};
  // let {allMarket} = markets?markets:{};

  const handledoubleAmount = (e, data) => {
    e.preventDefault();
    dispatch(userActions.doubleAmount({ userId: data?._id }));
  };

  // let { tnxList, tnxTotal } = users ? users : {};
  // console.log("userDetailsById", userDetailsById);

  console.log(userDetailsById);

  return (
    <>
      <Loader loading={selector?.users?.loading} />
      {/* <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
               
                  <div className="p-3 sm:p-5">
                    <div className="">
                      <div className="">
                        <div className="w-full col-span-1 bg-white rounded-xl shadow-lg">
                          <div className="bg-gradient-to-r from-[#DB2777] to-[#992941] rounded-t-xl p-6 flex justify-between items-center">
                            <div className="flex justify-center font-semibold space-x-2 text-white capitalize">
                              {userDetailsById?.userName || ""}
                            </div>
                          </div>
                          <div className="w-full space-y-6 p-4 border-t">
                            <div className="w-full flex flex-col justify-start">
                              <span className="text-black text-lg">
                                Available Balance
                              </span>
                              <span className="text-gray-900 text-2xl font-bold">
                                {userDetailsById?.wallet || 0}
                              </span>
                            </div>
                            <div className="w-full flex flex-col space-y-4">
                              <button
                                className="w-full flex justify-center items-center px-4 py-2 text-lg font-semibold text-white bg-green-600 rounded-full hover:bg-green-700 transition duration-150 ease-in-out"
                                type="button"
                                onClick={() =>
                                  createGameSubmit2(userDetailsById, "Add Fund")
                                }
                              >
                                <span className="mr-2">+</span> Add Fund
                              </button>
                              <button
                                className="w-full flex justify-center items-center px-4 py-2 text-lg font-semibold text-white bg-red-600 rounded-full hover:bg-red-700 transition duration-150 ease-in-out"
                                type="button"
                                onClick={() =>
                                  createGameSubmit3(
                                    userDetailsById,
                                    "Withdraw Fund"
                                  )
                                }
                              >
                                <span className="mr-2">-</span> Withdraw Fund
                              </button>
                              <button
                                className="w-full flex justify-center items-center px-4 py-2 text-lg font-semibold text-white bg-purple-700 rounded-full hover:bg-purple-800 transition duration-150 ease-in-out"
                                type="button"
                                onClick={() =>
                                  createGameSubmit4(
                                    userDetailsById,
                                    "Credit Reference"
                                  )
                                }
                              >
                                Credit Reference
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="w-full p-6 space-y-8 bg-white rounded-2xl shadow-md">
                          <div className="flex justify-between items-center border-b pb-4">
                            <span className="text-xl font-bold text-gray-800">
                              Personal Information
                            </span>
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="flex flex-col">
                              <label
                                className="block text-gray-700 text-sm font-medium"
                                htmlFor="userName"
                              >
                                User Name:
                              </label>
                              <input
                                disabled
                                className="px-4 py-3 mt-2 bg-gray-100 border rounded-lg focus:outline-none"
                                id="userName"
                                name="userName"
                                value={fieldsUser?.userName || ""}
                                type="text"
                                onChange={inputChangeUser}
                              />
                            </div>

                            <div className="flex flex-col">
                              <label
                                className="block text-gray-700 text-sm font-medium"
                                htmlFor="mobNo"
                              >
                                Mobile:
                              </label>
                              <input
                                className="px-4 py-3 mt-2 bg-white border rounded-lg focus:outline-none"
                                id="mobNo"
                                name="mobNo"
                                value={fieldsUser?.mobNo || ""}
                                type="text"
                                onChange={inputChangeUser}
                              />
                            </div>

                            <div className="flex flex-col">
                              <label
                                className="block text-gray-700 text-sm font-medium"
                                htmlFor="createdAt"
                              >
                                Creation Date:
                              </label>
                              <input
                                disabled
                                className="px-4 py-3 mt-2 bg-gray-100 border rounded-lg focus:outline-none"
                                id="createdAt"
                                name="createdAt"
                                value={
                                  fieldsUser?.createdAt
                                    ? new Date(
                                        fieldsUser.createdAt
                                      ).toLocaleDateString()
                                    : ""
                                }
                                type="text"
                              />
                            </div>

                            <div className="flex justify-end items-center">
                              <button
                                className="px-5 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500 focus:outline-none transition"
                                onClick={updateUserSubmit}
                              >
                                Update Info
                              </button>
                            </div>
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="flex flex-col">
                              <label
                                className="block text-gray-700 text-sm font-medium"
                                htmlFor="password"
                              >
                                Change Password:
                              </label>
                              <input
                                className="px-4 py-3 mt-2 bg-white border rounded-lg focus:outline-none"
                                id="password"
                                name="password"
                                type="password"
                                onChange={inputChangeUser}
                              />
                            </div>

                            <div className="flex justify-end items-center">
                              <button
                                className="px-5 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500 focus:outline-none transition"
                                onClick={updateUserSubmitPassword}
                              >
                                Save Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        {userDetailsById?.blockedNumbers?.map(
                          (blockedNumber) => {
                            // Check if there are any blocked numbers in the current market
                            const hasBlockedNumbers =
                              blockedNumber.numbers.some(
                                (numGroup) => numGroup.numbers.length > 0
                              );

                            if (!hasBlockedNumbers) {
                              return null; // Skip rendering if there are no blocked numbers in the current market
                            }

                            return (
                              <div
                                key={blockedNumber._id}
                                className="p-4 border-b border-gray-200"
                              >
                                <h3 className="text-lg font-semibold">
                                  Market: {blockedNumber.marketId?.name}
                                </h3>
                                {blockedNumber.numbers.map((numberGroup) => (
                                  <p
                                    key={numberGroup._id}
                                    className="text-sm mt-2"
                                  >
                                    Blocked Numbers:{" "}
                                    {numberGroup.numbers.join(", ")}
                                  </p>
                                ))}
                              </div>
                            );
                          }
                        )}
                      </div>

                      {sessionData && sessionData.roleId == 4 && (
                        <div>
                          <input
                            type="checkbox"
                            checked={userDetailsById?.DoubleAmount}
                            onChange={(e) =>
                              handledoubleAmount(e, userDetailsById)
                            }
                            id="implementDouble"
                          />
                          <label htmlFor="implementDouble">
                            Implement Double
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="z-0 overflow-hidden h-screen overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="p-4 sm:p-6 flex justify-center items-center ">
            {/* User Card */}
            {/* <div className="w-full bg-white rounded-xl shadow-lg ">
              <div className="bg-gradient-to-r from-pink-500 to-red-600 rounded-t-xl p-6 flex justify-between items-center">
                <h1 className="text-white text-lg font-semibold capitalize">
                  {userDetailsById?.userName || ""}
                </h1>
              </div>
            </div> */}

            {/* Personal Information */}
            <div className=" w-full mt-8 p-6 bg-white rounded-xl shadow-md">
              <div className="border-b pb-4 mb-4">
                <h2 className="text-xl font-bold text-gray-800">
                  Personal Information
                </h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col">
                  <label
                    className="text-sm font-medium text-gray-700"
                    htmlFor="userName"
                  >
                    User Name
                  </label>
                  <input
                    id="userName"
                    name="userName"
                    type="text"
                    disabled
                    className="px-4 py-3 mt-2 bg-gray-100 border rounded-lg focus:outline-none"
                    value={fieldsUser?.userName || ""}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    className="text-sm font-medium text-gray-700"
                    htmlFor="createdAt"
                  >
                    Creation Date
                  </label>
                  <input
                    id="createdAt"
                    name="createdAt"
                    type="text"
                    disabled
                    className="px-4 py-3 mt-2 bg-gray-100 border rounded-lg focus:outline-none"
                    value={
                      fieldsUser?.createdAt
                        ? new Date(fieldsUser.createdAt).toLocaleDateString()
                        : ""
                    }
                  />
                </div>
              </div>

              {/* Change Password */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                <div className="flex flex-col relative">
                  <label
                    className="text-sm font-medium text-gray-700"
                    htmlFor="mobNo"
                  >
                    Change Mobile
                  </label>
                  <input
                    id="mobNo"
                    name="mobNo"
                    type="text"
                    className="px-4 py-3 mt-2 bg-white border rounded-lg focus:outline-none"
                    value={fieldsUser?.mobNo || ""}
                    onChange={inputChangeUser}
                  />
                  <span className="absolute top-10 right-4">
                    <CiSaveUp1
                      onClick={updateUserSubmit}
                      className="text-xl cursor-auto"
                    />
                  </span>
                </div>
                <div className="flex flex-col relative">
                  <label
                    className="text-sm font-medium text-gray-700"
                    htmlFor="password"
                  >
                    Change Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    className="px-4 py-3 mt-2 bg-white border rounded-lg focus:outline-none"
                    onChange={inputChangeUser}
                  />
                  <span className="absolute top-10 right-4">
                    <CiSaveUp1
                      onClick={updateUserSubmitPassword}
                      className="text-xl cursor-auto"
                    />
                  </span>
                </div>
              </div>


              <div className="p-4 space-y-6 grid grid-cols-2">
                <div className="flex flex-col space-y-2">
                  <span className="text-lg text-gray-700">
                    Available Balance
                  </span>
                  <span className="text-2xl font-bold text-gray-900">
                    {userDetailsById?.wallet || 0}
                  </span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  <button
                    className="w-full px-2 py-2  font-semibold text-white bg-green-600 rounded-lg hover:bg-green-700 transition duration-150"
                    onClick={() =>
                      createGameSubmit2(userDetailsById, "Add Fund")
                    }
                  >
                    + Add Fund
                  </button>
                  <button
                    className="w-full px-2 py-2  font-semibold text-white bg-red-600 rounded-lg hover:bg-red-700 transition duration-150"
                    onClick={() =>
                      createGameSubmit3(userDetailsById, "Withdraw Fund")
                    }
                  >
                    - Withdraw Fund
                  </button>
                  {/* <button
                    className="w-full px-22 py-2  font-semibold text-white bg-purple-700 rounded-lg hover:bg-purple-800 transition duration-150"
                    onClick={() =>
                      createGameSubmit4(userDetailsById, "Credit Reference")
                    }
                  >
                    Credit Reference
                  </button> */}
                </div>
              </div>

              <div className=" p-4 border">
                {Object.entries(permissionList).map(([category, permissions]) => (
                  <div key={category} className="mb-4 border-b grid grid-cols-2">
                    <h3 className="font-semibold text-lg mb-2 capitalize">{category.replace('_', ' ')}</h3>
                    <div className="flex justify-start items-center gap-4">
                      {permissions.map((permission) => (
                        <label key={permission} className=" mb-2 flex items-center capitalize w-44">
                          <input
                            type="checkbox"
                            checked={selectedOption2[permission] || false}
                            onChange={() => handleCheckboxChange(permission)}
                            className="mr-2"
                          />
                          {permission.replace('_', ' ')}
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-end">
                <button className="bg-blue-700 p-2 rounded-md  text-white" onClick={handleSubmitPermisson}>Submit</button>
              </div>
            </div>


            {userDetailsById?.blockedNumbers?.length > 0 && (
              <div className="w-full mt-8 p-6 bg-white rounded-xl shadow-md space-y-4">
                <h2 className="text-lg font-semibold">Blocked Numbers</h2>
                {userDetailsById.blockedNumbers.map((blockedNumber) => (
                  <div
                    key={blockedNumber._id}
                    className="border-b border-gray-200 pb-4"
                  >
                    <h3 className="text-gray-800 font-medium">
                      Market: {blockedNumber.marketId?.name}
                    </h3>
                    {blockedNumber.numbers.map((numGroup) => (
                      <p key={numGroup._id} className="text-sm text-gray-600">
                        Blocked Numbers: {numGroup.numbers.join(", ")}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            )}

            {/* Double Amount Toggle */}
            {sessionData && sessionData.roleId === 4 && (
              <div className="flex items-center mt-6">
                <input
                  type="checkbox"
                  id="implementDouble"
                  checked={userDetailsById?.DoubleAmount}
                  onChange={(e) => (e, userDetailsById)}
                  className="mr-2"
                />
                <label htmlFor="implementDouble" className="text-gray-700">
                  Implement Double
                </label>
              </div>
            )}
          </div>

        </div>



      </div>

      <ViewUserModal
        viewModal={viewModal}
        rowData={rowData}
        handleViewHideModal={handleViewHideModal}
        handleChange={handleChange}
        handleFund={handleFund}
        errorsGame={errorsGame}
      />
    </>
  );
};

export default Dashboard;
