import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { IoChevronBackCircle } from "react-icons/io5";
import {
  gamesActions,
  marketActions,
  userActions,
  walletActions,
} from "../../../../_actions";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DashboardJSON from "../../MarketManagement.json";
import Loader from "../../../../components/Loader/Loader";

const EditMarket = () => {
  const dispatch = useDispatch();
  const { marketId } = useParams();
  const navigate = useNavigate();
  let selector = useSelector((state) => state);
  const { marketDataIdWise } = useSelector((state) => state.markets);
  const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const [fieldsUpdateMarket, setFieldsUpdateMarket] = useState({});
  const [errorsUpdateMarket, setErrorsUpdateMarket] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    let gameReq = {
      marketId: marketId,
    };
    dispatch(marketActions.getMarketByMarketIdForAdmin(gameReq));
  }, []);

  useEffect(() => {
    dispatch(userActions.clearImage());
    if (marketDataIdWise) {
      setFieldsUpdateMarket(marketDataIdWise);
    }
  }, [marketDataIdWise]);

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdateMarket((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdateMarket((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateMarketSubmit = (e) => {
    e.preventDefault();
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    console.log(fileData);

    if (handleValidationUpdateMarket()) {
      const { name, point, shortName, _id, min, max } = fieldsUpdateMarket;
      dispatch(
        marketActions.updateMarket({
          name: name && name.trim(),
          image:
            fileData ||
            fieldsUpdateMarket.image ||
            "https://res.cloudinary.com/dnd2isyjo/image/upload/v1712923995/your_folder_name/cmbpkevpflbvf9l0c6v0.png",
          shortName: shortName && shortName.trim(),
          point: point,
          id: _id,
          min,
          max,
        })
      );
    }
  };

  const handleValidationUpdateMarket = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdateMarket.name || fieldsUpdateMarket.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (
      !fieldsUpdateMarket.shortName ||
      fieldsUpdateMarket.shortName.trim() === ""
    ) {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdateMarket.point || fieldsUpdateMarket.point === "") {
      formIsValid = false;
      errors.point = DashboardJSON.ErrorMsg.point;
    }

    setErrorsUpdateMarket(errors);
    return formIsValid;
  };

  const handleFile = async (e) => {
    dispatch(userActions.uploadImage(e));
  };

  let { users } = selector ? selector : {};
  let { fileData, loading } = users ? users : {};

  return (
    <>
      <Loader loading={selector?.markets?.loading} />
      {/* <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">

                <main className="relative flex-1 p-4">
                  <div className='p-1'>
                    <IoChevronBackCircle className='text-3xl cursor-pointer' onClick={() => navigate('/app/market')} />
                  </div>
                  <div className="p-3 2xl:p-10 sm:p-5">

                    <div className="mx-auto max-w-screen-3xl space-y-3">
                      <div className='w-full p-5 space-y-2 bg-white rounded-xl'>
                        <div className='flex justify-between items-center border-b border-gray-400 pb-3'>
                          <span className="text-lg text-left font-semibold text-gray-900">Edit Market</span>
                        </div>
                        <div className="flex space-x-5 border-b border-gray-400">
                          <div className="flex items-center w-full">
                            <label class="block text-gray-700 text-base whitespace-nowrap" for="name">Name :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                              id="name" name="name"
                              value={fieldsUpdateMarket && fieldsUpdateMarket["name"] ? fieldsUpdateMarket["name"] : ""} type="text" onChange={inputChangeCreate} />
                            {errorsUpdateMarket && errorsUpdateMarket["name"] ?
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["name"]}
                              </div>
                              : null}
                          </div>
                        </div>


                        <div className="flex space-x-5 border-b border-gray-400">
                          <div className="flex items-center w-full">
                            <label class="block text-gray-700 text-base whitespace-nowrap" for="name">Min Amount :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                              id="min" name="min"
                              value={fieldsUpdateMarket && fieldsUpdateMarket["min"] ? fieldsUpdateMarket["min"] : ""} type="number" onChange={inputChangeCreate} />
                            {errorsUpdateMarket && errorsUpdateMarket["min"] ?
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["min"]}
                              </div>
                              : null}
                          </div>
                        </div>


                        <div className="flex space-x-5 border-b border-gray-400">
                          <div className="flex items-center w-full">
                            <label class="block text-gray-700 text-base whitespace-nowrap" for="name">Max Amount :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                              id="max" name="max"
                              value={fieldsUpdateMarket && fieldsUpdateMarket["max"] ? fieldsUpdateMarket["max"] : ""} type="number" onChange={inputChangeCreate} />
                            {errorsUpdateMarket && errorsUpdateMarket["max"] ?
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["max"]}
                              </div>
                              : null}
                          </div>
                        </div>

                        <div className="flex space-x-5 border-b border-gray-400">
                          {fieldsUpdateMarket["parentMarketId"] && <div className="flex items-center w-full">
                            <label class="block text-gray-700 text-base whitespace-nowrap" for="name">Point :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                              id="point" name="point"
                              value={fieldsUpdateMarket && fieldsUpdateMarket["point"] ? fieldsUpdateMarket["point"] : ""} type="number" onChange={inputChangeCreate} />
                            {errorsUpdateMarket && errorsUpdateMarket["point"] ?
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["point"]}
                              </div>
                              : null}
                          </div>}

                        </div>
                        <div className="flex space-x-5 border-b border-gray-400">
                          <div className="flex items-center w-full">
                            <label class="block text-gray-700 text-base whitespace-nowrap mr-2" for="name">Image : </label>
                            {fileData ? <img src={fileData}
                              alt=" " height="90px" width="90px" />
                              :
                              <img src={fileData || fieldsUpdateMarket && fieldsUpdateMarket.image ? fieldsUpdateMarket.image : "NA"}
                                alt=" " height="90px" width="90px" />


                            }

                            <input
                              className=' mx-3'
                              // style={{ display: 'none' }}
                              id="image"
                              name="image"
                              type="file"
                              onChange={handleFile}
                            />

                          </div>

                        </div>

                        <div className="flex space-x-5 border-b border-gray-400">
                          <div className="flex w-full">
                            <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                              onClick={(e) => updateMarketSubmit(e)}
                            >Submit</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div >
      </div > */}

      <div class="z-0 overflow-hidden overflow-y-auto focus:outline-none bg-gray-100 min-h-screen">
        <div class="mb-4 md:p-8 p-2">
          <IoChevronBackCircle
            class="text-3xl text-blue-600 cursor-pointer hover:text-blue-800"
            onClick={() => navigate("/app/market")}
          />
        </div>

        <main class="relative sm:p-8 max-w-2xl mx-auto">
          <div class="p-6 bg-white rounded-lg shadow-lg ">
            <div class=" space-y-6">
              <div class="w-full space-y-4">
                <div class="flex justify-between items-center border-b pb-4">
                  <h1 class="text-2xl font-semibold text-gray-900">
                    Edit Market
                  </h1>
                </div>

                {/* <!-- Name Input --> */}
                <div class="flex flex-col space-y-1">
                  <label
                    class="block text-gray-700 text-base font-medium"
                    for="name"
                  >
                    Name
                  </label>
                  <input
                    class="w-full px-4 py-2 text-sm border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    id="name"
                    name="name"
                    value={fieldsUpdateMarket?.name || ""}
                    type="text"
                    onChange={inputChangeCreate}
                  />
                  {errorsUpdateMarket?.name && (
                    <span class="text-red-600 text-sm">
                      {errorsUpdateMarket.name}
                    </span>
                  )}
                </div>

                {/* <!-- Min Amount Input --> */}
                <div class="flex flex-col space-y-1">
                  <label
                    class="block text-gray-700 text-base font-medium"
                    for="min"
                  >
                    Min Amount
                  </label>
                  <input
                    class="w-full px-4 py-2 text-sm border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    id="min"
                    name="min"
                    value={fieldsUpdateMarket?.min || ""}
                    type="number"
                    onChange={inputChangeCreate}
                  />
                  {errorsUpdateMarket?.min && (
                    <span class="text-red-600 text-sm">
                      {errorsUpdateMarket.min}
                    </span>
                  )}
                </div>

                {/* <!-- Max Amount Input --> */}
                <div class="flex flex-col space-y-1">
                  <label
                    class="block text-gray-700 text-base font-medium"
                    for="max"
                  >
                    Max Amount
                  </label>
                  <input
                    class="w-full px-4 py-2 text-sm border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    id="max"
                    name="max"
                    value={fieldsUpdateMarket?.max || ""}
                    type="number"
                    onChange={inputChangeCreate}
                  />
                  {errorsUpdateMarket?.max && (
                    <span class="text-red-600 text-sm">
                      {errorsUpdateMarket.max}
                    </span>
                  )}
                </div>

                {/* <!-- Point Input --> */}
                {fieldsUpdateMarket?.parentMarketId && (
                  <div class="flex flex-col space-y-1">
                    <label
                      class="block text-gray-700 text-base font-medium"
                      for="point"
                    >
                      Point
                    </label>
                    <input
                      class="w-full px-4 py-2 text-sm border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      id="point"
                      name="point"
                      value={fieldsUpdateMarket?.point || ""}
                      type="number"
                      onChange={inputChangeCreate}
                    />
                    {errorsUpdateMarket?.point && (
                      <span class="text-red-600 text-sm">
                        {errorsUpdateMarket.point}
                      </span>
                    )}
                  </div>
                )}

                {/* <!-- Image Upload --> */}
                <div class="flex flex-col space-y-1">
                  <label class="block text-gray-700 text-base font-medium">
                    Image
                  </label>
                  <div class="flex items-center space-x-4">
                    {fileData || fieldsUpdateMarket?.image ? (
                      <img
                        src={fileData || fieldsUpdateMarket.image}
                        alt="Market"
                        class="h-20 w-20 rounded-lg object-cover shadow"
                      />
                    ) : (
                      <div class="h-20 w-20 flex items-center justify-center bg-gray-100 border rounded-lg shadow">
                        N/A
                      </div>
                    )}
                    <input
                      class="text-sm text-gray-700"
                      id="image"
                      name="image"
                      type="file"
                      onChange={handleFile}
                    />
                  </div>
                </div>

                {/* <!-- Submit Button --> */}
                <div class="pt-6">
                  <button
                    class="px-6 py-2 text-white bg-blue-600 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={(e) => updateMarketSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default EditMarket;
