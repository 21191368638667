import React from 'react'

const Card = ({ totalName, firstName, firstValue, secondName, secondValue, thirdName, thirdValue, containerClassName, title }) => {
    const totalValue = Number(firstValue) + Number(secondValue) + Number(thirdValue);
    return (
        <div className={`text-white shadow-lg ${containerClassName} w-full rounded-lg`}>
            <div className={`  p-4 `}>
                <h2 className="text-center text-xl font-bold mb-4">{title}</h2>

                <div className={`flex justify-between font-bold mt-4 `}>
                    <span>{firstName}</span>
                    <span>{firstValue}</span>
                </div> <div className={`flex justify-between font-bold mt-4 `}>
                    <span>{secondName}</span>
                    <span>{secondValue}</span>
                </div> <div className={`flex justify-between font-bold mt-4 `}>
                    <span>{thirdName}</span>
                    <span>{thirdValue}</span>
                </div>

                <div className={`flex justify-between font-bold mt-4 `}>
                    <span>{totalName}</span>
                    <span>{totalValue}</span>
                </div>
            </div>
        </div>
    )
}

export default Card