import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
// import { IoArrowBack } from 'react-icons/io5';
import Loader from '../../components/Loader/Loader';
// import { confirmAlert } from 'react-confirm-alert';

const InactiveUser = () => {
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    let { users } = selector ? selector : {}
    let { inactiveUserList } = users ? users : {}
    let { data } = inactiveUserList ? inactiveUserList : {}
    useEffect(() => {
        dispatch(userActions.findInactiveUsers())
    }, [])

 

    return (
        <div className='p-5'>
            <Loader loading={selector?.users?.loading} />
         
            <div className="relative overflow-x-auto p-5 bg-white rounded-md">
                <table className="w-full text-sm text-left rtl:text-right text-black shadow">
                    <thead className="text-gray-900 capitalize bg-gray-50 shadow">
                        <tr>
                            <th className="px-2 py-3">
                                Username
                            </th>

                            <th className="px-2 py-3">
                                Mobile No
                            </th>
                            <th className="px-2 py-3">
                                Balance
                            </th>
                            <th className="px-2 py-3">
                                Inactive Days
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 ? (
                            data.map((element, index) => (
                                <React.Fragment key={index}>
                                    <tr className="bg-white border-b border-gray-400">

                                        <th
                                            scope="row"
                                            className="px-2 py-4 font-medium text-black whitespace-nowrap capitalize"
                                        >

                                            <div className="flex">
                                                {element?.userName || "-"}
                                            </div>

                                        </th>
                                        <th
                                            scope="row"
                                            className="px-2 py-4 font-medium text-black whitespace-nowrap capitalize"
                                        >

                                            <div className="flex">
                                                {element?.mobNo || "-"}
                                            </div>

                                        </th>
                                        <th
                                            scope="row"
                                            className="px-2 py-4 font-medium text-black whitespace-nowrap capitalize"
                                        >

                                            <div className="flex">
                                                {element?.balance || "-"}
                                            </div>


                                        </th>
                                        <th
                                            scope="row"
                                            className="px-2 py-4 font-medium text-black whitespace-nowrap capitalize"
                                        >

                                            <div className="flex">
                                                {element?.daysInactive || "-"}
                                            </div>


                                        </th>





                                    </tr>
                                </React.Fragment>
                            ))
                        ) : (
                            <td colSpan={12} className="text-center py-4">
                                No Data Found
                            </td>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default InactiveUser