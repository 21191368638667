import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesActions } from "../../_actions";
import Select from "react-select";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "../gameManagement/components/ViewGameModal/ViewGameModal";
import { confirmAlert } from "react-confirm-alert";

const DeclareResultFatafat = () => {
  const dispatch = useDispatch();
  const {games: { loading, gameResultList, gameResultTotal, winnerList, gamesListb = [] } = {},} = useSelector((state) => state);
  let [viewModal, setViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  const today = new Date().toISOString().split("T")[0];

  // const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  // const { allMarket } = useSelector((state) => state.markets);
  // const { gamesListb } = useSelector((state) => state.games);

  const numberList = [
    "000",
    "111",
    "222",
    "333",
    "444",
    "555",
    "666",
    "777",
    "888",
    "999",
    "550",
    "668",
    "244",
    "299",
    "226",
    "488",
    "677",
    "118",
    "334",
    "100",
    "119",
    "155",
    "227",
    "335",
    "344",
    "399",
    "588",
    "669",
    "200",
    "110",
    "228",
    "255",
    "336",
    "499",
    "660",
    "688",
    "778",
    "300",
    "166",
    "229",
    "337",
    "355",
    "445",
    "599",
    "779",
    "788",
    "400",
    "112",
    "220",
    "266",
    "338",
    "446",
    "455",
    "699",
    "770",
    "500",
    "113",
    "122",
    "177",
    "339",
    "366",
    "447",
    "799",
    "889",
    "600",
    "114",
    "277",
    "330",
    "448",
    "466",
    "556",
    "880",
    "899",
    "700",
    "115",
    "133",
    "188",
    "223",
    "377",
    "449",
    "557",
    "566",
    "800",
    "116",
    "224",
    "233",
    "288",
    "440",
    "477",
    "558",
    "990",
    "900",
    "117",
    "144",
    "199",
    "225",
    "388",
    "559",
    "577",
    "667",
    "127",
    "136",
    "145",
    "190",
    "235",
    "280",
    "370",
    "479",
    "460",
    "569",
    "389",
    "578",
    "128",
    "137",
    "146",
    "236",
    "245",
    "290",
    "380",
    "470",
    "489",
    "560",
    "678",
    "579",
    "129",
    "138",
    "147",
    "156",
    "237",
    "246",
    "345",
    "390",
    "480",
    "570",
    "679",
    "589",
    "120",
    "139",
    "148",
    "157",
    "238",
    "247",
    "256",
    "346",
    "490",
    "580",
    "670",
    "689",
    "130",
    "149",
    "158",
    "167",
    "239",
    "248",
    "257",
    "347",
    "356",
    "590",
    "680",
    "789",
    "140",
    "159",
    "168",
    "230",
    "249",
    "258",
    "267",
    "348",
    "357",
    "456",
    "690",
    "780",
    "123",
    "150",
    "169",
    "178",
    "240",
    "259",
    "268",
    "349",
    "358",
    "457",
    "367",
    "790",
    "124",
    "160",
    "179",
    "250",
    "269",
    "278",
    "340",
    "359",
    "368",
    "458",
    "467",
    "890",
    "125",
    "134",
    "170",
    "189",
    "260",
    "279",
    "350",
    "369",
    "378",
    "459",
    "567",
    "468",
    "126",
    "135",
    "180",
    "234",
    "270",
    "289",
    "360",
    "379",
    "450",
    "469",
    "478",
    "568",
  ];

  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: true,
    };

    dispatch(gamesActions.marketNumberInSingle());
    dispatch(gamesActions.getResultListAdmin(listReq));
    dispatch(gamesActions.getAllbrandGame(gameReq));
    dispatch(gamesActions.marketNumberThreeDigit());
  }, [pageNo]);

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      let gamePageRefresh = {
        fromDate: fromDate,
        toDate: toDate,
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
        brandGame: true,
      };

      let obj = {
        gameId: fieldsGame?.gameId,
        betNumber: fieldsGame?.num,
        isOpen: true,
        brandGame: true,
        date: fieldsGame?.date,
      };

      dispatch(
        gamesActions.saveResultAdmin(obj, gamePageRefresh, ShowWinnerList2)
      );
      // setFieldsGame({})
    }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Date is required";
    }

    if (!fieldsGame.gameId || fieldsGame.gameId === "") {
      formIsValid = false;
      errors.gameId = "Game is Required";
    }

    // if (!fieldsGame.isOpen || fieldsGame.isOpen === "") {
    //   formIsValid = false;
    //   errors.isOpen = "Select Sessions";
    // }

    if (!fieldsGame.num || fieldsGame.num === "") {
      formIsValid = false;
      errors.num = "Empty fields";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      brandGame: true,
    };
    dispatch(gamesActions.getAllGames(pageReq));
  };

  const ShowWinnerList = () => {
    let obj = {
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num?.toString() == "000" ? "0" : fieldsGame?.num,
      isOpen: true,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.getWinnerListByBetNumber(obj));
      setViewModal(true);
    }
  };
  const ShowWinnerList2 = () => {
    let obj = {
      isJackPot: false,
      isStarLine: true,
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num,
      isOpen: true,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.resultDeclare(obj));
      // setViewModal(true)
    }
  };

  let handleViewHideModal = () => {
    setViewModal(false);
  };

  const rollback = (e) => {
    let obj = {
      gameId: e?.gameId?._id,
      closeNumber: null,
      jodiNumber: e?.jodiNumber,
      openNumber: e?.openNumber == 0 ? "000" : e?.openNumber,
      date: e && e.rDate && new Date(e.rDate).toISOString().split("T")[0],
    };

    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: true,
    };

    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to Rollback",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(gamesActions.rollbackResultDeclare(obj, listReq)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // let { games } = selector ? selector : {};
  // let { loading, gameResultList, gameResultTotal, winnerList } = games
  //   ? games
  //   : {};
  // let optionsData = gamesListb && gamesListb.length > 0 && gamesListb;

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <div className="text-left bg-white space-y-2 rounded-lg">
                        <div className="px-6 py-4 flex items-center justify-between border-b">
                          <span className=" text-lg font-bold">
                            Save Result (Starline Game)
                          </span>
                        </div>
                        <div className="px-6 py-4 w-full">
                          <div className="flex flex-col lg:flex-row justify-start place-items-end gap-4 ">
                            <div className="w-full mt-1 shadow-sm">
                              <label
                                class="block text-gray-700 text-base mb-2 font-bold"
                                for="gamename"
                              >
                                Result Date
                              </label>
                              <input
                                className={`w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="date"
                                name="date"
                                placeholder="Enter Date"
                                value={
                                  fieldsGame && fieldsGame["date"]
                                    ? fieldsGame["date"]
                                    : ""
                                }
                                max={today}
                                type="date"
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame["date"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["date"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-full mt-1 shadow-sm">
                              <label
                                class="block text-gray-700 text-base mb-2 whitespace-nowrap font-bold"
                                for="name"
                              >
                                Game Name
                              </label>
                              <select
                                className={`w-full capitalize px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="gameId"
                                name="gameId"
                                placeholder="name"
                                value={
                                  fieldsGame && fieldsGame["gameId"]
                                    ? fieldsGame["gameId"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeCreate}
                              >
                                <option value="">Select Game</option>
                                {gamesListb && gamesListb.length > 0
                                  ? gamesListb.map((element, index) => (
                                      <option
                                        value={
                                          element && element._id
                                            ? element._id
                                            : ""
                                        }
                                      >
                                        {element && element.name
                                          ? element.name
                                          : ""}
                                      </option>
                                    ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["gameId"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["gameId"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="mt-1 w-full shadow-sm">
                              <label className="block text-gray-700 text-base font-bold mb-2">
                                Number
                              </label>
                              <Select
                                onChange={(selectedOption) =>
                                  inputChangeCreate({
                                    target: {
                                      name: "num",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                name="num"
                                value={
                                  fieldsGame && fieldsGame["num"]
                                    ? {
                                        value: fieldsGame["num"],
                                        label: fieldsGame["num"],
                                      }
                                    : ""
                                }
                                options={
                                  numberList &&
                                  numberList.length > 0 &&
                                  numberList
                                    .filter((e) => e.toString().length > 2)
                                    .map((option) => ({
                                      value: option?.toString(),
                                      label: option?.toString(),
                                    }))
                                }
                                classNamePrefix="react-select"
                                placeholder="Select Number"
                                className="w-auto"
                              />
                              {errorsGame && errorsGame["num"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                              ) : null}
                            </div>
                            <div className="w-full">
                              <button
                                className="justify-center md:w-44 w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bg-[#7D1970]"
                                type="button"
                                onClick={(e) => createGameSubmit(e)}
                              >
                                Save
                              </button>
                            </div>
                            <div className="w-full">
                              <button
                                className="justify-center md:w-44 w-full px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-yellow-600 border border-yellow-600 rounded xl hover:bg-blue hover:text-white focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600"
                                type="button"
                                onClick={(e) => ShowWinnerList(e)}
                              >
                                Show Winner List
                              </button>
                            </div>
                            {/* <div className=" flex px-3 md:flex-row flex-col gap-3 md:w-fit w-full"> */}
                            {/* <button
                              className=" w-full    px-4 py-2 text-sm text-white transition duration-150 ease-in-out bg-pink-600 border border-pink-600 rounded xl hover:bg-blue hover:text-white focus:outline-none focus:border-pink-600 focus:shadow-outline-pink active:bg-pink-600"
                              type="button"
                              onClick={(e) => ShowWinnerList2(e)}
                            >
                              Declare Result
                            </button> */}
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-black ">
                          <thead className="text-gray-800 capitalize bg-gray-50  ">
                            <tr>
                              {[
                                "#",
                                "Result Date",
                                "Game Name",
                                "Opening Number",
                                "Open Ank",
                                "RollBack",
                              ].map((header) => (
                                <th
                                  key={header}
                                  scope="col"
                                  className="px-2 lg:px-4 py-3 text-left text-[12px] lg:text-[15px]"
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {gameResultList?.length > 0 ? (
                              gameResultList.map((element, index) => (
                                <tr
                                  key={index}
                                  className={
                                    index % 2 === 0
                                      ? "bg-white border-b"
                                      : "bg-gray-100"
                                  }
                                >
                                  <td className="px-2 lg:px-4 py-3 font-medium text-gray-700 whitespace-nowrap text-left text-[13px] lg:text-[15px]">
                                    {offset + index + 1}
                                  </td>
                                  <td className="px-2 lg:px-4 py-3 font-medium text-gray-700 whitespace-nowrap text-left text-[13px] lg:text-[15px]">
                                    {element?.rDate
                                      ? new Date(
                                          element.rDate
                                        ).toLocaleDateString()
                                      : "-"}
                                  </td>
                                  <td className="px-2 lg:px-4 py-3 capitalize font-medium text-gray-700 whitespace-nowrap text-left text-[13px] lg:text-[15px]">
                                    {element?.gameId?.name || "-"}
                                  </td>
                                  <td className="px-2 lg:px-4 py-3 font-medium text-gray-700 whitespace-nowrap text-left text-[13px] lg:text-[15px]">
                                    {element?.openNumber?.toString() || "0"}
                                  </td>
                                  <td className="px-2 lg:px-4 py-3 font-medium text-gray-700 whitespace-nowrap text-left text-[13px] lg:text-[15px]">
                                    {element?.jodiNumber?.toString() || "0"}
                                  </td>
                                  <td className="px-2 lg:px-4 py-3 font-medium text-gray-700 whitespace-nowrap text-left text-[13px] lg:text-[15px]">
                                    {element?.jodiNumber?.length === 1 ? (
                                      <span
                                        className="text-red-500 cursor-pointer"
                                        onClick={() => rollback(element)}
                                      >
                                        RollBack
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="px-2 lg:px-4 py-3 text-center text-gray-500"
                                >
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {gameResultList && gameResultTotal > 10 ? (
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.ceil(gameResultTotal / size)}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={10}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                      ) : null}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <ViewGameModal
            viewModal={viewModal}
            handleViewHideModal={handleViewHideModal}
            winnerList={winnerList}
            ShowWinnerList2={ShowWinnerList2}
          />
        </div>
      </div>
    </>
  );
};

export default DeclareResultFatafat;
