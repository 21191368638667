import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./AppSettingManagement.json";
import { appSettingActions, userActions } from "../../_actions";
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import ReactPaginate from "react-paginate";
// import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader/Loader";
import { FaRegSave } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoSave } from "react-icons/io5";

// import Table from '../../components/Atoms/Table/Table';

const AppSettingManagement = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state)
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(1000);
  // const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [valueappLink, setValueAppLink] = useState("");
  const [appVersion, setAppVersion] = useState('')

  const [minBid, setIsMinBid] = useState("")
  const [youtube, setIsYoutube] = useState("")
  const [minWithDraw, setIsMinWithdraw] = useState("")
  const [maxWithdraw, setIsMaxWithdraw] = useState("")

  const [minRecharge, setIsMinRecharge] = useState("")
  const [mobNo, setIsMobNo] = useState('')
  const [whatsAppNo, setIsWhatsAppNo] = useState("")
  const [upiId, setIsUpiId] = useState("")
  const [addMoneyRules, setIsAddMoneyRules] = useState("")
  const [withdrawmoneyRules, setIsWithdrawMoneyRules] = useState("")
  const [googlePay, setIsGooglePay] = useState()
  const [phonePay, setIsPhonePay] = useState()

  console.log("setIsGooglePaysetIsGooglePaysetIsGooglePaysetIsGooglePay", typeof googlePay, googlePay)


  const { appSettingList, loading } = useSelector((state) => state.appSettings);



  const [state1, setstate1] = useState("");
  const [state2, setstate2] = useState("");
  const [state3, setstate3] = useState("");
  const [state4, setstate4] = useState("");
  const [state5, setstate5] = useState("");
  const [marquee, setMarquee] = useState('')

  // const [initialState, setInitialState] = useState({
  //   _id: "",
  //   name: "",
  //   key: "",
  //   value: "",
  // });

  // const handleChange2 = (e, element) => {
  //   console.log(element);
  //   if (appSettingList && appSettingList.length > 0) {
  //     appSettingList.forEach((element) => {
  //       if (element.key === "YoutubeLink" && e.key === "Youtube") {
  //         setstate1(e.target.value);
  //       }
  //       if (element.key === "Email") {
  //         setstate2(e.target.value);
  //       }
  //       if (element.key === "WhatsApp") {
  //         setstate3(e.target.value);
  //       }
  //       if (element.key === "MobileNumber") {
  //         setstate4(e.target.value);
  //       }
  //       if (element.key === "TelegramLink") {
  //         setstate5(e.target.value);
  //       }
  //     });
  //   }
  // };

  const handleChange = (event) => {
    let uploadUrl = selector?.users?.fileData
    console.log("handleFileevent", selector?.users?.fileData);

    if (event.target.files[0]) {

      dispatch(userActions.uploadImage(event));
      setSelectedFile(null)
      // setAudioFile(selector?.users?.fileData)
    }
    else {
      console.log("No File To Upload!")
    }

  }

  // console.log(initialState)

  useEffect(() => {
    let appsettingReq = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(appSettingActions.getAppSettingList(appsettingReq));
    dispatch(userActions.clearImage());

  }, []);

  // const handleCreateOpenModal = () => {
  //   setCreateModal(true);
  // };
  // const savedatadata = (e) => {
  //   console.log(e);
  // };

  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  };

  // const handleUpdateOpenModal = (data) => {
  //   setFieldsUpdate(data);
  //   setUpdateModal(true);
  // }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  };

  // const handleViewOpenModal = (data) => {
  //   setRowData(data);
  //   setViewModal(true);
  // };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    // console.log(e)
    // const { name, value } = e.target;
    // setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    // setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, key, value, _id } = fieldsUpdate;
      dispatch(
        appSettingActions.updateAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id,
          },
          setUpdateModal,
          appsettingPageRefresh
        )
      );
    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, key, value, _id } = fieldsAppSetting;
      dispatch(
        appSettingActions.createAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id,
          },
          setCreateModal,
          setFieldsAppSetting,
          appsettingPageRefresh
        )
      );
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }

    setErrorsAppSetting(errors);
    return formIsValid;
  };

  // const disableAppSetting = (data) => {
  //   let appsettingIdReq = {
  //     id: data._id,
  //   };
  //   let appsettingPageRefresh = {
  //     keyWord: "",
  //     pageNo: pageNo,
  //     size: size,
  //   };
  //   confirmAlert({
  //     title: "Confirm to disable AppSetting?",
  //     message: `Are you sure to disable ${data.name}?`,
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () =>
  //           dispatch(
  //             appSettingActions.updateAppSettingStatus(
  //               appsettingIdReq,
  //               appsettingPageRefresh
  //             )
  //           ),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };

  // const deleteAppSetting = (data) => {
  //   let appsettingIdReq = {
  //     "id": data._id,
  //   }

  //   let appsettingPageRefresh = {
  //     "keyWord": "",
  //     "pageNo": pageNo,
  //     "size": size
  //   }

  //   confirmAlert({

  //     title: 'Confirm to Delete?',
  //     message: `Are you sure you want  to delete ${data.name}?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => dispatch(appSettingActions.deleteAppSetting(appsettingIdReq, appsettingPageRefresh))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }

  const handleFile = (event) => {
    console.log("handleFileevent", event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(
        appSettingActions.uploadImage(
          event.target.files[event.target.files.length - 1]
        )
      );
      // this.setState({ selectedFile: null });
      setSelectedFile(null);
    } else {
      console.log("No File To Upload!");
    }
  };




  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        if (element.key === "YoutubeLink") {
          setstate1(element.value);
        }
        if (element.key === "maxWithdrawl") {
          setIsMaxWithdraw(element.value);
        }
        if (element.key === "Email") {
          setstate4(element.value);
        }

        if (element.key === "gPayBoolean") {
          setIsGooglePay(element.value);
        }


        if (element.key === "phonePayBoolean") {
          setIsPhonePay(element.value);
        }


        if (element.key === "Marquee") {
          setMarquee(element.value);
        }

        if (element.key === "TelegramLink") {
          setstate5(element.value);
        }
        if (element.key === "HowToPlay") {
          setValue(element.value);
        }
        if (element.key === "NOTICE") {
          setValue2(element.value);
        }
        if (element.key === "applink") {
          setValueAppLink(element.value);
        }
        if (element.key === "minbid") {
          setIsMinBid(element.value);
        }

        if (element.key === "YouTub") {
          setIsYoutube(element.value);
        }

        if (element.key === "minWithdrawl") {
          setIsMinWithdraw(element.value);
        }
        if (element.key === "minRecharge") {
          setIsMinRecharge(element.value);
        }
        if (element.key === "MobileNumber") {
          setIsMobNo(element.value);
        }

        if (element.key === "WhatsApp") {
          setIsWhatsAppNo(element.value);
        }

        if (element.key === "UPIID") {
          setIsUpiId(element.value);
        }

        if (element.key === "AddMoneyRules") {
          setIsAddMoneyRules(element.value);
        }
        if (element.key === "WithDrawalMoneyRules") {
          setIsWithdrawMoneyRules(element.value);
        }
        if (element.key === "appversion") setAppVersion(element.value);
      });
    }
  }, [appSettingList]);




  const saveInfo = async (e) => {




    let { users, appSettings } = selector ? selector : {}
    let { appSettingList } = appSettings ? appSettings : {}

    console.log('appSettingListappSettingList', appSettingList)
    const { fileData } = users ? users : {}
    let oldimage = appSettingList && appSettingList.length > 0 && appSettingList.find(e => e?.key == "Qr")?.value || ""

    let dataofvalue;
    if (e.key === "maxWithdrawl") {
      dataofvalue = maxWithdraw;
    }

    if (e.key === "gPayBoolean") {
      dataofvalue = googlePay;
    }

    if (e.key === "phonePayBoolean") {
      dataofvalue = phonePay;
    }

    if (e.key === "NOTICE") {
      dataofvalue = value2;
    }
    if (e.key === "minbid") {
      dataofvalue = minBid;
    }

    if (e.key === "YouTub") {
      dataofvalue = youtube;
    }

    if (e.key === "minWithdrawl") {
      dataofvalue = minWithDraw;
    }


    if (e.key === "minRecharge") {
      dataofvalue = minRecharge;
    }
    if (e.key === "MobileNumber") {
      dataofvalue = mobNo;
    }

    if (e.key === "WhatsApp") {
      dataofvalue = whatsAppNo;
    }


    if (e.key === "UPIID") {
      dataofvalue = upiId;
    }
    if (e.key === "AddMoneyRules") {
      dataofvalue = addMoneyRules;
    }
    if (e.key === "WithDrawalMoneyRules") {
      dataofvalue = withdrawmoneyRules;
    }

    if (e.key === "applink") {
      dataofvalue = valueappLink;
    }
    if (e.key === "appversion") {
      dataofvalue = appVersion;
    }

    if (e.key === "Qr") {
      dataofvalue = fileData || oldimage;
    }
    if (e.key === "TelegramLink") {
      dataofvalue = state5;
    }
    if (e.key === "YoutubeLink") {
      dataofvalue = state1;
    }
    if (e.key === "Email") {
      dataofvalue = state4;
    }

    if (e.key === "Marquee") {
      dataofvalue = marquee;
    }

    if (e.key === "HowToPlay") {
      dataofvalue = value;
    }
    let appsettingPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      size: size,
    };
    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id,

    };

    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh));
  };
  let { users } = selector ? selector : {}
  const { fileData } = users ? users : {}
  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <p className="text-2xl font-bold">
                          App Setting Management
                        </p>
                      </sectiion>
                      <div>

                        {/* <div>
                          <button className="px-4 py-2 font-medium tracking-wider text-white bg-[#019FF5] border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                          onClick={() => handleCreateOpenModal()}>Add</button>
                        </div> */}
                        <div className="relative  flex flex-col gap-5 rounded-md">
                          <div className=" flex justify-between   p-3 bg-white border rounded-md shadow-md items-end gap-5  ">
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                if (element.key === "TelegramLink") {
                                  return (
                                    <form class="flex  w-full justify-start items-center  ">
                                      <div className=" relative w-full">
                                        <label
                                          for="text"
                                          class="block mb-1 text-sm font-medium text-gray-900 "
                                        >
                                          {element && element.name}
                                        </label>
                                        <input
                                          value={state5}
                                          onChange={(e) =>
                                            setstate5(e.target.value)
                                          }
                                          type="text"
                                          id="text"
                                          class="bg-gray-50  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required
                                        />
                                        <FaRegSave
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                          className=" text-[#992941] absolute  top-1/2 right-2 text-xl cursor-pointer"
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }



                                if (element.key === "appversion") {
                                  return (
                                    <form class=" w-full  mt-2 ">
                                      <label
                                        for="text"
                                        class="block mb-1  text-gray-900 "
                                      >
                                        {element && element?.name}
                                      </label>
                                      <textarea
                                        rows={3}
                                        type='number'
                                        value={appVersion}
                                        onChange={(e) =>
                                          setAppVersion(e.target.value)
                                        }

                                        id="text"
                                        class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <div className="flex justify-end">

                                        <button
                                          title="Save"
                                          onClick={(e) => {
                                            e.preventDefault(); // Prevent form submission
                                            saveInfo(element);
                                          }}

                                          className=" text-white bg-[#992941] px-2 py-1 mt-1  rounded cursor-pointer"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </form>
                                  );
                                }






                                if (element.key === "Email") {
                                  return (
                                    <form class="flex w-full  justify-start items-center mt-2 ">

                                      <label
                                        for="text"
                                        class="block mb-1 text-sm font-medium text-gray-900 "
                                      >
                                        {element && element.name}
                                      </label>
                                      <input
                                        value={state4}
                                        onChange={(e) =>
                                          setstate4(e.target.value)
                                        }
                                        type="text"
                                        id="text"
                                        class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                      />
                                      <FaRegSave
                                        title="Save"
                                        onClick={(e) =>
                                          saveInfo(element, e)
                                        }
                                        className=" text-[#992941] absolute  top-1/2 right-2 text-xl cursor-pointer"
                                      />

                                      <div></div>
                                    </form>
                                  );
                                }
                                if (element.key === "YoutubeLink") {
                                  return (
                                    <form class="flex  justify-start w-full items-center mt-2 ">
                                      <div className="w-full relative">
                                        <label
                                          for="text"
                                          class="block mb-1 text-sm font-medium text-gray-900 "
                                        >
                                          {element && element.name}
                                        </label>
                                        <input
                                          value={state1}
                                          onChange={(e) =>
                                            setstate1(e.target.value)
                                          }
                                          type="text"
                                          id="text"
                                          class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required
                                        />
                                        <FaRegSave
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                          className=" text-[#992941] absolute  top-1/2 right-2 text-xl cursor-pointer"
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }











                                if (element.key === "Qr") {

                                  return (
                                    <form class="flex  justify-start w-full items-center mt-2 ">
                                      <div className="w-full relative">
                                        <img src={fileData || element.value} className=" w-24 h-24" alt="img" />
                                        <label
                                          for="text"
                                          class="block mb-1 text-sm font-medium text-gray-900 "
                                        >
                                          {element && element.name}
                                        </label>
                                        <input
                                          // value={state1}
                                          onChange={handleChange
                                          }
                                          type="file"
                                          id="text"
                                          class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required

                                        />
                                        <FaRegSave
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                          className=" text-[#992941] absolute  top-32 right-2 text-xl cursor-pointer"
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }


























                                if (element.key === "Marquee") {
                                  return (
                                    <form class="flex  justify-start w-full items-center mt-2 ">
                                      <div className="w-full relative">
                                        <label
                                          for="text"
                                          class="block mb-1 text-sm font-medium text-gray-900 "
                                        >
                                          {element && element.name}
                                        </label>
                                        <input
                                          value={marquee}
                                          onChange={(e) =>
                                            setMarquee(e.target.value)
                                          }
                                          type="text"
                                          id="text"
                                          class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          required
                                        />
                                        <FaRegSave
                                          title="Save"
                                          onClick={(e) =>
                                            saveInfo(element, e)
                                          }
                                          className="  text-[#992941] absolute  top-1/2 right-2 text-xl cursor-pointer"
                                        />
                                      </div>
                                      <div></div>
                                    </form>
                                  );
                                }





                              })
                              : null}
                          </div>


                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">

                            <div className="  h-full bg-white border p-3 rounded-md shadow-md">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === "HowToPlay"
                                  ) {
                                    return (
                                      <div className="">
                                        <div className=" mb-1">

                                          How To Play
                                        </div>
                                        <ReactQuill
                                          className="h-96  w-full   pb-14"
                                          theme="snow"
                                          value={value}
                                          onChange={setValue}
                                        />
                                        <div className="flex justify-end">

                                          <button
                                            className="bg-[#992941] text-white mt-4 rounded px-2 py-1"
                                            onClick={(e) => saveInfo(element, e)}
                                          >

                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : null}
                            </div>
                            <div className=" flex-1 h-full bg-white border p-3 rounded-md shadow-md ">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === "NOTICE"
                                  ) {
                                    return (
                                      <div className="">
                                        <div className=" mb-1"> NOTICE</div>
                                        <ReactQuill
                                          className="h-96  w-full   pb-14"
                                          theme="snow"
                                          value={value2}
                                          onChange={setValue2}
                                        />
                                        <div className="flex justify-end">

                                          <button
                                            className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                            onClick={(e) => saveInfo(element, e)}
                                          >

                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : null}
                            </div>





                            <div className=" flex-1 h-full bg-white border p-3 rounded-md shadow-md ">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === "applink"
                                  ) {
                                    return (
                                      <div className="">
                                        <div className=" mb-1"> App Link</div>
                                        <ReactQuill
                                          className="h-96  w-full   pb-14"
                                          theme="snow"
                                          value={valueappLink}
                                          onChange={setValueAppLink}
                                        />
                                        <div className="flex justify-end">

                                          <button
                                            className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                            onClick={(e) => saveInfo(element, e)}
                                          >

                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : null}
                            </div>





                            <div className="  flex-1 h-full bg-white border p-3 rounded-md shadow-md ">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === "YouTub"
                                  ) {
                                    return (
                                      <div className="">
                                        <div className=" mb-1">Youtube Link </div>
                                        <ReactQuill
                                          className="h-40  w-full   pb-14"
                                          theme="snow"
                                          value={youtube}
                                          onChange={setIsYoutube}
                                        />
                                        <div className="flex justify-end">

                                          <button
                                            className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                            onClick={(e) => saveInfo(element, e)}
                                          >

                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : null}
                            </div>


                            <div className=" flex-1 h-full bg-white border p-3 rounded-md shadow-md  ">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === "AddMoneyRules"
                                  ) {
                                    return (
                                      <div className="">
                                        <div className=" mb-1">Add Money Rules  </div>
                                        <ReactQuill
                                          className="h-40  w-full   pb-14"
                                          theme="snow"
                                          value={addMoneyRules}
                                          onChange={setIsAddMoneyRules}
                                        />
                                        <div className="flex justify-end">

                                          <button
                                            className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                            onClick={(e) => saveInfo(element, e)}
                                          >

                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : null}
                            </div>


                            <div className=" flex-1 h-full bg-white border p-3 rounded-md shadow-md  ">
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === "AddMoneyRules"
                                  ) {
                                    return (
                                      <div className="">
                                        <div className=" mb-1">Withdraw Money Rules  </div>
                                        <ReactQuill
                                          className="h-40  w-full   pb-14"
                                          theme="snow"
                                          value={withdrawmoneyRules}
                                          onChange={setIsWithdrawMoneyRules}
                                        />
                                        <div className="flex justify-end">

                                          <button
                                            className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                            onClick={(e) => saveInfo(element, e)}
                                          >

                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : null}
                            </div>









                          </div>




                        </div>
                      </div>

                      <div className="flex flex-col lg:flex-row justify-between items-start gap-8 p-4 bg-gray-50 rounded-md shadow-md my-2">
                        {appSettingList &&
                          appSettingList.length > 0 &&
                          appSettingList.map((element, index) => {
                            let label, value, onChange, name;

                            if (element.key === "UPIID") {
                              label = "Upi Id";
                              value = upiId;
                              onChange = (e) => setIsUpiId(e.target.value);
                              name = "upiId";
                            } else if (element.key === "WhatsApp") {
                              label = "WhatsApp";
                              value = whatsAppNo;
                              onChange = (e) => setIsWhatsAppNo(e.target.value);
                              name = "whatsAppNo";
                            } else if (element.key === "minWithdrawl") {
                              label = "Min Withdraw";
                              value = minWithDraw;
                              onChange = (e) => setIsMinWithdraw(e.target.value);
                              name = "minWithdraw";
                            } 
                            else if (element.key === "maxWithdrawl") {
                              label = "Max Withdraw";
                              value = maxWithdraw;
                              onChange = (e) => setIsMaxWithdraw(e.target.value);
                              name = "maxWithdraw";
                            } else if (element.key === "MobileNumber") {
                              label = "Mob No";
                              value = mobNo;
                              onChange = (e) => setIsMobNo(e.target.value);
                              name = "mobNo";
                            } else if (element.key === "minRecharge") {
                              label = "Min Recharge";
                              value = minRecharge;
                              onChange = (e) => setIsMinRecharge(e.target.value);
                              name = "minRecharge";
                            } else {
                              return null;
                            }

                            return (
                              <div key={index} className="w-full lg:w-1/5">
                                <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
                                <div className="relative">
                                  <input
                                    type={name === "upiId" ? "text" : "number"}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring focus:ring-indigo-300"
                                  />
                                  <button
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-indigo-500 hover:text-indigo-700"
                                    onClick={(e) => saveInfo(element, e)}
                                  >
                                    <IoSave size={20} />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {/* <div className="flex flex-col lg:flex-row justify-between items-start gap-8 p-4 bg-gray-50 rounded-md shadow-md my-2">
                        <div className="  ">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (
                                element &&
                                element.key &&
                                element.key === "UPIID"
                              ) {
                                return (
                                  <div className="">
                                    <div className=" mb-1">Upi Id</div>
                                    <input
                                      type="text"
                                      name="upiId"
                                      className="border border-black rounded-md px-3"
                                      theme="snow"
                                      value={upiId}
                                      onChange={(e) => setIsUpiId(e.target.value)}
                                    />
                                    <div className="flex justify-end">

                                      <button
                                        className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                        onClick={(e) => saveInfo(element, e)}
                                      >

                                        Save
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            })
                            : null}
                        </div>
                        <div className="  ">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (
                                element &&
                                element.key &&
                                element.key === "WhatsApp"
                              ) {
                                return (
                                  <div className="">
                                    <div className=" mb-1">WhatsApp</div>
                                    <input
                                      type="number"
                                      name="minBid"
                                      className="border border-black rounded-md px-3"
                                      theme="snow"
                                      value={whatsAppNo}
                                      onChange={(e) => setIsWhatsAppNo(e.target.value)}
                                    />
                                    <div className="flex justify-end">

                                      <button
                                        className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                        onClick={(e) => saveInfo(element, e)}
                                      >

                                        Save
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            })
                            : null}
                        </div>

                        <div className="  ">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (
                                element &&
                                element.key &&
                                element.key === "minWithdrawl"
                              ) {
                                return (
                                  <div className="">
                                    <div className=" mb-1">Min Withdraw</div>
                                    <input
                                      type="number"
                                      name="minWithDraw"
                                      className="border border-black rounded-md px-3"
                                      theme="snow"
                                      value={minWithDraw}
                                      onChange={(e) => setIsMinWithdraw(e.target.value)}
                                    />
                                    <div className="flex justify-end">

                                      <button
                                        className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                        onClick={(e) => saveInfo(element, e)}
                                      >

                                        Save
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            })
                            : null}
                        </div>

                        <div className="  ">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (
                                element &&
                                element.key &&
                                element.key === "MobileNumber"
                              ) {
                                return (
                                  <div className="">
                                    <div className=" mb-1">Mob No</div>
                                    <input
                                      type="number"
                                      name="minWithDraw"
                                      className="border border-black rounded-md px-3"
                                      theme="snow"
                                      value={mobNo}
                                      onChange={(e) => setIsMobNo(e.target.value)}
                                    />
                                    <div className="flex justify-end">

                                      <button
                                        className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                        onClick={(e) => saveInfo(element, e)}
                                      >

                                        Save
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            })
                            : null}
                        </div>

                        <div className="  ">
                          {appSettingList && appSettingList.length > 0
                            ? appSettingList.map((element, index) => {
                              if (
                                element &&
                                element.key &&
                                element.key === "minRecharge"
                              ) {
                                return (
                                  <div className="">
                                    <div className=" mb-1">Min Recharge</div>
                                    <input
                                      type="number"
                                      name="minRecharge"
                                      className="border border-black rounded-md px-3"
                                      theme="snow"
                                      value={minRecharge}
                                      onChange={(e) => setIsMinRecharge(e.target.value)}
                                    />
                                    <div className="flex justify-end">

                                      <button
                                        className="bg-[#992941] px-2 py-1 mt-4 text-white rounded "
                                        onClick={(e) => saveInfo(element, e)}
                                      >

                                        Save
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            })
                            : null}
                        </div>
                      </div> */}

                      <div className="flex flex-col lg:flex-row justify-between items-start gap-8 p-4 bg-gray-50 rounded-md shadow-md">
                        {/* Google Pay Section */}
                        <div className="flex flex-col w-full lg:w-1/2 bg-white p-4 rounded-lg shadow-md">
                          {appSettingList && appSettingList.length > 0 &&
                            appSettingList.map((element) => {
                              if (element?.key === "gPayBoolean") {
                                return (
                                  <div key={element.key}>
                                    <h3 className="text-lg font-semibold mb-2">Google Pay</h3>
                                    <label className="flex items-center gap-4 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="appearance-none w-6 h-6 border-2 border-gray-400 rounded-md checked:bg-green-500 checked:border-green-500 transition-all"
                                        checked={googlePay === "true"}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          setIsGooglePay(isChecked ? "true" : "false");
                                        }}
                                      />
                                      <span className="text-gray-700">{googlePay === "true" ? "Disable" : "Enable"} Google Pay</span>
                                    </label>
                                    <div className="flex justify-end mt-4">
                                      <button
                                        className="bg-[#992941] px-4 py-2 text-white rounded-md shadow hover:bg-[#b33350] transition-all"
                                        onClick={() => saveInfo(element, googlePay)}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                        </div>

                        {/* Phone Pay Section */}
                        <div className="flex flex-col w-full lg:w-1/2 bg-white p-4 rounded-lg shadow-md">
                          {appSettingList && appSettingList.length > 0 &&
                            appSettingList.map((element) => {
                              if (element?.key === "phonePayBoolean") {
                                return (
                                  <div key={element.key}>
                                    <h3 className="text-lg font-semibold mb-2">Phone Pay</h3>
                                    <label className="flex items-center gap-4 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="appearance-none w-6 h-6 border-2 border-gray-400 rounded-md checked:bg-green-500 checked:border-green-500 transition-all"
                                        checked={phonePay === "true"}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          setIsPhonePay(isChecked ? "true" : "false");
                                        }}
                                      />
                                      <span className="text-gray-700">{phonePay === "true" ? "Disable" : "Enable"} Phone Pay</span>
                                    </label>
                                    <div className="flex justify-end mt-4">
                                      <button
                                        className="bg-[#992941] px-4 py-2 text-white rounded-md shadow hover:bg-[#b33350] transition-all"
                                        onClick={() => saveInfo(element, phonePay)}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                        </div>
                      </div>
                    </div>
                  </div>


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />
    </>
  );
};

export default AppSettingManagement;
