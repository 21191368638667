import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, reportActions } from '../../_actions'
import moment from 'moment'
import Loader from '../../components/Loader/Loader'

const CancelBetHistory = () => {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    let { reports, games } = selector ? selector : {};
    let { getCancelList } = reports ? reports : {}
    const { gamesList } = useSelector(state => state.games);


    const [date, setDate] = useState('')
    const [gameId, setGameId] = useState('')
    const [gameType, setGameType] = useState('')
    const [userId, setuserId] = useState("")


    let today = new Date()
    const formattedDate = moment(today).format('YYYY-MM-DD');
    useEffect(() => {
        let reqData = {
            "gameId": "",
            "marketId": "",
            "userId": [],
            "fromDate": formattedDate,
            "toDate": formattedDate,
            "keyWord": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 1000
        }
        let gameReq2 = {
            "fromDate": '',
            "toDate": '',
            "keyWord": '',
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 500
        }
        dispatch(gamesActions.getAllGames(gameReq2));
        dispatch(reportActions.getCancleHistory(reqData))
        dispatch(gamesActions.getAllUser());

        setDate(formattedDate)
    }, [])

    const handleDateChange = (e) => {
        setDate(e.target.value)
    }
    const handleGameId = (e) => {
        setGameId(e.target.value)
    }
    const handleType = (e) => {
        setGameType(e.target.value)
    }

    const handleUserId = (data) => {
        setuserId(data)
    }
    const handleSubmit = () => {
        let reqData = {
            "gameId": gameId,
            "marketId": '',
            "userId": [userId],
            "fromDate": date,
            "toDate": date,
            "keyWord": "",
            "sortBy": "",
            isOpen: gameType == 'true' ? true : false,
            "sortOrder": "",
            "pageNo": 1,
            "size": 1000
        }
        dispatch(reportActions.getCancleHistory(reqData))

    }

    const handleClear = () => {
        let reqData = {
            "gameId": "",
            "marketId": "",
            "userId": [],
            "fromDate": formattedDate,
            "toDate": formattedDate,
            "keyWord": "",
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 1000
        }
        dispatch(reportActions.getCancleHistory(reqData))
        setGameId('')
        setuserId('')
    }
    return (
        <div className='p-5 space-y-2'>
            <Loader loading={selector?.reports?.loading} />

            <div className='p-2 grid grid-cols-1 md:grid-cols-5 items-end     gap-2 bg-white rounded-md'>
                <div className='flex-1'>
                    <label class="block text-gray-700 text-base mb-2" for="name">Date :</label>
                    <input type='date' value={date} onChange={handleDateChange} className="w-full px-4 py-2.5 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                </div>
                <div className="flex-1">
                    <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                    <select className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize  "
                        id="userId" name="userId" placeholder="name"
                        value={gameId}
                        type="text" onChange={handleGameId} >
                        <option value="">Select Game Name</option>
                        {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (
                            <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                        )) : null}

                    </select>

                </div>
                <div className='flex-1'>
                    <label class="block text-gray-700 text-base mb-2" for="name">Type :</label>
                    <select className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize  " onChange={handleType}>
                        <option value={''}>Select Type</option>
                        <option value={true}>Open</option>
                        <option value={false}>Close</option>

                    </select>
                </div>

                <div className="flex-1">
                    <label className="mb-2 block text-sm font-medium text-gray-700">Select User</label>
                    <select
                        className="w-full px-4 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 capitalize  "
                        id="Users"
                        value={userId}
                        name="Users"
                        onChange={(e) => handleUserId(e.target.value)}
                    >
                        <option value="">Select User</option>
                        {selector?.games?.allUserList?.map((element) => (
                            <option key={element._id} value={element._id}>
                                {element.userName || "N/A"}
                            </option>
                        ))}
                    </select>
                </div>


                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <button className='bg-black p-2 rounded-md text-white' onClick={handleSubmit}>Submit</button>
                    <button className='bg-blue-500 p-2 rounded-md text-white' onClick={handleClear}>Clear</button>

                </div>
            </div>

            <div>
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden text-left">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">User Name</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Game Name</th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount </th>
                            <th className="py-3 px-6 text-xs font-semibold text-gray-600 uppercase tracking-wider">Date Time</th>

                        </tr>
                    </thead>
                    <tbody>
                        {getCancelList && getCancelList.length > 0 ? (
                            getCancelList.map((ele, index) => (
                                <tr key={index} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                    <td className="py-4 px-6 text-sm text-gray-700 capitalize ">{ele?.userId?.userName}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700 capitalize">{ele?.gameId?.name || '-'}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700">{ele?.amount || 0}</td>
                                    <td className="py-4 px-6 text-sm text-gray-700">{moment(ele?.createdAt).format('YYYY-MM-DD')}</td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="py-4 px-6 text-center text-sm text-gray-500">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>


        </div>
    )
}

export default CancelBetHistory