import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { alertActions, userActions } from '../../_actions';

const CreateAccount = () => {
    const dispatch = useDispatch();
    const [fieldsValue, setFieldsValue] = useState({
        name: '',
        phoneNumber: '',
        password: ''
    });

    const [errors, setErrors] = useState({});

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        // Update field value and remove error if present
        setFieldsValue({
            ...fieldsValue,
            [name]: value
        });

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!fieldsValue.name) formErrors.name = 'Name is required';
        if (!fieldsValue.phoneNumber) formErrors.phoneNumber = 'Phone number is required';
        if (!fieldsValue.password) formErrors.password = 'Password is required';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const reqData = {
                userName: fieldsValue.name,
                mobNo: fieldsValue.phoneNumber,
                password: fieldsValue.password
            };
            dispatch(userActions.createuserBysuperadmin(reqData));
            dispatch(alertActions.success('user Creaet successfully'))
            setFieldsValue({
                name: '',
                phoneNumber: '',
                password: ''
            })
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-semibold text-center mb-6">Create Account</h2>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="space-y-4">
                        <div>
                            <input
                                type="text"
                                placeholder="Enter name..."
                                name="name"
                                className={`border p-2 rounded-md w-full focus:outline-none focus:border-blue-500 ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                                value={fieldsValue.name}
                                onChange={handleOnChange}
                            />
                            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                        </div>

                        <div>
                            <input
                                type="text"
                                placeholder="Enter phone number..."
                                name="phoneNumber"
                                className={`border p-2 rounded-md w-full focus:outline-none focus:border-blue-500 ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'}`}
                                value={fieldsValue.phoneNumber}
                                onChange={handleOnChange}
                            />
                            {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
                        </div>

                        <div>
                            <input
                                type="password"
                                placeholder="Enter password..."
                                name="password"
                                className={`border p-2 rounded-md w-full focus:outline-none focus:border-blue-500 ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
                                value={fieldsValue.password}
                                onChange={handleOnChange}
                            />
                            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                        </div>

                        <button type="submit" className="bg-blue-500 text-white w-full py-2 rounded-md hover:bg-blue-600">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateAccount;
